import  Toast, { useToast }  from 'vue-toastification';
import { defineStore } from 'pinia';

import { IUsers } from './interface';
import { HttpAdapterCustomer } from '@/helpers';

export const useStoreUsers = defineStore('users', {
	state: () => ({
		users: [] as IUsers[],
		tab: 'List' as 'List' | 'Form',
		userToUpdate: undefined as IUsers | undefined,
		search: '',
		spinner: false,
	}),
	actions: {
		setUsers(users: IUsers[]) {
			this.users = users.map((el) => {
				el.roleName = ROLES.find((role) => role.id === el.role)?.role || '' 
				return el
			});
		},
		addUser(user: IUsers) {
			this.users.push(user);
		},
		updateUser(user: IUsers) {
			const index = this.users.findIndex((u) => u.id === user.id);
			this.users[index] = user;
		},
		setUserToUpdate(user: IUsers | undefined) {
			this.userToUpdate = user;
		},
		setValueInUserToUpdate(key: string, value: string) {
			if (!this.userToUpdate) this.userToUpdate = {} as any;
			(this.userToUpdate as any)[key] = value;
		},
		setTab(tab: 'List' | 'Form') {
			if (tab === 'List') this.setUserToUpdate(undefined);
			this.tab = tab;
		},
		setSearch(search: string) {
			this.search = search;
		},
		setSpinner(spinner: boolean) {
			this.spinner = spinner;
		},
		deleteUser(id:number){
			deleteUserApi(id)
		}
	},
});

export const ROLES = [
	{ id: 'admin', role: 'Administrador' },
	{ id: 'user', role: 'Usuário' },
	{ id: 'company_manager', role: 'Gestor' },
	{ id: 'organizer', role: 'Organizador' },
	{ id: 'stand_builder', role: 'Montadora Oficial' },
	{ id: 'coordinator', role: 'Coordenador' },
	{id:'sender',role:'Remetente'}
];


export const deleteUserApi = async (id:number) => {
	const {data,response } = await HttpAdapterCustomer.delete({
		url:`admin/user/${id}`,
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		}
	});
	const toast = useToast()

	const storeUsers = useStoreUsers()
	if (!response.ok) return toast.error(data.message || 'Erro ao cadastrar usuário');
	toast.success('Usuário Excluido com sucesso');
	getUser(storeUsers.setUsers, storeUsers.search, () => {
			storeUsers.setSpinner(false);
		});
}
export const getUser = async (setValue: (p: any) => void, search: string, callback: () => void,
			sort?:{order:string,direction:'asc' | 'desc'}): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : '';
	const direction = sort?.direction? sort.direction:'asc';
	const order = sort?.order? sort.order: 'name'
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/user${query}`,
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};
