
import Vue, { defineComponent, onBeforeMount, onMounted } from "vue";
import BaseMultipleSelect from "../../../../components/Atoms/BaseMultipleSelect.vue";
import { getCompanysEvent } from "../service";
import { useStoreDashboard } from "../store";
import PieChart from "./chart.component";
import Table from "../../../../components/Organism/Table/table.vue";

export default defineComponent({
  name: "PreEvent",
  components: {
    BaseMultipleSelect,
    PieChart,
    Table,
  },
  methods: {
    getPercentage(companys:any, store:any) {
      return companys.map((el:any) => {
        return el[store.tabPreEvent]
      });
    },
  },
  setup() {
    const store = useStoreDashboard();

		function orderByColumn(column: any, direction: any) {
			const totalParticipants = store.participantsFilter.find((p: any) => p.name === 'TOTAL GERAL')
			const participantsList = store.participantsFilter.sort((a,b) => {
						
            if(direction === 'asc'){
              if(a[column] > b[column])return 1;
              if(a[column] < b[column])return -1;
              return 0
            } else {
              if(a[column] < b[column])return 1;
              if(a[column] > b[column])return -1;
              return 0
            }
        }).filter((p: any) => p.name !== "TOTAL GERAL")
				const toSet = !!totalParticipants ? [totalParticipants, ...participantsList] : participantsList
			store.setParticipantsFilter(
				toSet
       )
		}
  
    return {
      store,
      console,
      PieChart,
      BaseMultipleSelect,
      Table,
			orderByColumn,
    };
  },
});
