import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e600c84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"100%","display":"flex","justify-content":"center","align-items":"center","padding":"0","position":"relative"} }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "button-options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!!_ctx.modalOptions)
      ? (_openBlock(), _createBlock(_component_BaseButton, {
          key: 0,
          class: "modal-background",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOptionDot('')))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BaseButton, {
        id: `button-dots${_ctx.user ? _ctx.user.id : ''}`,
        class: "button-dots",
        onClick: _cache[1] || (_cache[1] = 
					() => {
						_ctx.getPosition(_ctx.user ? _ctx.user.id : '');
						_ctx.setOptionDot(_ctx.user ? _ctx.user.id : '');
					}
				)
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.IcVerticalDots)))
        ]),
        _: 1
      }, 8, ["id"]),
      (_ctx.modalOptions === _ctx.user?.id || _ctx.modalOptions === 'true')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            style: _normalizeStyle(`position:fixed;left:${_ctx.positionModal.left - 150}px;top:${
					_ctx.positionModal.top
				}px`),
            class: "modal-options"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.handleActions, (action) => {
              return (_openBlock(), _createElementBlock("div", {
                key: action.title,
                class: "button-line",
                onClick: ($event: any) => (action.onClick(_ctx.user), (_ctx.modalOptions = 'false'))
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(action.icon))),
                _createElementVNode("button", _hoisted_3, _toDisplayString(action.title), 1)
              ], 8, _hoisted_2))
            }), 128))
          ], 4))
        : _createCommentVNode("", true)
    ])
  ]))
}