import  Toast, { useToast }  from 'vue-toastification';
import { defineStore } from 'pinia';

import { ISpeakers } from './interface';
import { existThisFile, HttpAdapterCustomer } from '@/helpers';

export const useStoreSpeakers = defineStore('speakers', {
	state: () => ({
		speakers: [] as ISpeakers[],
		tab: 'List' as 'List' | 'Form',
		speakerToUpdate: undefined as ISpeakers | undefined,
		search: '',
		spinner: false,
	}),
	actions: {
		setSpeakers(speakers: ISpeakers[]) {
			this.speakers = speakers.map((el) => {
				return el
			});
		},
		addSpeaker(speaker: ISpeakers) {
			this.speakers.push(speaker);
		},
		updateSpeaker(speaker: ISpeakers) {
			const index = this.speakers.findIndex((u) => u.id === speaker.id);
			this.speakers[index] = speaker;
		},
		setSpeakerToUpdate(speaker: ISpeakers | undefined) {
			if(!!speaker?.avatar){
				speaker.avatar = existThisFile(speaker.avatar)
			} else {
					if(speaker){
						speaker.avatar = speaker?.asset?.base64;
					}
					
			}
			this.speakerToUpdate = speaker;
		},
		setValueInSpeakerToUpdate(key: string, value: any) {
			if (!this.speakerToUpdate) this.speakerToUpdate = {} as any;
			(this.speakerToUpdate as any)[key] = value;
		},
		setTab(tab: 'List' | 'Form') {
			if (tab === 'List') this.setSpeakerToUpdate(undefined);
			this.tab = tab;
		},
		setSearch(search: string) {
			this.search = search;
		},
		setSpinner(spinner: boolean) {
			this.spinner = spinner;
		},
		deleteSpeaker(id:number){
			deleteSpeakerApi(id)
		}
	},
});

export const ROLES = [
	{ id: 'admin', role: 'Administrador' },
	{ id: 'speaker', role: 'Usuário' },
	{ id: 'company_manager', role: 'Gestor' },
	{ id: 'organizer', role: 'Organizador' },
	{ id: 'stand_builder', role: 'Montadora Oficial' },
	{ id: 'coordinator', role: 'Coordenador' },
];


export const deleteSpeakerApi = async (id:number) => {
	const {data,response } = await HttpAdapterCustomer.delete({
		url:`admin/speaker/${id}`,
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		}
	});
	const toast = useToast()

	const storeSpeakers = useStoreSpeakers()
	if (!response.ok) return toast.error(data.message || 'Erro ao cadastrar usuário');
	toast.success('Usuário Excluido com sucesso');
	getSpeaker(storeSpeakers.setSpeakers, storeSpeakers.search, () => {
			storeSpeakers.setSpinner(false);
		});
}
export const getSpeaker = async (setValue: (p: any) => void, search: string, callback: () => void,
			sort?:{order:string,direction:'asc' | 'desc'}): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : '';
	const direction = sort?.direction? sort.direction:'asc';
	const order = sort?.order? sort.order: 'name'
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/speaker${query}`,
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};
