import { ActivityResponse, IScheduleActivities, IScheduleActivitiesResponse, SpeakerResponse } from '../dto/IScheduleActivitiesResponse';
import { Activity, ActivityDetail, IScheduleActivitiesState, Speaker } from '../dto/IScheduleActivitiesState';

export function mapperScheduleActivities(scheduleActivities: IScheduleActivitiesResponse): IScheduleActivitiesState[] {
	return scheduleActivities?.data?.map(getScheduleActivities);
}

function getScheduleActivities(scheduleActivity: IScheduleActivities) {
	return {
		date: scheduleActivity.date,
		activities: scheduleActivity.activities.map(mapperActivities),
	};
}

function mapperActivities(activity: ActivityResponse): Activity {
	return {
		speakers: activity.speakers.map(mapperSpeaker),
		activityDetail: mapperActivityDetail(activity),
	};
}

function mapperSpeaker(speaker: SpeakerResponse): Speaker {
	return {
		...speaker,
		avatar: `https://eventosabrafarma.com.br/content/${speaker.avatar}`,
	};
}

function mapperActivityDetail(activity: ActivityResponse): ActivityDetail {
	return {
		title: activity.activity_data.title,
		hours: `${activity.activity_data.start_time} às ${activity.activity_data.end_time}`,
		place: activity.activity_data.place,
		logo: activity.activity_data.logo,
		isInterval: activity.activity_data.is_interval,
		id: activity.activity_data.id,
		type: activity.activity_data.type,
		typeLabel: activity.activity_data.type_label,
		companyName: activity.activity_data.company_name,
		description: activity.activity_data.description,
	};
}
