import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78fa540f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-length-count" }
const _hoisted_2 = ["type", "value", "checked", "list"]
const _hoisted_3 = {
  key: 0,
  class: "error error-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["input-group-box", _ctx.sizeDefault ? 'input-group' : ''])
    }, [
      _createElementVNode("label", {
        class: _normalizeClass(["label", _ctx.error?.status ? 'error' : ''])
      }, _toDisplayString(_ctx.label), 3),
      _createElementVNode("input", _mergeProps(_ctx.$attrs, {
        class: ["input", [
					_ctx.error?.status ? 'error error-assets' : '',
					_ctx.sizeDefault ? 'input-default' : '',
				]],
        type: _ctx.type,
        value: _ctx.value,
        checked: _ctx.checked,
        style: _ctx.styleInput,
        list: _ctx.list,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
      }), null, 16, _hoisted_2),
      (_ctx.error?.status)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.error.message), 1))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", null, _toDisplayString(_ctx.maxLength), 1)
  ]))
}