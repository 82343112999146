
	// eslint-disable-next-line import/named
	import Vue, { PropType, defineComponent } from 'vue';

	export default defineComponent({
		name: 'InputRadio',
		props: {
			options: {
				type: Array as Vue.PropType<{ text: string; value: boolean }[]>,
				required: true,
			},
			value: {
				type: Object as PropType<any>,
			},
		},
		methods: {
			onChange(event: any, value: any) {
				this.$emit('change-option', { event: event.target.value, value });
			},
		},
	});
