import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec403eca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: `width:auto;overflow:${'auto'}` }
const _hoisted_2 = { class: "table" }
const _hoisted_3 = { style: {"width":"100%","display":"flex"} }
const _hoisted_4 = { class: "tr" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { style: {"margin-left":"10px"} }
const _hoisted_7 = {
  key: 1,
  style: {"display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}
}
const _hoisted_8 = {
  key: 0,
  style: {"width":"100%","border-bottom":"1px solid #ccc","height":"auto"}
}
const _hoisted_9 = {
  key: 0,
  class: "td-span",
  style: {"max-width":"100%","word-wrap":"break-word"}
}
const _hoisted_10 = {
  key: 2,
  style: {"margin-bottom":"2vh"}
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 5 }
const _hoisted_14 = { key: 6 }
const _hoisted_15 = { style: {"font-weight":"bold","font-size":"12px"} }
const _hoisted_16 = { style: {"font-size":"12px"} }
const _hoisted_17 = {
  key: 0,
  style: {"width":"90%","height":"auto","border-bottom":"1px solid #ccc","display":"flex","justify-content":"flex-end","padding-right":"5%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_IcArrowUpSort = _resolveComponent("IcArrowUpSort")!
  const _component_IcArrowDownSort = _resolveComponent("IcArrowDownSort")!
  const _component_MenuTable = _resolveComponent("MenuTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", _hoisted_3, [
        _createElementVNode("tr", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
            return (_openBlock(), _createElementBlock("th", {
              class: "th",
              key: column.id,
              style: _normalizeStyle(`min-width:${
							!!column.width ? column.width : '100px'
						};max-width:${!!column.width ? column.width : '100px'}`)
            }, [
              (column.check)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_BaseInput, {
                      label: "",
                      type: "checkbox",
                      onOnchangeCheck: ($event: any) => (column.checked($event ? 'all' : 'clear'))
                    }, null, 8, ["onOnchangeCheck"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_6, _toDisplayString(column.title), 1),
              (column.sort)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_IcArrowUpSort, {
                      onClick: () => _ctx.onSort(column.value, 'desc'),
                      class: "icon-sort",
                      style: _normalizeStyle(`fill:${
									_ctx.type === 'desc' && _ctx.columnSelected === column.value
										? '#073566'
										: '#ddd'
								};`)
                    }, null, 8, ["onClick", "style"]),
                    _createVNode(_component_IcArrowDownSort, {
                      onClick: () => _ctx.onSort(column.value, 'asc'),
                      class: "icon-sort",
                      style: _normalizeStyle(`fill:${
									_ctx.type === 'asc' && _ctx.columnSelected === column.value
										? '#073566'
										: '#ddd'
								};`)
                    }, null, 8, ["onClick", "style"])
                  ]))
                : _createCommentVNode("", true)
            ], 4))
          }), 128)),
          (!!_ctx.tableActions)
            ? (_openBlock(), _createElementBlock("th", _hoisted_8))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.rows, (row) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id,
            style: {"border":"none","height":"auto","display":"flex","justify-content":"flex-start","position":"relative","margin-left":"12px"}
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
              return (_openBlock(), _createElementBlock("td", {
                key: column.id,
                class: "td",
                style: _normalizeStyle(`
            min-width:${!!column.width ? column.width : '100px'};
            max-width:${!!column.width ? column.width : '100px'}`)
              }, [
                (
								!column.doubleText &&
								!column.check &&
								!column.icon &&
								!column.list &&
								!column.iconList &&
								!column.button
							)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(row[column.value]), 1))
                  : _createCommentVNode("", true),
                (!!column.icon)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(row[column.value] ? column.icon.success : column.icon.fail), { key: 1 }))
                  : _createCommentVNode("", true),
                (!!column.list)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row[column.value], (list) => {
                        return (_openBlock(), _createElementBlock("span", {
                          class: "td-span",
                          key: list
                        }, [
                          _createElementVNode("b", null, _toDisplayString(list.title), 1),
                          _createTextVNode(" " + _toDisplayString(list.value), 1)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (!!column.iconList && column.iconList[row[column.value]])
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 3,
                      onClick: column.iconList[row[column.value]].onClick,
                      class: "icon-list"
                    }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(column.iconList[row[column.value]].icon))),
                      _createElementVNode("span", null, _toDisplayString(column.iconList[row[column.value]].description), 1)
                    ], 8, _hoisted_11))
                  : _createCommentVNode("", true),
                (!!column.button)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 4,
                      onClick: ($event: any) => (column.button.onClick(row)),
                      style: _normalizeStyle(`color:${
								column.button.colors[row[column.value]]?.color
							};background:${
								column.button.colors[row[column.value]]?.background
							};padding:10px;border-radius:8px;cursor:pointer`)
                    }, _toDisplayString(column.button.title[row[column.value]]), 13, _hoisted_12))
                  : _createCommentVNode("", true),
                (!!column.check && column.checkValue && column.checked)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(_component_BaseInput, {
                        label: "",
                        checked: row[column.checkValue],
                        type: "checkbox",
                        onOnchangeCheck: 
									(event) => {
										column.checked(row[column.value], event);
									}
								
                      }, null, 8, ["checked", "onOnchangeCheck"])
                    ]))
                  : _createCommentVNode("", true),
                (!!column.doubleText)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(row[column.doubleText.bold]), 1),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(row[column.doubleText.text]), 1)
                    ]))
                  : _createCommentVNode("", true)
              ], 4))
            }), 128)),
            (!!_ctx.tableActions)
              ? (_openBlock(), _createElementBlock("td", _hoisted_17, [
                  _createVNode(_component_MenuTable, {
                    handleActions: _ctx.tableActions,
                    user: row
                  }, null, 8, ["handleActions", "user"])
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ]))
}