import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05e494ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = {
  style: {"display":"flex","width":"100%","gap":"2vw","margin-bottom":"2vh","flex-wrap":"wrap"},
  class: "checkbox-list"
}
const _hoisted_3 = { class: "row-checkbox" }
const _hoisted_4 = { class: "checkbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizers, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          style: {"display":"flex","width":"30%"}
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BaseInput, {
                name: item.name,
                type: "checkbox",
                label: "",
                class: "input-checkbox",
                checked: 
								_ctx.storeEvents.eventToUpdate &&
								_ctx.storeEvents.eventToUpdate.organizersId.includes(item.id)
							,
                onChange: 
								(event) => {
									if (
										event.target.checked &&
										!_ctx.storeEvents.eventToUpdate.organizersId.includes(item.id)
									) {
										_ctx.storeEvents.eventToUpdate.organizersId.push(item.id);
									} else {
										if (!event.target.checked) {
											const findInd =
												_ctx.storeEvents.eventToUpdate.organizersId.indexOf(item.id);
											if (_ctx.findId !== -1) {
												_ctx.storeEvents.eventToUpdate.organizersId.splice(
													findInd,
													1
												);
											}
										}
									}
									_ctx.storeEvents.setValueInEventToUpdate(
										'organizersId',
										_ctx.storeEvents.eventToUpdate.organizersId
									);
								}
							
              }, null, 8, ["name", "checked", "onChange"]),
              _createElementVNode("span", null, _toDisplayString(item.name), 1)
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}