import { defineStore } from "pinia";
import { IParticipantList, ISessionActivities } from "./interface";
import { deleteParticipantToActivity, getActivitySession, getParticipantSession } from "./service";
import { useToast } from "vue-toastification";

const toast = useToast()

export const useStoreFindRetailer = defineStore("useStoreFindRetailer", {
	state: () => ({
		modalIsOpen: false,
		participantList: [{}] as IParticipantList[],
		sessionList: [{}] as ISessionActivities[],
		participantSelected: {
			name: '',
			cpf: '',
			id: ''
		},
		confirmDeleteOneCoupon: "",
		confirmDeleteOne: false,
		companyData: {
			name: '',
			spots: 0,
		},
		spots: [],
		isLoading: false,
	}),

	actions: {
		setLoading(value: boolean) {
			this.isLoading = value;
		},
		setCompanyName(company: any) {
			this.companyData.name = company;
		},

		setCompanySpots(spots: any) {
			this.companyData.spots = spots;
		},

		setSessionsActivities(activity: ISessionActivities[]) {
			this.sessionList = activity;
		},

		setSpotsList(spots: any) {
			this.spots = spots;
		},

		setParticipants(participant: IParticipantList[]) {
			this.participantList = participant;
		},

		setOpenModal(open: boolean) {
			this.modalIsOpen = open;
		},

		showConfirmDeleteOne(id: string, confirm: boolean) {
			this.confirmDeleteOneCoupon = id;
			this.confirmDeleteOne = confirm;
		},

		onConfirmDeleteOne() {
			deleteParticipantToActivity(this.confirmDeleteOneCoupon, async () => {
				await getParticipantSession(this.setParticipants);
				await getActivitySession(this.setSessionsActivities);
				this.showConfirmDeleteOne("", false);
				toast.success("Participante excluído dessa atividade!");
			});
		},
	},
});
