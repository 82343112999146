
	import { defineComponent, ref, watch } from 'vue';

	import { IUsers } from '../interface';
	import { ROLES, getUser, useStoreUsers } from '../store';
	import BaseButton from '../../../../components/Atoms/BaseButton.vue';
	import BaseInput from '../../../../components/Atoms/BaseInput.vue';
	import Spinner from '../../../../components/Atoms/Spinner.vue';
	import IcSearch from '../../../../components/icons/IcSearch.vue';
	import IcTrash from '../../../../components/icons/IcTrash.vue';
	import IcEdit from '../../../../components/icons/IcEdit.vue';

	import IcVerticalDots from '../../../../components/icons/IcVerticalDots.vue';
	import { debounce } from '../../../../helpers/debounce';
	import Table from '../../../../components/Organism/Table/table.vue'


 export default defineComponent({
		name: 'MenuTable',
		components: {
			BaseInput,
			IcTrash,
      IcSearch,
      IcEdit,
      BaseButton,
			Table,
			Spinner
		},
		setup(props) {
	const storeUsers = useStoreUsers();

	const miniSpinner = ref(false);

	const updateUser = (user: IUsers) => {
		storeUsers.setUserToUpdate(user);
		storeUsers.setTab('Form');
	};

	

	const search = (value: string) => {
		storeUsers.setSearch(value);
		miniSpinner.value = true;
		const TRHEE_SECONDS = 1 * 1000;
		debounce(() => {
			storeUsers.setSpinner(true);
			miniSpinner.value = false;
			
			getUser(storeUsers.setUsers, storeUsers.search, () => {
				storeUsers.setSpinner(false);
			});
		}, TRHEE_SECONDS);
	};

	const tableActions = [{
		title:'Editar',
		icon: IcEdit,
		onClick: ((user:any) => updateUser(user))
	},{
		title:'Excluir',
		icon:IcTrash,
		onClick: ((user:any) => storeUsers.deleteUser(user.id))
	}]

	const columns = [{
		title:'Nome',
		value:'name',
		id:1,
		sort: true,
		width:'25%'
	},{
		title:'Papel',
		value:'roleName',
		id:2,
		sort: true,
		width:'25%'
	},{
		title:'Email',
		value:'email',
		width:'25%',
		id:3,
		sort: true
	}]

 return {
     columns,
		 tableActions,
		 storeUsers,
		 miniSpinner,
		 Table,
		 Spinner,
		 search,
		 getUser
    }
 } 
 })
