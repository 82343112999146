<template>
	<div class="box-spinner">
		<div class="spinner"></div>
	</div>
</template>
<style scoped>
	.box-spinner {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 10px;
	}

	.spinner {
		width: 15px;
		height: 15px;
		border: 3px solid white;
		border-top: 3px solid var(--color-primary);
		border-radius: 50%;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
