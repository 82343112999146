export interface IContractBenefits {
	id:number,
	name: string
}

export interface IContractDetails {
	action: string;
	category: string;
	classification: string;
	description: string;
	id: string;
	item_description: string
	item_value: string
	model_id: string
	green?: boolean
	gold?:boolean
	platinum?: boolean
	blue?: boolean
	black?:boolean
}


export enum enTypeContract {
  green = 1,
  blue = 2,
  gold = 4,
  platinum = 8,
  black = 16
}