<template>
  <svg
    width="24"
    height="24"
  >
    <g clip-path="url(#clip0_14630_218315)">
      <path
        d="M16.5312 11.9375C16.8047 11.6641 16.8047 11.2812 16.5313 11.0078L8.49219 2.91406C8.27344 2.64062 7.83594 2.64062 7.5625 2.91406L6.52344 4.00781C6.25 4.28125 6.25 4.66406 6.52344 4.9375L12.9766 11.5L6.52344 18.0078C6.25 18.2812 6.25 18.7188 6.52344 18.9375L7.5625 20.0312C7.83594 20.3047 8.27344 20.3047 8.49219 20.0312L16.5312 11.9375Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_14630_218315">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(24 24) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
