import auth from "@/middleware/auth";
import authAdmin from "@/middleware/authAdmin";
import RevenueDetails from "@/views/Admin/AccountingYear/AccountingYearEdit/Register/revenue-details/index.vue";
import AccountingYearEdit from "@/views/Admin/AccountingYear/AccountingYearEdit/index.vue";
import AccountingYear from "@/views/Admin/AccountingYear/index.vue";
import atividadePremium from "@/views/Admin/Atividade-premium/index.vue";
import Checklist from "@/views/Admin/Checklist/index.vue";
import Classification from "@/views/Admin/Classification/index.vue";
import CompanyEvents from "@/views/Admin/Company-events/index.vue";
import ContractDetails from "@/views/Admin/ContractBenefits/ContractDetails/index.vue";
import Contract from "@/views/Admin/ContractBenefits/index.vue";
import Convidado from "@/views/Admin/Convidado/index.vue";
import Costs from "@/views/Admin/Costs/index.vue";
import Coupon from "@/views/Admin/Coupon/index.vue";
import Credenciais from "@/views/Admin/Credenciais/index.vue";
import Dashboard from "@/views/Admin/Dashboard/index.vue";
import Emailmarketing from "@/views/Admin/Email-marketing/index.vue";
import EventCompanyAction from "@/views/Admin/Event-company-action/index.vue";
import Event from "@/views/Admin/Event/index.vue";
import Files from "@/views/Admin/Files/index.vue";
import FindRetailerAdmin from "@/views/Admin/FindRetailer/index.vue";
import FindRetailerSession from "@/views/Admin/FindRetailerSession/index.vue";
import Hospedagem from "@/views/Admin/Hospedagem/index.vue";
import Hotel from "@/views/Admin/Hotel/index.vue";
import LoginAdmin from "@/views/Admin/Login/index.vue";
import OfficialServices from "@/views/Admin/Mesas/index.vue";
import ParticipantAdmin from "@/views/Admin/Participant/index.vue";
import PassagensAereas from "@/views/Admin/Passagens-aereas/index.vue";
import PreRegistration from "@/views/Admin/PreRegistration/index.vue";
import Prestadores from "@/views/Admin/Prestadores/index.vue";
import Questionnaire from "@/views/Admin/Questionnaire/index.vue";
import ScheduleAdmin from "@/views/Admin/Schedule/index.vue";
import Settings from "@/views/Admin/Settings/index.vue";
import Speakers from "@/views/Admin/Speakers/index.vue";
import Taxas from "@/views/Admin/Taxas/index.vue";
import Users from "@/views/Admin/Users/index.vue";
import CompanyEdit from "@/views/Admin/companys/CompanyEdit/index.vue";
import Company from "@/views/Admin/companys/index.vue";
import newFindRetailerBusinessClub from "@/views/Admin/findRetailerBussinesClub/NewVersion/index.vue";
import findRetailerNotBussinessClub from "@/views/Admin/findRetailerNotBussinesClub/index.vue";
import Certificate from "@/views/Certificate/index.vue";
import Credential from "@/views/Credential/index.vue";
import FindRetailer from "@/views/FindRetailer/index.vue";
import Guest from "@/views/Form/Guest.vue";
import IndividualBatchForm from "@/views/Form/IndividualBatchForm/index.vue";
import ParticipantSuccess from "@/views/Form/Participant.vue";
import Form from "@/views/Form/index.vue";
import Home from "@/views/Home/index.vue";
import Login from "@/views/Login/index.vue";
import Participant from "@/views/Participant/index.vue";
import LoginProvider from "@/views/Provider/index.vue";
import Schedule from "@/views/Schedule/index.vue";
import Voucher from "@/views/Voucher/index.vue";
import { createRouter, createWebHashHistory } from "vue-router";

const layoutFull = { layout: "LayoutFull", middleware: auth };
const layoutFullAdmin = { layout: "LayoutAdmin", middleware: authAdmin };
const defaultLayout = { layout: "DefaultLayout" };
const layoutOld = { layout: "LayoutOld", middleware: authAdmin };

const routes = [
	{
		path: "/cadastro/:id?",
		name: "Cadastro",
		component: Form,
		meta: defaultLayout,
	},
	{
		path: "/cadastro/:id/:ticket_batch_id/:pre_registration_id",
		name: "Cadastro Lote",
		component: IndividualBatchForm,
		meta: defaultLayout,
	},
	{
		path: "/sucesso-convidado",
		name: "Guest",
		component: Guest,
		meta: defaultLayout,
	},
	{
		path: "/sucesso-participante",
		name: "Participant",
		component: ParticipantSuccess,
		meta: defaultLayout,
	},
	{
		path: "/dados-participante/:id?",
		name: "Dados Participante",
		component: Participant,
		meta: layoutFull,
	},
	{
		path: "/participante",
		name: "Participante",
		component: Login,
		meta: defaultLayout,
	},
	{
		path: "/montadora",
		name: "Montadora",
		component: LoginProvider,
		meta: defaultLayout,
	},
	{
		path: "/confirmacao/:id?",
		name: "Inscrição",
		component: Credential,
		meta: layoutFull,
	},
	{
		path: "/voucher/:id?",
		name: "voucher",
		component: Voucher,
		meta: layoutFull,
	},

	{
		path: "/findRetailer/",
		name: "Encontre o varejista",
		component: FindRetailer,
		meta: layoutFull,
	},
	{
		path: "/findRetailerAdmin",
		name: "find",
		component: FindRetailerAdmin,
		meta: layoutFullAdmin,
	},
	{
		path: "/certificate/:id?",
		name: "Certificado",
		component: Certificate,
		meta: layoutFull,
	},
	{
		path: "/agenda",
		name: "Agenda",
		component: Schedule,
		meta: layoutFull,
	},
	{
		path: "/",
		name: "LoginAdmin",
		component: LoginAdmin,
	},
	{
		path: "/home",
		name: "Home",
		component: Home,
	},
	{
		path: "/activity",
		name: "",
		component: ScheduleAdmin,
		meta: layoutOld,
	},
	{
		path: "/event-company",
		name: "",
		component: CompanyEvents,
		meta: layoutOld,
	},
	{
		path: "/hotel",
		name: "",
		component: Hotel,
		meta: layoutOld,
	},
	{
		path: "/checklist",
		name: "",
		component: Checklist,
		meta: layoutOld,
	},
	{
		path: "/guests",
		name: "",
		component: Convidado,
		meta: layoutOld,
	},
	{
		path: "/passagens-aereas",
		name: "",
		component: PassagensAereas,
		meta: layoutOld,
	},
	{
		path: "/prestadores",
		name: "",
		component: Prestadores,
		meta: layoutOld,
	},
	{
		path: "/servicos-oficiais",
		name: "",
		component: OfficialServices,
		meta: layoutOld,
	},
	{
		path: "/table-reservation",
		name: "",
		component: OfficialServices,
		meta: layoutOld,
	},
	{
		path: "/hospedagem",
		name: "",
		component: Hospedagem,
		meta: layoutOld,
	},
	{
		path: "/costs",
		name: "",
		component: Costs,
		meta: layoutOld,
	},
	{
		path: "/upload-manuais",
		name: "",
		component: Files,
		meta: layoutOld,
	},

	{
		path: "/taxas",
		name: "",
		component: Taxas,
		meta: layoutOld,
	},

	{
		path: "/credenciais",
		name: "",
		component: Credenciais,
		meta: layoutOld,
	},
	{
		path: "/email-marketing",
		name: "",
		component: Emailmarketing,
		meta: layoutOld,
	},

	{
		path: "/event-company-action",
		name: "",
		component: EventCompanyAction,
		meta: layoutOld,
	},

	// {
	// 	path: "/push-notification",
	// 	name: "",
	// 	component: Notification,
	// 	meta: layoutOld,
	// },
	{
		path: "/users",
		name: "Usuários",
		component: Users,
		meta: layoutFullAdmin,
	},
	{
		path: "/settings",
		name: "Configurações",
		component: Settings,
		meta: layoutFullAdmin,
	},
	{
		path: "/speaker",
		name: "Palestrante",
		component: Speakers,
		meta: layoutFullAdmin,
	},
	{
		path: "/contract",
		name: "Modelos de contrato",
		component: Contract,
		meta: layoutFullAdmin,
	},
	{
		path: "/contract/:id",
		name: "Benefícios do Contrato",
		component: ContractDetails,
		meta: layoutFullAdmin,
	},
	{
		path: "/event",
		name: "Eventos",
		component: Event,
		meta: layoutFullAdmin,
	},
	{
		path: "/company",
		name: "Empresas",
		component: Company,
		meta: layoutFullAdmin,
	},
	{
		path: "/company/:id",
		name: "Empresa",
		component: CompanyEdit,
		meta: layoutFullAdmin,
	},
	{
		path: "/revenues",
		name: "Receitas por vigência",
		component: AccountingYear,
		meta: layoutFullAdmin,
	},
	{
		path: "/revenues/:id",
		name: "Detalhes da receitas por vigência",
		component: AccountingYearEdit,
		meta: layoutFullAdmin,
	},
	{
		path: "/revenues/:id/:idDetail",
		name: "Detalhes da receita",
		component: RevenueDetails,
		meta: layoutFullAdmin,
	},
	{
		path: "/classification",
		name: "Classificação",
		component: Classification,
		meta: layoutFullAdmin,
	},
	{
		path: "/questionnaire",
		name: "Questionário",
		component: Questionnaire,
		meta: layoutFullAdmin,
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		component: Dashboard,
		meta: layoutFullAdmin,
	},
	{
		path: "/participant",
		name: "Participantes",
		component: ParticipantAdmin,
		meta: layoutFullAdmin,
	},
	{
		path: "/pre-registration",
		name: "Pré-inscrição",
		component: PreRegistration,
		meta: layoutFullAdmin,
	},
	{
		path: "/coupon",
		name: "Gestão de inscrições",
		component: Coupon,
		meta: layoutFullAdmin,
	},
	{
		path: "/findRetailerBusinessClub",
		name: "Encontre o Varejista",
		component: newFindRetailerBusinessClub,
		meta: layoutFullAdmin,
	},
	{
		path: "/findRetailerNotBussinesClub",
		name: "findRetailerNotBussinesClub",
		component: findRetailerNotBussinessClub,
		meta: layoutFullAdmin,
	},
	{
		path: "/findRetailerSession",
		name: "findRetailerSession",
		component: FindRetailerSession,
		meta: layoutFullAdmin,
	},
	{
		path: "/atividade-premium",
		name: "atividade-premium",
		component: atividadePremium,
		meta: layoutFullAdmin,
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

function nextFactory(context: any, middleware: any, index: any) {
	const subsequentMiddleware = middleware[index];
	// If no subsequent Middleware exists,
	// the default `next()` callback is returned.
	if (!subsequentMiddleware) return context.next;

	return (...parameters: any) => {
		// Run the default Vue Router `next()` callback first.
		context.next(...parameters);
		// Then run the subsequent Middleware with a new
		// `nextMiddleware()` callback.
		const nextMiddleware = nextFactory(context, middleware, index + 1);
		subsequentMiddleware({ ...context, next: nextMiddleware });
	};
}

router.beforeEach((to, from, next) => {
	if (to.meta.middleware) {
		const middleware = Array.isArray(to.meta.middleware)
			? to.meta.middleware
			: [to.meta.middleware];
		const context = {
			from,
			next,
			router,
			to,
		};
		const nextMiddleware = nextFactory(context, middleware, 1);

		return middleware[0]({ ...context, next: nextMiddleware });
	}

	return next();
});

export { router };
