import jwt_decode from 'jwt-decode';

export enum TOKEN_NAME {
	TOKEN = 'token',
	OLD_TOKEN = 'oldToken',
}

export class UseStorage {
	public static getToken = (name = TOKEN_NAME['TOKEN']): string => {
		const token = sessionStorage.getItem(name);
		if (!token) return '';
		return token.replace('Bearer ', '');
	};

	public static setToken = (token: string, name = TOKEN_NAME['TOKEN']): void => {
		sessionStorage.setItem(name, 'Bearer ' + token);
		const tokenData = UseStorage.getTokenData(name);
		if (!tokenData) return;
		sessionStorage.setItem('token_data', JSON.stringify(tokenData))
		if (name === TOKEN_NAME['TOKEN']) this.setEventSelected(tokenData.event_id);
	};

	public static removeToken = (name = TOKEN_NAME['TOKEN']): void => {
		sessionStorage.removeItem(name);
	};

	public static getTokenData = (name = TOKEN_NAME['TOKEN']): { id: string; company_id: string; event_id: string } | undefined => {
		const token = UseStorage.getToken(name);
		if (!token) return undefined;
		const { user }: any = jwt_decode(token);
		if (!user) return undefined;
		return user as any;
	};

	public static setEventSelected = (event_id: string): void => {
		sessionStorage.setItem('event_id', event_id);
	};

	public static removeEventSelected = (): void => {
		sessionStorage.removeItem('event_id');
	};

	public static getEventSelected = (): string | undefined => {
		const event_id = sessionStorage.getItem('event_id');
		if (!event_id) return undefined;
		return event_id;
	};
}
