import { ref } from 'vue';

import { IHandlerOptionsFetch } from '@/helpers';

const optionsHttp: IHandlerOptionsFetch = {
	url: '',
	method: 'POST',
	old_url: true,
	headers_config: {
		Authorization: 'old_token',
		'Content-Type': 'json',
	},
	body: JSON.stringify([]),
};

const store = {
	labelStatus: ref(''),
	isLoadingConfirmRequest: ref(false),
	termAccept: ref(false),
	policyAccept: ref(false),
};

const actions = {
	teste() {
	},
};

export const scheduleSubscriptionStore = { ...store, ...actions, optionsHttp };
