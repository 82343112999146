import { HttpAdapterCustomer, urlOldApi } from "@/helpers";
import { useToast } from "vue-toastification";

const toast = useToast()

export const getCompanyManager = async (setValue: any, companySpots: any, callback: (data: any) => void) => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: "admin/event/companies",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	setValue(data[0].company.name)
	companySpots(data[0].spots_marketing_activity)
	return callback(data);
}

export const getParticipantSession = async (setValue: any) => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: "admin/v2/participant/session",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return console.error(response);
	return setValue(data)
};

export const getActivitySession = async (setValues: any) => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: "admin/activity/session",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return console.error(response);
	setValues(data)
};

export const getSpotsActivity = async (setValues: any) => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: "/admin/event/companies/event/bcf146b7-e1e6-42c3-aa6a-a405888c8e7e/company/b9c8af5b-5375-413f-9a1f-abada785629e",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return console.error(response);
	setValues(data)
};

export const postParticipantInActivities = async (activitiesId: { activityIds: string[] }, participantId: string, callback: () => void) => {
	const { data, response } = await HttpAdapterCustomer.post({
		url: `admin/restricted-list/participant/${participantId}/activities`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
			"Content-Type": 'json'
		},
		body: JSON.stringify(activitiesId),
	});
	if (!response.ok) return console.error(response);
	if (data.errors.length > 0) {
		const errorMessage = data.errors.join('\n');
		toast.warning('Foram encontrada divergências em algumas atividades: ' + ('\n') + errorMessage);
		return
	}
	callback()
};

export const deleteParticipantToActivity = async (participantId: string, callback: () => void) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/restricted-list/${participantId}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) console.error(response);
	callback();
};