import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "tabs" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseMultipleSelect = _resolveComponent("BaseMultipleSelect")!
  const _component_PieChart = _resolveComponent("PieChart")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseMultipleSelect, {
      label: "Tipo de empresa *",
      value: _ctx.store.companyTypesSelected,
      onChange: _cache[0] || (_cache[0] = 
        (event) => {
          _ctx.store.setCompanyTypeSelected(event);
          _ctx.store.setParticipantsFilter(
            _ctx.store.participants.filter((el) => event.includes(el.type))
          );
        }
      ),
      options: 
        _ctx.store.companyType.map((el) => {
          return {
            value: el.value,
            label: el.text,
          };
        })
      
    }, null, 8, ["value", "options"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.keys, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["tab", _ctx.store.tabPreEvent === tab.type ? 'tab-selected' : '']),
          ref_for: true,
          ref: "tab",
          onClick: ($event: any) => {
          _ctx.store.setTabPreEvent(tab.type);
          // store.setParticipantsFilter(store.participants.
          // filter(t => store.companyTypesSelected.indexOf(t.type) > -1));
        },
          key: tab.id
        }, _toDisplayString(tab.display), 11, _hoisted_3))
      }), 128))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.keys, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        style: {"margin":"20px"}
      }, [
        (_ctx.store.participantsFilter && _ctx.store.tabPreEvent === item.type)
          ? (_openBlock(), _createBlock(_component_PieChart, {
              key: 0,
              width: 600,
              height: 600,
              labels: _ctx.store.participantsFilter.map((el) => el.name).filter(el => el !== 'Total Geral'),
              data: _ctx.getPercentage(_ctx.store.participantsFilter.filter((el) => el.name !== 'Total Geral'), _ctx.store)
            }, null, 8, ["labels", "data"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    (_ctx.store.tab === 'pre-event')
      ? (_openBlock(), _createBlock(_component_Table, {
          key: 0,
          columns: [{
          title: 'Empresa',
          value: 'name',
          width: '33%',
          sort: true
        },
        {
          title: 'Inscritos',
          value: 'registered',
          width: '20%',
        },
        {
          title: 'Confirmados',
          value: 'confirmed',
          width: '20%',
        },
        {
          title: 'Não confirmados',
          value: 'notConfirmed',
          width: '20%',
        },
      ],
          overflowX: "hidden",
          onOnsort: _cache[1] || (_cache[1] = (column,direction) => {
         _ctx.orderByColumn(column,direction)
			}),
          rows: _ctx.store.participantsFilter
        }, null, 8, ["rows"]))
      : _createCommentVNode("", true),
    (_ctx.store.tab === 'pos-event')
      ? (_openBlock(), _createBlock(_component_Table, {
          key: 1,
          columns: [{
          title: 'Empresa',
          value: 'name',
          width: '30%',
          sort: true
        },
        {
          title: 'Inscritos',
          value: 'registered',
          width: '10%',
        },
        {
          title: 'Ausentes',
          value: 'notPresent',
          width: '10%',
        },
        {
          title: 'Presentes',
          value: 'present',
          width: '10%',
        },
        {
          title: 'Presentes na hora',
          value: 'present at time',
          width: '10%',
        },
        {
          title: 'Total Presentes',
          value: 'Total presentes',
          width: '10%',
        }],
          overflowX: "hidden",
          onOnsort: _cache[2] || (_cache[2] = (column,direction) => {
       _ctx.orderByColumn(column,direction)
			}),
          rows: _ctx.store.participantsFilter
        }, null, 8, ["rows"]))
      : _createCommentVNode("", true)
  ]))
}