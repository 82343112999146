
import { defineComponent } from "vue";

export default defineComponent({
	name: "ToggleSwitch",
	props: {
		checked: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit }) {
		const onChange = (event: Event) => {
			emit("change", (event.target as HTMLInputElement).checked);
		};

		return {
			onChange,
		};
	},
});
