import { IParticipantDataUpdate } from '../dto/IParticipantData';
import { formatDate } from '@/helpers/FormatDate';
import { removeMaskCpf, removeMaskPhone } from '@/helpers/mask';
import { IParticipantState } from '@/views/Participant/dto/IParticipantState';

export function participantMapperUpdate(participantState: IParticipantState): IParticipantDataUpdate {
	const participant: IParticipantDataUpdate = {
		name: participantState.name,
		name_credential: participantState.credential,
		cpf: removeMaskCpf(participantState.cpf),
		enterprise_mail: participantState.emailBusinesses,
		email: participantState.emailPersonal,
		enterprise_phone: removeMaskPhone(participantState.enterprisePhone),
		residence_phone: removeMaskPhone(participantState.residencePhone),
		phone: removeMaskPhone(participantState.phone),
		birth_date: formatDate(participantState.birthday),
		address: participantState.address,
		company_invited: participantState.guestCompany,
		register: participantState.register,
		description_role: participantState.descriptionPosition,
		type_role: participantState.typePosition,
		gender: participantState.gene,
		company: participantState.company,
		invited_by: participantState.nameCompany,
		profile_img: participantState.image,
	};
	return participant;
}
