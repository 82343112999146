<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="192"
		height="192"
		fill="#000000"
		viewBox="0 0 256 256"
	>
		<rect width="256" height="256" fill="none"></rect>
		<polyline
			points="216 184 216 40 72 40"
			fill="none"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="16"
		></polyline>
		<rect
			x="40"
			y="72"
			width="144"
			height="144"
			fill="none"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="16"
		></rect>
	</svg>
</template>
