import { HttpAdapterCustomer, urlOldApi } from "@/helpers";

export const getClassification = async (
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/classification${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();

	if (!response.ok) return;
	setValue(data);
};

export const postClassification = async (
	setValue: (p: any) => void,
	callback: () => void
): Promise<any | void> => {
	const data = await HttpAdapterCustomer.post({
		url: `admin/classification`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	// setValue(data);
};

export const loadPositionsType = async (
	setValue: any,
	callback: () => void
) => {
	await fetch(`${urlOldApi}/admin/positionType`)
		.then((response) => {
			callback();
			if (response.ok) {
				response.json().then((data) => setValue(data));
			}
		})
		.catch((err) => callback());
};

export const loadCompaniesType = async (
	setValue: any,
	callback: () => void
) => {
	await fetch(`${urlOldApi}/admin/companyType`)
		.then((response) => {
			callback()
			if (response.ok) {
				response.json().then((data) => setValue(data));
			}
		})
		.catch((err) => callback());
};

export const loadSponsorshipsType = async (
	setValue: any,
	callback: () => void
) => {
	await fetch(`${urlOldApi}/admin/sponsorshipType`)
		.then((response) => {
			callback();
			if (response.ok) {
				response.json().then((data) => setValue(data));
			}
		})
		.catch((err) => {
			callback();
		});
};
