
import BaseInput from "../../../components/Atoms/BaseInput.vue";
import MenuTable from "../../../components/Organism/Table/menu.vue";
import IcArrowDownSort from "../../../components/icons/IcArrowDownSort.vue";
import IcArrowUpSort from "../../../components/icons/IcArrowUpSort.vue";
import IcSearch from "../../../components/icons/IcSearch.vue";
import { debounce } from "../../../helpers/debounce";
import { IUsers } from "../../../views/Admin/Users/interface";
import { useStoreQuestions } from "@/views/Admin/Questionnaire/store";
import Vue, {
	Component,
	defineComponent,
	onMounted,
	reactive,
	ref,
	watch,
} from "vue";

interface TableColumns {
	title: string;
	value: string;
	id?: number;
	icon?: { success: Component; fail: Component };
	sort?: boolean;
	list?: string[];
	iconList?: any;
	check?: boolean;
	button?: any;
	width?: string;
	checkValue?: string;
	checked?: any;
	doubleText?: { bold: string; text: string };
}

interface TableActions {
	onClick: (row: any) => void;
	icon: any;
	title: string;
}
export default defineComponent({
	name: "Table",
	props: {
		tableActions: {
			type: Array as Vue.PropType<TableActions[]>,
			required: false,
		},
		rows: {
			type: Array as Vue.PropType<any[]>,
			required: true,
		},
		columns: {
			type: Object as Vue.PropType<TableColumns[]>,
		},
		overflowX: {
			type: String,
			required: false,
			default: "auto",
		},
	},
	components: {
		MenuTable,
		IcArrowDownSort,
		IcArrowUpSort,
		BaseInput,
	},
	setup(props) {
		const columns = props.columns;
		const handleActions = props.tableActions;
		let type = "asc";
		let columnSelected = "name";

		return {
			columns,
			handleActions,
			MenuTable,
			console,
			props,
			IcArrowDownSort,
			IcArrowUpSort,
			type,
			columnSelected,
		};
	},
	methods: {
		onClick(click: any, row: any) {
			click(row);
		},
		onSort(column: any, direction: any) {
			this.$emit("onsort", column, direction);
			this.type = direction;
			this.columnSelected = column;
		},
	},
});
