
import BaseButton from "../../../../components/Atoms/BaseButton.vue";
import BaseInput from "../../../../components/Atoms/BaseInput.vue";
import Spinner from "../../../../components/Atoms/Spinner.vue";
import Table from "../../../../components/Organism/Table/table.vue";
import IcEdit from "../../../../components/icons/IcEdit.vue";
import IcSearch from "../../../../components/icons/IcSearch.vue";
import IcTrash from "../../../../components/icons/IcTrash.vue";
import IcVerticalDots from "../../../../components/icons/IcVerticalDots.vue";
import { debounce } from "../../../../helpers/debounce";
import { ISpeakers } from "../interface";
import { getSpeaker } from "../service";
import { ROLES, useStoreSpeakers } from "../store";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
	name: "MenuTable",
	components: {
		BaseInput,
		IcTrash,
		IcSearch,
		IcEdit,
		BaseButton,
		Table,
		Spinner,
	},
	setup(props) {
		const storeSpeakers = useStoreSpeakers();

		const miniSpinner = ref(false);

		const updateSpeaker = (speaker: ISpeakers) => {
			storeSpeakers.setSpeakerToUpdate(speaker);
			storeSpeakers.setTab("Form");
		};

		const search = (value: string) => {
			storeSpeakers.setSearch(value);
			miniSpinner.value = true;
			const TRHEE_SECONDS = 1 * 1000;
			debounce(() => {
				storeSpeakers.setSpinner(true);
				miniSpinner.value = false;

				getSpeaker(storeSpeakers.setSpeakers, storeSpeakers.search, () => {
					storeSpeakers.setSpinner(false);
				});
			}, TRHEE_SECONDS);
		};

		const tableActions = [
			{
				title: "Editar",
				icon: IcEdit,
				onClick: (speaker: any) => updateSpeaker(speaker),
			},
			{
				title: "Excluir",
				icon: IcTrash,
				onClick: (speaker: any) => storeSpeakers.deleteSpeaker(speaker.id),
			},
		];

		const columns = [
			{
				title: "Nome",
				value: "name",
				id: 1,
				sort: true,
				width: "40%",
			},
			{
				title: "Email",
				value: "email",
				id: 3,
				sort: true,
				width: "40%",
			},
		];

		return {
			columns,
			tableActions,
			storeSpeakers,
			miniSpinner,
			Table,
			Spinner,
			search,
			getSpeaker,
		};
	},
});
