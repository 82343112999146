
	import { useRoute } from 'vue-router';

	import DefaultLayout from './DefaultLayout.vue';
	import LayoutFull from './LayoutFull.vue';
	import LayoutOld from './LayoutOld.vue'

	const defaultLayout = 'DefaultLayout';
	export default {
		name: 'AppLayout',
		components: {
			DefaultLayout,
			LayoutFull,
			LayoutOld
		},
		computed: {
			layout() {
				const route = useRoute();
				return route.meta.layout || defaultLayout;
			},
		},
	};
