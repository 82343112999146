
import BaseInput from "../../../../../components/Atoms/BaseInput.vue";
import Table from "../../../../../components/Organism/Table/table.vue";
import IcCheckVue from "../../../../../components/icons/IcCheck.vue";
import IcEditVue from "../../../../../components/icons/IcEdit.vue";
import IcMessageVue from "../../../../../components/icons/IcMessage.vue";
import IcSearch from "../../../../../components/icons/IcSearch.vue";
import IcTrashVue from "../../../../../components/icons/IcTrash.vue";
import { debounce } from "../../../../../helpers/debounce";
import { getSpeaker } from "../../service";
import { useStoreParticipantAdmin } from "../../store";
import IcDownload from "@/components/icons/IcDownload.vue";
import { urlOldApi } from "@/helpers";
import { TOKEN_TYPE, eventStore } from "@/store/event.store";
import { defineComponent, onMounted, ref } from "vue";
import { useToast } from "vue-toastification";

export default defineComponent({
	name: "Speaker",
	components: {
		Table,
		BaseInput,
		IcSearch,
		IcCheckVue,
	},
	setup() {
		const miniSpinner = ref(false);
		const store = useStoreParticipantAdmin();
		const toast = useToast();
		const { getStoreToken } = eventStore;
		const search = (value: string) => {
			store.setSearch(value);
			const TRHEE_SECONDS = 1 * 1000;
			miniSpinner.value = true;
			debounce(() => {
				store.setSpinner(true);
				miniSpinner.value = false;

				getSpeaker(store.setSpeaker, store.search, () => {
					store.setSpinner(false);
				});
			}, TRHEE_SECONDS);
		};

		const columns = [
			{
				title: "Nome",
				value: "name",
				id: 1,
				sort: true,
				width: "20%",
				doubleText: {
					bold: "name",
					text: "cpfFormat",
				},
			},
			{
				title: "Nome Credencial",
				value: "credential_name",
				id: 1,
				sort: false,
				width: "20%",
			},
			{
				title: "Empresa",
				value: "companyName",
				id: 1,
				sort: false,
				width: "20%",
			},
			{
				title: "Email Comercial",
				value: "enterprise_mail",
				id: 5,
				sort: true,
				width: "20%",
			},
		];

		const tableActions = [
			{
				icon: IcEditVue,
				title: "Editar",
				onClick: (row: any) => {
					store.setHasSearch(true);
					store.setTab("form");
					store.setParticipantToUpdate(row);
				},
			},
			{
				icon: IcMessageVue,
				title: "Enviar Convite",
				onClick: (row: any) => {
					store.sendInvite(row);
				},
			},
			{
				icon: IcTrashVue,
				title: "Excluir",
				onClick: (row: any) => {
					store.showConfirmDeleteOne(row.id, true);
				},
			},
			{
				icon: IcDownload,
				title: "Download Voucher",
				onClick: (row: any) => {
					if (!row.airTravel || !row.room) {
						toast.error("Palestrante ainda não tem voucher disponível");
						return;
					}
					downloadVoucherSpeaker(row.id);
					toast.success("Download do Voucher feito com sucesso!");
				},
			},
		];

		const downloadVoucherSpeaker = async (speakerId: string) => {
			const url = `/admin/participant/voucher/${speakerId}`;
			let responseFetch: any = undefined;
			await fetch(urlOldApi + url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getStoreToken(TOKEN_TYPE["ADMIN_TOKEN"])}`,
				},
			})
				.then((response) => {
					responseFetch = response;
					return response.blob();
				})
				.then((blob) => URL.createObjectURL(blob))
				.then((url) => {
					window.open(url, "_blank");
					URL.revokeObjectURL(url);
				});
			if (!responseFetch?.ok) toast.error("Erro ao abrir o voucher");
		};
		onMounted(() => {
			store.setSpinner(true);
			getSpeaker(store.setSpeaker, store.search, () => {
				store.setSpinner(false);
			});
		});
		return {
			store,
			search,
			IcSearch,
			Table,
			BaseInput,
			columns,
			miniSpinner,
			getSpeaker,
			tableActions,
		};
	},
});
