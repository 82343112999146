import { AnswersProps, INewRow, IRow, NewQuestion, QuestionsProps } from "./interface";
import { getQuestions } from "./service";
import { HttpAdapterCustomer } from "@/helpers";
import moment from "moment";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

const initialQuestion = {
	description: "",
	required: false,
	section_id: "",
	type: "text" as "text" | "multiple-choice",
	max_description: "",
	min_description: "",
	id: "",
};

const initialValue = {
	description: "",
	event_id: "",
	id: "",
	order: 0,
	questions: [] as QuestionsProps[],
};

const getNewRows = (rows: IRow[]): INewRow[] => {
	const newRows: INewRow[] = [];

	rows.forEach((row) => {
		const { section, ...question } = row.question;
		const { id, description, order } = section;

		const newQuestion: NewQuestion = {
			...question,
			answer: row.answer,
		};

		const newRow = newRows.find((row) => row.id === id);

		if (newRow) {
			newRow.questions.push(newQuestion);
		} else {
			newRows.push({
				id,
				section_name: description,
				order,
				questions: [newQuestion],
			});
		}
	});

	return newRows;
};

export const useStoreQuestions = defineStore("useStoreQuestions", {
	state: () => ({
		addSectionQuestion: "",
		questions: [] as QuestionsProps[],
		answers: [] as any,
		tab: "Questions" as "Questions" | "Answer",
		type: "text" as "text" | "multiple-choice",
		addQuestion: { ...initialQuestion },
		sectionToUpdate: initialValue,
		answerToView: [],
		confirmDeleteOneId: "",
		confirmDeleteOne: false,
		confirmDeleteOneIdSection: "",
		confirmDeleteOneSection: "",
		spinner: false,
		openModalSection: false,
		directionSection: "up" as "up" | "down",
		published: false,
		renderList: true,
		openModalAnswerList: false,
	}),
	

	getters: {
		description: (state) => state.questions.map((item) => item.description),
	},

	actions: {
		setSpinner(spinner: boolean) {
			this.spinner = spinner;
		},
		setOpenModalSection(openModalSection: boolean) {
			this.openModalSection = openModalSection;
		},
		setOpenModalAnswerList(openModalAnswerList: boolean) {
			this.openModalAnswerList = openModalAnswerList;
		},
		setQuestions(questions: QuestionsProps[]) {
			this.questions = questions.sort((a, b) => {
				if (a.order > b.order) return 1;
				if (a.order < b.order) return -1;
				return 0;
			});
		},
		setAnswers(answers: AnswersProps[]) {
			this.answers = answers.map((item: any) => {
						item.cpf = item.cpf
							.replace(/\D/g, "")
							.replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3/$4");
						item.company = item.company.name;
						item.answers = getNewRows(item.answers);
						item.update_at = moment(item.updated_at).format("DD/MM/YY HH:mm");

				return item
			});
		},
		setTab(tab: "Questions" | "Answer") {
			this.tab = tab;
		},
		setDescriptionSection(value: any) {
			this.sectionToUpdate.description = value;
		},
		setValueInAddQuestion(key?: string, value?: any) {
			if (!key) {
				this.addQuestion = initialQuestion as any;
				return;
			}
			(this.addQuestion as any)[key] = value;
		},
		setQuestionToUpdate(question: any | undefined) {
			this.addQuestion = question;
		},
		setSectionToUpdate(section: any | undefined) {
			if (!section) {
				return (this.sectionToUpdate = initialValue);
			}
			const newSection = {
				...initialValue,
				id: section,
			};
			this.sectionToUpdate = newSection;
		},
		setChangeOrder(order: any | undefined) {
			this.sectionToUpdate.order = order ? order : initialValue;
		},
		showConfirmDeleteOne(id: string, confirm: boolean) {
			this.confirmDeleteOneId = id;
			this.confirmDeleteOne = confirm;
		},

		onConfirmDeleteOne() {
			deleteQuestion(this.confirmDeleteOneId, () => {
				this.showConfirmDeleteOne("", false);
				getQuestions(this.setQuestions, () => {});
			});
		},

		showConfirmDeleteOneSection(id: string, confirm: string) {
			this.confirmDeleteOneIdSection = id;
			this.confirmDeleteOneSection = confirm;
		},

		onConfirmDeleteOneSection() {
			deleteSection(this.confirmDeleteOneIdSection, () => {
				this.showConfirmDeleteOneSection("", "");
				getQuestions(this.setQuestions, () => {});
			});
		},

		setRenderList() {
			this.renderList = false;
			setTimeout(() => {
				this.renderList = true;
			}, 1);
		},

		setPublish(publish: boolean) {
			this.published = publish;
		},

		setAnswerToView(answer: any) {
			this.answerToView = answer;
			this.answerToView = answer ? answer : [];
		},
	},
});

export const deleteSection = async (id: string, callback: () => void) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/event/questions/section/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	const store = useStoreQuestions();
	const toast = useToast();
	if (!response.ok)
		return toast.error(data.message || "Erro ao excluir uma seção");
	toast.success("Seção excluída com sucesso!");
	getQuestions(store.setQuestions, () => {});
	callback();
};

export const deleteQuestion = async (id: string, callback: () => void) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/event/questions/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	const store = useStoreQuestions();
	const toast = useToast();
	if (!response.ok)
		return toast.error(data.message || "Erro ao excluir uma pergunta");
	toast.success("Pergunta excluída com sucesso!");
	getQuestions(store.setQuestions, () => {});
	callback();
};
