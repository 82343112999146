import { urlNewApi, urlOldApi } from './HttpAdapterCostumer';
import { TOKEN_TYPE, eventStore } from '@/store/event.store';

const CONTENT_TYPE = 'Content-Type';

export type IHandlerOptionsFetch = {
	url: string;
	old_url?: boolean;
	method: string;
	body?: any;
	timeout?:number,
	headers_config?: {
		[CONTENT_TYPE]?: 'none' | 'json' | 'multipart';
		Authorization?: 'none' | 'token' | 'old_token' | 'admin_token';
		
	};
};

type OptionsFetch = {
	url: string;
	urlApi: string;
	method: string;
	body?: any;
	headers?: {
		[CONTENT_TYPE]?: string;
		Authorization?: string;
	};
};

export const handlerOptionsFetch = ({ url, old_url, headers_config, method, body }: IHandlerOptionsFetch): OptionsFetch => {
	const urlApi = old_url ? urlOldApi : urlNewApi;
	const headers: any = {};
	if (headers_config?.[CONTENT_TYPE] !== 'none') headers[CONTENT_TYPE] = 'application/json';
	if(headers_config?.[CONTENT_TYPE] === 'multipart') headers[CONTENT_TYPE] = 'multipart/form-data';
	if (headers_config?.Authorization && headers_config?.Authorization !== 'none') {
		if (headers_config?.Authorization === 'token') headers['Authorization'] = `Bearer ${eventStore.getStoreToken(TOKEN_TYPE['NEW_TOKEN'])}`;
		if (headers_config?.Authorization === 'old_token') headers['Authorization'] = `Bearer ${eventStore.getStoreToken(TOKEN_TYPE['OLD_TOKEN'])}`;
		if (headers_config?.Authorization === 'admin_token') headers['Authorization'] = `Bearer ${eventStore.getStoreToken(TOKEN_TYPE['ADMIN_TOKEN'])}`;
	}
	return { url, urlApi, method, headers: headers || undefined, body: body };
};
