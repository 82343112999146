
// eslint-disable-next-line import/named
import { PropType, defineComponent } from "vue";

export default defineComponent({
	name: "BaseInput",
	props: {
		label: {
			type: String,
			default: "Nome",
		},
		maxLength: {
			type: String,
			default: "",
		},
		styleInput: {
			type: String,
			default: "",
		},
		error: {
			type: Object as PropType<{
				message: string;
				status: boolean;
			}>,
			// eslint-disable-next-line vue/require-valid-default-prop
			default: { message: "", status: false },
		},
		sizeDefault: {
			type: Boolean,
			default: true,
		},
		list: {
			type: String,
			default: "",
		},
		type: {
			type: String,
			default: "text",
		},
		value: {
			type: undefined,
			default: "",
		},
		checked: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		onChange(event: any) {
			this.$emit("onchange", event.target.value);
			this.$emit("onchangeCheck", event.target.checked);
			this.$emit("onInputChange", event);
		},
	},
});
