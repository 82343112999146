
	import { defineComponent, defineProps } from 'vue';
	

	export default defineComponent({
		name: 'BaseTextArea',
		props: {
			label: {
				type: String,
				default: 'Nome',
			},
			value: {
				type: String,
				default: '',
			},
			maxLenght: {
				type: String,
				default: 'maxLenght',
			},
			maxLengthText: {
				type: String,
				default: 'maxLenghtText',
			},
		},methods: {
			onchange(event: any) {
				this.$emit('onchange', event);
			}
		},
	});
