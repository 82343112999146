import { IEvent } from "./interface";
import { HttpAdapterCustomer } from "@/helpers/HttpAdapterCostumer";
import { eventStore, TOKEN_TYPE } from "@/store/event.store";
import jwtDecode from "jwt-decode";
import { reactive, ref } from "vue";
import { useToast } from "vue-toastification";

const toast = useToast();

const store = {
	tabSelected: ref(0),
	events: reactive<IEvent[]>([]),
	eventsToShow: reactive<IEvent[]>([]),
	years: reactive<{ id: string; value: number }[]>([]),
	yearFilter: ref(0),
	search: ref(""),
};

const { clearStore, getStoreToken } = eventStore;

export function useStoreModalChangeEvent() {
	const actions = {
		setTabSelected: (value: number) => {
			store.tabSelected.value = value;
		},
		setEvents: (value: IEvent[]) => {
			store.events.length = 0;
			const adminTokenData = jwtDecode<any>(
				getStoreToken(TOKEN_TYPE["ADMIN_TOKEN"])
			);
			if (adminTokenData.role === "company_manager") {
				value = value.filter((x) => {
					return [
						"Future Trends 2017",
						"FUTURE TRENDS 2018",
						"FUTURE TRENDS 2019",
						"FUTURE TRENDS 2020 - Digital",
						"FUTURE TRENDS 2020 - online",
						"FUTURE TRENDS 2020 - Edição Online",
						"MASTER CLASS 2020 - Edição Online",
						"FUTURE TRENDS 2021 - Edição Online",
						"FUTURE TRENDS 2022",
						"FUTURE TRENDS 2023",
					].includes(x.title);
				});
			}
			store.events.push(...value);
		},
		setYears: (value: { id: string; value: number }[]) => {
			store.years.length = 0;
			store.years.push(...value);
		},
		setEventsToShow: (value: IEvent[]) => {
			store.eventsToShow.length = 0;
			store.eventsToShow.push(...value);
		},
		setYearFilter: (value: number) => {
			store.yearFilter.value = value;
		},
		setSearch: (value: string) => {
			store.search.value = value;
		},
		getEvents: async (like?: string) => {
			const url = (() => {
				if (like) return `admin/event?like=${like}`;
				return "admin/event";
			})();
			const { data, response } = await HttpAdapterCustomer.get({
				url,
				old_url: true,
				headers_config: {
					Authorization: "admin_token",
				},
			});
			if (!response.ok)
				return toast.error(data.message || "Erro ao buscar os eventos");
			actions.setEvents(data);
			actions.getYears();
			actions.getEventByYear(new Date().getFullYear());
		},
		getYears: () => {
			const years = store.events.map((event) =>
				new Date(event.start_date).getFullYear()
			);
			const yearsNotDuplicated = [...new Set(years)].sort((a, b) => b - a);
			actions.setYears(
				yearsNotDuplicated.map((year: number) => ({
					id: String(year),
					value: year,
				}))
			);
		},
		getEventByYear: (year: number) => {
			const eventsThisYear = store.events.filter((event) => {
				const yearOfEvent = new Date(event.start_date).getFullYear();
				return yearOfEvent === year;
			});
			const eventsSorteredMoreOld = eventsThisYear.sort((a, b) => {
				const aDate = new Date(a.start_date);
				const bDate = new Date(b.start_date);
				return aDate > bDate ? -1 : 1;
			});
			actions.setEventsToShow(eventsSorteredMoreOld);
			return eventsSorteredMoreOld;
		},
	};

	return { ...store, ...actions };
}
