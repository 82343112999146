import { PreRegistrationData, PreRegistrationProps } from "./interface";
import { deletePreRegistration, getPreRegistration } from "./service";
import { SessionStorage } from "@/store/sessionStorage";
import { defineStore } from "pinia";

const eventId = SessionStorage.get("event");

// const event = SessionStorage.get("event");
export const useStorePreRegistrationAdmin = defineStore(
	"useStorePreRegistrationAdmin",
	{
		state: () => ({
			registration: {} as PreRegistrationProps,
			resume: {
				paid_total: "",
			},
			section: "List" as "List" | "Form",
			formData: {
				id: "",
				cpf: "",
				name: "",
				company: "",
				type_role: "",
				name_credential: "",
				description_role: "",
				email: "",
				phone: "",
				status: "",
				payment_status: "",
				coupon_id: "",
				foreign_document: false,
			},
			manualPaymentData: {
				pre_registration_id: "",
				method: "",
				amount: 0,
			},
			companyOptions: { id: "", name: "" } as any,
			positionOptions: {} as any,
			filter: {
				search: "",
				status: "",
			},
			spinner: false,
			pages: {
				size: "10" as "10" | "15" | "25",
				page: 0,
				totalItens: 0,
			},
			confirmDeleteOneId: "",
			confirmDeleteOne: false,
			actions: [] as any,
			renderList: true,
			totalPageCalc: 0,
			openModal: false,
			openModalCoupon: false,
			preRegistrationId: "",
		}),

		actions: {
			setRegistration(list: PreRegistrationProps) {
				this.registration = list;
				this.registration.total = +list.total;
				this.registration.data = list.data.map((item: any) => {
					if (this.actions.includes(item.id)) {
						item.checked = true;
					}
					return item;
				});
				this.totalPageCalc = Math.ceil(
					this.registration.total / Number(this.pages.size)
				);
			},
			setResume(info: any) {
				this.resume = info;
			},
			setPreRegistrationId(info: string) {
				this.preRegistrationId = info;
			},
			setMethod(method: "credit card" | "pix" | "boleto") {
				this.manualPaymentData.method = method;
			},
			setOpenModal(open: boolean) {
				this.openModal = open;
			},
			setOpenModalCoupon(open: boolean) {
				this.openModalCoupon = open;
			},
			setSection(section: "List" | "Form") {
				this.section = section;
			},
			setValueInPreRegistration(key?: string, value?: any) {
				if (!key) {
					this.formData = this.formData as any;
					return;
				}
				(this.formData as any)[key] = value;
			},
			setPreRegistrationToUpdate(update: any | undefined) {
				this.formData = update;
			},
			setCompanyOptions(updateOptions: any) {
				this.companyOptions = updateOptions;
			},
			setValueInCompanyOptions(value: any) {
				(this.formData.company as any) = value;
			},
			setPositionOptions(updateOptions: any) {
				this.positionOptions = updateOptions;
			},
			setValueInPositionOptions(value: any) {
				(this.formData.type_role as any) = value;
			},
			setSearch(value: any) {
				this.filter.search = value;
			},
			setSpinner(spinner: boolean) {
				this.spinner = spinner;
			},
			showConfirmDeleteOne(id: string, confirm: boolean) {
				this.confirmDeleteOneId = id;
				this.confirmDeleteOne = confirm;
			},
			onConfirmDeleteOne() {
				deletePreRegistration(this.confirmDeleteOneId, async () => {
					this.showConfirmDeleteOne("", false);
					await getPreRegistration(
						this.setRegistration,
						() => {},
						eventId.id,
						this.pages.size,
						this.pages.page,
						this.filter.search,
						this.filter.status,
						{ order: "name", direction: "ASC" }
					);
				});
			},
			setSelectFilter(value: any) {
				this.filter.status = value;
			},
			setActions(checked: any, value: boolean) {
				if (checked === "all") {
					this.actions = [];
					value = true;
					this.registration.data = this.registration.data.map((item: any) => {
						item.checked = true;
						this.actions.push(item.id);
						return item;
					});
				} else {
					if (checked === "clear") {
						this.actions = [];
						value = false;
						this.registration.data = this.registration.data.map((item: any) => {
							item.checked = false;
							return item;
						});
					} else {
						if (!value) {
							if (this.actions.includes(checked)) {
								const index = this.actions.indexOf(checked);
								this.actions.splice(index, 1);
								this.registration.data = this.registration.data.map(
									(item: any) => {
										if (item.id === checked) {
											item.checked = false;
										}
										return item;
									}
								);
							}
						} else {
							if (value) {
								this.actions.push(checked);
								this.registration.data = this.registration.data.map(
									(item: any) => {
										if (item.id === checked) {
											item.checked = true;
										}
										return item;
									}
								);
							}
						}
					}
				}
			},
		},
	}
);
