import  Toast, { useToast }  from 'vue-toastification';
import { defineStore } from 'pinia';

import { IContractBenefits, IContractDetails } from './interface';
import { HttpAdapterCustomer } from '@/helpers';
import { deleteContractBenefitApi, deleteContractDetailApi } from './service';

export const useStoreContractBenefits = defineStore('contractBenefits', {
	state: () => ({
		contractBenefits: [] as IContractBenefits[],
		tab: 'List' as 'List' | 'Form',
		tabDetails:'List' as 'List' | 'Form',
		contractBenefitToUpdate: undefined as IContractBenefits | undefined,
		search: '',
		spinner: false,
		contractDetails: [] as IContractDetails[],
		contractDetailsToUpdate: undefined as IContractDetails | undefined
	}),
	actions: {
		setContractDetails(contractDetails:IContractDetails[]){
			this.contractDetails = contractDetails.map((el) => {
				el.green = setCategory('Green',Number(el.category))
				el.blue = setCategory('Blue',Number(el.category))
				el.gold = setCategory('Gold',Number(el.category))
				el.platinum = setCategory('Platinum',Number(el.category))
				el.black = setCategory('Black',Number(el.category))

				return el
			})
		},
		updateContractDetails(contractBenefit: IContractDetails) {
			const index = this.contractDetails.findIndex((u) => u.id === contractBenefit.id);
			this.contractDetails[index] = contractBenefit;
		},
		setContractDetailsToUpdate(contractDetails: IContractDetails | undefined) {
			this.contractDetailsToUpdate = contractDetails;
		},
		setContractBenefits(contractBenefits: IContractBenefits[]) {
			this.contractBenefits = contractBenefits.map((el) => {
				return el
			});
		},
		addContractBenefit(contractBenefit: IContractBenefits) {
			this.contractBenefits.push(contractBenefit);
		},
		updateContractBenefit(contractBenefit: IContractBenefits) {
			const index = this.contractBenefits.findIndex((u) => u.id === contractBenefit.id);
			this.contractBenefits[index] = contractBenefit;
		},
		setContractBenefitToUpdate(contractBenefit: IContractBenefits | undefined) {
			this.contractBenefitToUpdate = contractBenefit;
		},
		setValueInContractBenefitToUpdate(key: string, value: string) {
			if (!this.contractBenefitToUpdate) this.contractBenefitToUpdate = {} as any;
			(this.contractBenefitToUpdate as any)[key] = value;
		},
		setTab(tab: 'List' | 'Form') {
			if (tab === 'List') this.setContractBenefitToUpdate(undefined);
			this.tab = tab;
		},
		setTabDetails(tab: 'List' | 'Form') {
			if (tab === 'List') this.setContractDetailsToUpdate(undefined);
			this.tab = tab;
		},
		setSearch(search: string) {
			this.search = search;
		},
		setSpinner(spinner: boolean) {
			this.spinner = spinner;
		},
		deleteContractBenefit(id:number){
			deleteContractBenefitApi(id)
		},
		deleteContractDetail(id:number,idModel:string){
			deleteContractDetailApi(id,idModel)
		}
	},
});


const setCategory = (category:'Green' | 'Blue' | 'Gold' | 'Platinum' | 'Black',value:number) => {

	const categoryOptions = {
		Green: [1,3,5,7,9,15,17,31],
		Blue: [2,3,6,7,10,15,18,31],
		Gold:[4,5,6,7,12,15,20,31],
		Platinum: [8,9,10,11,12,15,24,31],
		Black: [16,17,18,19,20,23,24,28,30,31]
	}

	return categoryOptions[category].includes(value)
	
	
}
export const ROLES = [
	{ id: 'admin', role: 'Administrador' },
	{ id: 'contractBenefit', role: 'Usuário' },
	{ id: 'company_manager', role: 'Gestor' },
	{ id: 'organizer', role: 'Organizador' },
	{ id: 'stand_builder', role: 'Montadora Oficial' },
	{ id: 'coordinator', role: 'Coordenador' },
];


