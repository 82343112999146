
// eslint-disable-next-line import/named
import { PropType, defineComponent } from "vue";

export default defineComponent({
	name: "BaseButton",

	props: {
		tag: {
			type: String,
			default: "button",
			required: false,
		},
		href: {
			type: String,
			default: null,
			required: false,
		},
		variant: {
			type: String as PropType<
				"primary" | "secondary" | "gray" | "new-secondary"
			>,
			default: "primary",
			required: false,
		},
	},

	emits: ["click"],

	data() {
		return {
			context: {
				primary: "primary",
				secondary: "secondary",
				gray: "gray",
				"new-secondary": "new-secondary",
			},
		};
	},
});
