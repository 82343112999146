import { urlOldApi } from './HttpAdapterCostumer';
export async function isUrlExists(url: string): Promise<boolean> {
	const resp = await fetch(url);
	return resp.ok;
}

export function existThisFile(fileName?: string): string {
	if (!fileName) return '';
	// const urlProd = 'https://eventosabrafarma.com.br/content/';
	const urlDev = urlOldApi.replace('/api','') + '/content/';

	if(fileName.includes('https')){
		return fileName;
	} else {
		return urlDev + fileName;

	}
}
