
import BaseButton from "../Atoms/BaseButton.vue";
import Vue, {
	Component,
	defineComponent,
	onMounted,
	reactive,
	ref,
	watch,
} from "vue";

export default defineComponent({
	name: "ConfirmModal",
	props: {
		text: {
			type: String,
			required: true,
		},
	},
	components: {
		BaseButton,
	},
	methods: {
		confirm() {
			this.$emit("confirm");
		},
		cancel() {
			this.$emit("cancel");
		},
	},
	setup(props: any) {
		const title = props.text;
		return {
			BaseButton,
			title,
		};
	},
});
