<script>
import { defineComponent } from 'vue'
import { onMounted } from 'vue-demi'
import { getCurrentEvent, getEventAccess } from '../service'
import { useStoreDashboard } from '../store'
import moment from 'moment'
import Table from '../../../../components/Organism/Table/table.vue'
export default defineComponent({
  name:'Access',
  components:{
    Table
  },
  setup() {
    const store = useStoreDashboard()
    onMounted(() => {
      store.reset()
      store.setIsLoading(true)
      getEventAccess(store.setAccess,() => {
      store.setIsLoading(false)
      })
      getCurrentEvent(store.setCurrentEvent,() => {
      store.setIsLoading(false)
      })
    })
   const getType = (item) => {
      const type = {
        'event':'Evento',
        'main':'Corporativa',
        'additional':'Complementar',
        'premium':'Atividade Premium',
        'marketing_activity':'Atividade Marketing'
      }
      return type[item]
  }

    return {
      console,
      getType,
      Table,
      store,
      moment
    }
  },
})
</script>

<template>
  <div style="display:flex;flex-direction:column" class="card">
      <div class="tabs">
				<div
          v-for="date of store.eventDates"
          ref="tab"
					class="tab"
					:class="store.currentDate === date? 'tab-selected' : ''"
					@click="store.changeDate(date)"
				>
					{{date}}
				</div>
      </div>
      <div v-for="section of store.sections" :key="section">
        <div style="width:100%;display:flex;justify-content:space-between;height:5vh">
          <p><b>{{getType(section)}}</b></p>
          <p>{{store.getTotal(section)?.geral || 0}} presente{{store.getTotal(section)?.geral === 1?'':'s'}}</p>
        </div>
      
        
        <Table
        style="margin-bottom:2vh;height: auto !important;"
        :rows="store.getAccess(section)?store.getAccess(section):[]"
        :columns="[{
          title:'Horário',
          value:'period',
          width:'5%'
        },{
          title:'Atividade',
          value:'title',
          width:'10%'
        },{
          title:'Participantes Presentes',
          value:'present',
          width:'15%'
        },{
          title:'Participantes Presentes na Hora',
          value:'presentAtTime',
          width:'15%'
        },{
          title:'Participantes Total',
          value:'participant',
          width:'10%'
        },{
          title:'Staff',
          value:'staff',
          width:'5%'
        },{
          title:'Palestrantes',
          value:'speaker',
          width:'10%'
        },{
          title:'Total',
          value:'geral',
          width:'10%'
        }
        ]"
        />
        <p v-if="!store.getAccess(section) || store.getAccess(section)?.length === 0">Nenhum acesso registrado até o momento</p>

      </div>
  </div>
</template>
