
import Vue, { defineComponent } from "vue";

export default defineComponent({
	name: "BaseSelect",
	props: {
		options: {
			type: Array as Vue.PropType<any[]>,
			required: true,
		},
		keys: {
			type: Object as Vue.PropType<{ keyValue: string; keyLabel: string }>,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		value: {
			type: String,
			required: true,
			default: "",
		},
		styleSelect: {
			type: String,
			required: false,
			default: "",
		},
		sizeDefault: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		onChange(event: any) {
			this.$emit("onChangeOption", event.target.value);
		},
	},
});
