import { useStoreEvents } from "./store";
import { HttpAdapterCustomer } from "@/helpers";
import { useToast } from "vue-toastification";

const toast = useToast();
export const uploadIntranet = async (file: any, callback: any) => {
	const formData = new FormData();
	formData.append("file", file);
	formData.append("resumableChunkNumber", "1");
	formData.append("resumableChunkSize", "1048576");
	formData.append("resumableCurrentChunkSize", "1896448");
	formData.append("resumableTotalSize", "1896448");
	formData.append("resumableType", file.type);
	formData.append(
		"resumableIdentifier",
		Date.now() + "-" + file.name.replaceAll(" ", "_").replaceAll(".", "")
	);
	formData.append("resumableFilename", file.name);
	formData.append("resumableRelativePath", file.name);
	formData.append("resumableTotalChunk", "");

	const unique =
		Date.now() + "-" + file.name.replaceAll(" ", "_").replaceAll(".", "");
	await HttpAdapterCustomer.post({
		url: `admin/event/upload?
		resumableChunkNumber=1&resumableChunkSize=1048576
		&resumableCurrentChunkSize=1896448
		&resumableTotalSize=1896448
		&resumableType=${file.type}
		&resumableIdentifier=${unique}
		&resumableFilename=${file.name}
		&resumableRelativePath=${file.name}
		&resumableTotalChunks=1`,
		old_url: true,
		body: formData,
		headers_config: {
			Authorization: "admin_token",
			"Content-Type": "none",
		},
	}).then(() => {
		setUploadProgress(
			{
				fileName: file.name,
				uniqueIdentifier: unique,
			},
			callback
		);
	});
};

const setUploadProgress = async (body: any, callback: any) => {
	await HttpAdapterCustomer.post({
		url: "admin/event/upload-completed",
		old_url: true,
		body: JSON.stringify(body),
		headers_config: {
			Authorization: "admin_token",
		},
	}).then(() => {
		toast.success("Importação feita com sucesso!");
		callback;
	});
};

export const getRunJob = async () => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: "admin/job/commands/run",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return toast.error("Erro ao executar o processo");

};
export const getHistoryIntranet = async (setValue: any, callback: any) => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/event/historic-import`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};
export const getOrganizers = async (
	eventId: any,
	setValue: any,
	callback: any
) => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/company`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const getOrganizersIds = async (
	eventId: any,
	setValue: any,
	callback: any
) => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/event/organizers/${eventId}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};
export const deleteEventApi = async (id: number) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/event/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	const toast = useToast();

	const storeEvents = useStoreEvents();
	if (!response.ok)
		return toast.error(data.message || "Erro ao cadastrar usuário");
	toast.success("Palestrante Excluido com sucesso");
	getEvent(storeEvents.setEvents, storeEvents.search, () => {
		storeEvents.setSpinner(false);
	});
};
export const getEvent = async (
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "desc";
	const order = sort?.order ? sort.order : "start_date";
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=25${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/event${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const importListTransmissionOnline = async (dateEvent: any, eventId: any, body: any, callback: any) => {
	await HttpAdapterCustomer.post({
		url: `admin/event/${dateEvent}/${eventId}/importTransmissionPlatform`,
		old_url: true,
		body: JSON.stringify({ data: body }),
		headers_config: {
			Authorization: "admin_token",
		},
	}).then((result) => {
		if (!result.response.ok) return toast.error('Erro ao importar participantes')
		callback();
	});
};
