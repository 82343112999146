<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fit=""
		height="100%"
		width="100%"
		preserveAspectRatio="xMidYMid meet"
		viewBox="0 0 24 24"
		focusable="false"
		fill="currentColor"
	>
		<g id="delete">
			<path
				d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
			></path>
		</g>
	</svg>
</template>
