import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-046ffd96"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "label"
}
const _hoisted_2 = ["value", "maxLength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", _mergeProps({ class: "textarea" }, _ctx.$attrs, {
      value: _ctx.value,
      maxLength: _ctx.maxLenght,
      onChange: _cache[0] || (_cache[0] = (event) => {
				_ctx.onchange(event)
			})
    }), null, 16, _hoisted_2),
    _createElementVNode("p", null, _toDisplayString(_ctx.maxLengthText), 1)
  ]))
}