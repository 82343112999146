import * as XLSX from 'xlsx'
// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
export const importParticipant = async (event: any, callBack: Function) => {
	const file = event?.target?.files;
	if (!file?.length) return;
	const fileToRead = file[0];
	const reader = new FileReader();

	const header = [
		'company',
		'name',
		'credentialName',
		'cpf',
		'register',
		'uf',
		'email',
		'position',
		'enterprise_phone',
		'home_phone',
		'personal_phone',
		'birth_date',
		'guest_company',
		'position_type',
		'classification',
	]
	reader.onload = (e: any) => {
		const data = e?.target?.result;
		const workBook = XLSX.read(data, { type: 'binary' });
		const firstSheetName = workBook.SheetNames[0];
		const workSheet = workBook.Sheets[firstSheetName];
		const dataFromFile = XLSX.utils.sheet_to_json(workSheet, {
			header: header,
			raw: true,
		});

		callBack(formatDataFile(dataFromFile as any[]));
	};
	reader.readAsBinaryString(fileToRead);
};

const formatDataFile = (dataFromFile: any[]): any[] => {
	return dataFromFile
};
