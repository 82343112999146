import { ref } from 'vue';

const store = {
	count: ref(0),
};

export function useStore() {
	const actions = {
		setCount() {
			store.count.value++;
		},
	};

	return { store, actions };
}
