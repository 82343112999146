import { IStandBuilder } from "./../../../interface/standBuilder";
import { getOrganizers, getOrganizersIds } from "./service";
import { existThisFile, HttpAdapterCustomer } from "@/helpers";
import { IEvents } from "@/interface/event";
import { DateTime } from "luxon";
import moment from "moment";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import { ImportIntranetProps } from "./interface";

const initialData: any = {
	organizersId: [],
	assets: [],
	credentialColors: [],
};

type StatusTypes = {
	[key: string]: string;
};

const STATUS: StatusTypes = {
	waiting: "Em espera",
	pending: "Pendente",
	processing: "Processando",
	finished: "Finalizado",
};

export const useStoreEvents = defineStore("events", {
	state: () => ({
		events: [] as IEvents[],
		isOnline: false,
		tab: "List" as "List" | "Form" | "import",
		session: 'event-register' as 'event-register' | 'montadora' | 'organizer' | 'badge' | 'acceptance-term',
		eventToUpdate: initialData,
		search: "",
		spinner: false,
		organizers: [],
		stand_builders: [] as any[],
		historyIntranet: [] as ImportIntranetProps[],
		standBuilderFile: {} as any,
		modalIsOpen: false,
		modalData: {} as any,
		dateToImport: '',
		bodyToImport: [],
	}),
	actions: {
		setStandBuilderFile(file: any) {
			this.standBuilderFile = file;
		},
		addFilename() {
			if (!this.standBuilderFile?.file || !this.standBuilderFile?.extensions) {
				useToast().error("É necessário inserir o nome do arquivo e a extensão");
				return;
			}

			this.eventToUpdate.stand_builder.stand_builder_files =
				this.eventToUpdate.stand_builder.stand_builder_files || [];
			this.eventToUpdate.stand_builder.stand_builder_files.push({
				file: this.standBuilderFile.file,
				extensions: this.standBuilderFile.extensions
					.split(",")
					.map((e: any) => e.replace(/\./g, "").toLowerCase().trim())
					.filter((e: any) => e),
			});

			this.standBuilderFile = {};
		},

		setOpenModal(open: boolean, data: any) {
			this.modalIsOpen = open;
			this.modalData = data;
		},

		setDataToImport(body: any) {
			this.bodyToImport = body;
		},

		removeFilename(filename: any) {
			this.eventToUpdate.stand_builder.stand_builder_files =
				this.eventToUpdate.stand_builder.stand_builder_files.filter(
					(file: any) => file.file !== filename
				);
		},
		setstandBuilders(builders: IStandBuilder[]) {
			this.stand_builders = builders.filter((s) => s.type === "stand_builder");
			if (this.eventToUpdate.stand_builder) {
				this.loadCompanies();
			}
		},
		loadCompanies() {
			this.stand_builders.forEach((s) => {
				if (s.id === this.eventToUpdate.stand_builder.company_id) {
					this.eventToUpdate.stand_builder.email = s.email;
					this.eventToUpdate.stand_builder.contact = s.contact;
				}
			});
		},
		getStandBuilderType() {
			const types = [
				{ id: "standBuilder", title: "Montadora" },
				{ id: "agency", title: "Agência" },
				{ id: "maintenance", title: "Manutenção" },
			];
			return types;
		},

		setHistoryIntranet(data: ImportIntranetProps[]) {
			this.historyIntranet = data.map((item: ImportIntranetProps) => {
				item.filename = item.filename,
					item.progress = String(item.progress + ' %'),
					item.status = STATUS[item.status]
				return item
			}).sort((a, b) => b.created_at.localeCompare(a.created_at));
		},

		setOrganizersIds(ids: any) {
			const event = this.eventToUpdate;

			if (event) {
				event.organizersId = ids.map((el: any) => el.company_id);
			}

			this.eventToUpdate = event;
		},
		setOrganizers(organizers: any) {
			this.organizers = organizers.filter((el: any) => {
				return el.type === "organizer";
			});
		},
		setEvents(events: IEvents[]) {
			this.events = events.map((el: any) => {
				const startDateFormat = DateTime.fromISO(el.start_date).toFormat(
					"dd/MM/yyyy"
				);
				const endDateFormat = DateTime.fromISO(el.end_date).toFormat(
					"dd/MM/yyyy"
				);
				const limitDateFormat = DateTime.fromISO(el.limit_date).toFormat(
					"dd/MM/yyyy"
				);
				el.start_date = DateTime.fromISO(el.start_date).toFormat("yyyy-MM-dd");
				el.end_date = DateTime.fromISO(el.end_date).toFormat("yyyy-MM-dd");
				el.limit_date = DateTime.fromISO(el.limit_date).toFormat("yyyy-MM-dd");
				el.dataList = [
					{
						title: "Inicio: ",
						value: startDateFormat,
					},
					{
						title: "Fim: ",
						value: endDateFormat,
					},
					{
						title: "Limite: ",
						value: limitDateFormat,
					},
				];

				el.spotList = [
					{
						title: "Confirmados: ",
						value: el.spots_used,
					},
					{
						title: "Reservados: ",
						value: el.spots_allocated - el.spots_used,
					},
					{
						title: "Disponíveis: ",
						value: el.spots - el.spots_allocated,
					},
					{
						title: "Total",
						value: el.spots,
					},
				];
				this.isOnline = el.online
				return el;
			});
		},
		addEvent(event: IEvents) {
			this.events.push(event);
		},
		updateEvent(event: IEvents) {
			const index = this.events.findIndex((u) => u.id === event.id);
			this.events[index] = event;
		},
		setEventToUpdate(event?: IEvents | undefined) {
			event?.stand_builder?.stand_builder_files?.map((el: any) => {
				el.extensions = el.extensions;
				return el;
			});
			this.eventToUpdate = event ? event : initialData;

			getOrganizers(event?.id, this.setOrganizers, () => { });
			getOrganizersIds(event?.id, this.setOrganizersIds, () => { });
		},
		setValueInEventToUpdate(key: string, value: any) {
			if (!this.eventToUpdate) this.eventToUpdate = {} as any;
			(this.eventToUpdate as any)[key] = value;
		},
		setTab(tab: "List" | "Form" | "import") {
			if (tab === "List") this.setEventToUpdate(undefined);
			this.tab = tab;
		},
		setSession(session: 'event-register' | 'montadora' | 'organizer' | 'badge' | 'acceptance-term') {
			if (session === "event-register") this.setEventToUpdate(undefined);
			this.session = session;
		},
		setSearch(search: string) {
			this.search = search;
		},
		setSpinner(spinner: boolean) {
			this.spinner = spinner;
		},
		deleteEvent(id: number) {
			deleteEventApi(id);
		},
		setDateToImport(date: string) {
			this.dateToImport = date;
		},
	},
});

export const ROLES = [
	{ id: "admin", role: "Administrador" },
	{ id: "event", role: "Usuário" },
	{ id: "company_manager", role: "Gestor" },
	{ id: "organizer", role: "Organizador" },
	{ id: "stand_builder", role: "Montadora Oficial" },
	{ id: "coordinator", role: "Coordenador" },
];

export const deleteEventApi = async (id: number) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/event/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	const toast = useToast();

	const storeEvents = useStoreEvents();
	if (!response.ok)
		return toast.error(data.message || "Erro ao cadastrar usuário");
	toast.success("Usuário Excluido com sucesso");
	getEvent(storeEvents.setEvents, storeEvents.search, () => {
		storeEvents.setSpinner(false);
	});
};
export const getEvent = async (
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/event${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};
