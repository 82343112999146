export function formatDate(value: string) {
	let date = value;
	if (isPatterBrazilDate(value)) date = convertDateISO8601(value);

	const convertDate = date.split('T')[0].replace(/-/g, '/');
	return new Date(convertDate).toLocaleDateString('en-CA').replace(/\//g, '-');
}

function isPatterBrazilDate(value: string) {
	return Boolean(value.includes('/'));
}

function convertDateISO8601(value: string) {
	return value.split('/').reverse().join('-');
}
