import { useStoreDashboard } from './../store';
import { defineComponent, h, PropType } from 'vue'

import { Pie } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  Plugin
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default defineComponent({
  name: 'PieChart',
  components: {
    Pie
  },

  props: {
    labels:{
      type:Array as PropType<String[]>,
      default:[]
    },
    data:{
      type:Array as PropType<any[]>,
      default:[]
    },
    colors:{
      type:Array as PropType<any[]>,
      default:[]
    },
    chartId: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    }
  },
  setup(props) {
    const chartData = {
      labels:props.labels,
      datasets: [
        {
          backgroundColor:props.data.map((el) =>'#' + Math.floor(Math.random()*16777215).toString(16)),
          data: props.data
        }
      ]
    }

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
        legend: {
            position: "right",
            align: "right",
            labels: {
                usePointStyle: true,
                boxWidth: 100
            }
        
      }
    }

    return () =>
      h(Pie, {
        chartData,
        chartOptions,
        chartId: props.chartId,
        width: props.width,
        height: props.height,
      })
  }
})
