import { companyType } from "./../../../constants/company-type";
import { getCompanysEvent, getCompanysEventParticipant } from "./service";
import moment from "moment";
import { defineStore } from "pinia";

const initialData: any = {};

const TYPES: any = {
	"not informed": "Nenhum",
	"commercial partner": "Parceiro Comercial",
	associated: "Associado",
	invited: "Convidado",
	government: "Governo",
	midia: "Imprensa",
	entity: "Entidade",
	organizer: "Organizadora",
	stand_builder: "Montadora Oficial",
	"ticket sale": "Inscrição",
};

export const useStoreDashboard = defineStore("dashboard", {
	state: () => ({
		tab: "pre-event",
		isLoading: false,
		eventToUpdate: initialData,
		spinner: false,
		valueSearchCompany: [],
		tabPreEvent: "subscribers",
		companys: [],
		companysFilter: [],
		participants: [],
		participantsFilter: [],
		keys: [
			{
				id: 1,
				type: "registered",
				display: "Inscritos",
				filter: (p: any) => p,
			},
			{
				id: 2,
				type: "confirmed",
				display: "Confirmados",
				filter: (p: any) => p.status === "accepted",
			},
			{
				id: 3,
				type: "notConfirmed",
				display: "Não confirmados",
				filter: (p: any) =>
					p.status === "invited" || p.status === "not invited",
			},
		] as any,
		totalP: {} as any,
		companyTypesSelected: Object.keys(TYPES),
		companyType: Object.keys(TYPES).map((el) => {
			return {
				value: el,
				text: TYPES[el],
			};
		}),
		questions: [] as any,
		totalSatisfaction: 0,
		totalPointsGeneral: 0,
		totalAnswered: 0,
		totalPoints: 0,
		sections: [
			"event",
			"premium",
			"marketing_activity",
			"main",
			"additional",
		] as any,
		eventDates: [] as any,
		total: {} as any,
		access: {} as any,
		currentDate: "",
	}),
	actions: {
		setIsLoading(loading: boolean) {
			this.isLoading = loading;
		},
		reset() {
			this.access = {};
			this.total = {};
		},
		setTab(tab: string) {
			this.tab = tab;

			if (tab === "pre-event") {
				this.keys = [
					{
						id: 1,
						type: "registered",
						display: "Inscritos",
						filter: (p: any) => p,
					},
					{
						id: 2,
						type: "confirmed",
						display: "Confirmados",
						filter: (p: any) => p.status === "accepted",
					},
					{
						id: 3,
						type: "notConfirmed",
						display: "Não confirmados",
						filter: (p: any) =>
							p.status === "invited" || p.status === "not invited",
					},
				];
				getCompanysEventParticipant(this.setParticipants, () => {
					this.setIsLoading(false);
				});
			} else {
				if (tab === "pos-event") {
					this.keys = [
						{
							id: 1,
							type: "registered",
							display: "Inscritos",
							filter: (p: any) => p,
						},
						{
							id: 2,
							type: "notPresent",
							display: "Ausentes",
							filter: (p: any) => p.status === "not present",
						},
						{
							id: 3,
							type: "present",
							display: "Presentes",
							filter: (p: any) => p.status === "present",
						},
						{
							id: 4,
							type: "present at time",
							display: "Presentes na hora",
							filter: (p: any) => p.status === "present at time",
						},
						{
							id: 5,
							type: "Total presentes",
							display: "Total presentes",
							filter: (p: any) =>
								p.status === "present at time" || p.status === "present",
						},
					];
				}
			}
			getCompanysEventParticipant(this.setParticipants, () => {
				this.setIsLoading(false);
			});
		},
		setTabPreEvent(tab: string) {
			this.tabPreEvent = tab;
		},
		setCompanyTypeSelected(value: any) {
			this.companyTypesSelected = value;
		},
		setValueCompanySearch(value: any) {
			this.valueSearchCompany = value;
		},
		setCompanys(companys: any) {
			this.companys = companys;
		},
		setParticipantsFilter(companys: any) {
			this.participantsFilter = companys
		},
		setParticipants(participants: any) {
			this.participants = this.calculate(this.formatData(participants)).concat(
				this.formatData(participants)
			);
			this.participantsFilter = this.calculate(
				this.formatData(participants)
			).concat(this.formatData(participants));
		},
		formatData(participants: any) {
			return participants
				.filter((p: any) => p.company)
				.reduce((acc: any, p: any) => {
					let company = acc.find((c: any) => c.id === p.company.id);
					if (!company) {
						company = p.company;
						company.participants = [];
						acc.push(company);
					}
					company.participants.push(p);
					return acc;
				}, [])
				.map((company: any) => {
					const data: any = { name: company.name, type: company.type };
					this.keys.forEach((key: any) => {
						data[key.type] = company.participants.filter(key.filter).length;
					});
					return data;
				});
		},
		calculate(participants: any) {
			this.keys.forEach((key: any) => {
				this.totalP[key.type] = participants.reduce(
					(prev: any, t: any) => prev + t[key.type],
					0
				);
			});

			return [{ ...this.totalP, name: "TOTAL GERAL" }] as any;
		},
		setAnswers(participants: any[]) {
			const questions = participants
				.reduce((questions, participant) => {
					participant.answers.forEach((answer: any) => {
						let question = questions.filter(
							(a: any) => a.id === answer.question.id
						)[0];

						if (!question) {
							question = answer.question;
							question.answers = [];
							questions.push(question);
						}

						delete answer.question;
						if (question.answers) {
							question.answers.push(answer);
						}
					});

					return questions;
				}, [])
				.filter((q: any) => q.type === "multiple-choice");
			let counter = 0
			questions.forEach((question: any) => {
				counter++;
				const subTotalPoints = question.answers.reduce(
					(acc: any, a: any) => acc + Number(a.answer),
					0
				);
				const totalAnswers = question.answers.reduce((acc: any) => acc + 10, 0);
				const standardDeviation = this.calculateStandard(
					question.answers.map((a: any) => Number(a.answer))
				);
				const satisfaction = Number(
					((subTotalPoints * 100) / totalAnswers).toFixed(1)
				);
				this.questions.push({
					order: counter,
					average: Number(
						(subTotalPoints / question.answers.length).toFixed(2)
					),
					satisfaction: satisfaction,
					description: question.description,
					standardDeviation: Number(standardDeviation.toFixed(2)),
					points: subTotalPoints,
					subTotalAnswers: question.answers.length * 10,
				});

				this.totalSatisfaction += satisfaction;
			});
			this.totalSatisfaction = Number(
				(this.totalSatisfaction / questions.length).toFixed(1)
			);

			this.totalPoints = this.questions.reduce(
				(acc: any, question: any) => acc + question.points,
				0
			);
			this.totalPointsGeneral = this.questions.reduce(
				(acc: any, question: any) => acc + question.subTotalAnswers,
				0
			);
			this.totalAnswered = participants.length;
		},
		calculateStandard(arr: any) {
			let mean =
				arr.reduce((acc: any, curr: any) => {
					return acc + curr;
				}, 0) / arr.length;

			arr = arr.map((k: any) => {
				return (k - mean) ** 2;
			});

			let sum = arr.reduce((acc: any, curr: any) => acc + curr, 0);

			let variance = sum / arr.length;

			return Math.sqrt(sum / arr.length);
		},
		setAccess(accesses: any) {
			accesses.forEach((access: any) => {
				access.participant =
					(access.present || 0) + (access.presentAtTime || 0);
				access.geral =
					(access.participant || 0) +
					(access.staff || 0) +
					(access.speaker || 0);

				this.generateTotal(access);
				// this.access.push(access)
				this.generateAccessList(access).push(access);
			});
			return this.total;
		},
		generateAccessList(data: any) {
			const { date, type } = data;
			const current = (this.access[date] = this.access[date] || {});
			return (current[type] = current[type] || []);
		},
		generateTotal(access: any) {
			const {
				date,
				type,
				present = 0,
				presentAtTime = 0,
				staff = 0,
				speaker = 0,
			} = access;
			const totalDate = (this.total[date] = this.total[date] || {});

			const current = (totalDate[type] = totalDate[type] || {
				staff: 0,
				speaker: 0,
				present: 0,
				presentAtTime: 0,
				get participant() {
					return (current.present || 0) + (current.presentAtTime || 0);
				},
				get geral() {
					return (
						current.participant + (current.staff || 0) + (current.speaker || 0)
					);
				},
			});
			current.staff += staff;
			current.speaker += speaker;
			current.present += present;
			current.presentAtTime += presentAtTime;

			return current;
		},
		changeDate(date: any) {
			this.currentDate = date;
		},
		getTotal(section: any) {
			return (
				this.total &&
				this.total[this.currentDate] &&
				this.total[this.currentDate][section]
			);
		},
		getAccess(section: any) {
			return (
				this.access &&
				this.access[this.currentDate] &&
				this.access[this.currentDate][section]
			);
		},
		setCurrentEvent(dates: any) {
			const days = this.getEventDays(dates);
			const mapDays = days.map((day) => moment(day).format("DD/MM"));
			this.currentDate = mapDays[0];
			this.eventDates = mapDays;
		},
		getEventDays(event: any) {
			let eventDays = [];
			let currentDate = moment(event.start_date);
			let endDate = moment(event.end_date);
			eventDays.push(currentDate);
			eventDays.push(endDate);

			eventDays = eventDays.map((d) => {
				return d.toDate();
			});

			return eventDays;
		},
	},
});
