import { useStoreCompanys } from "./store";
import { HttpAdapterCustomer, urlOldApi } from "@/helpers";
import { useToast } from "vue-toastification";

export const getSponsors = async (setValue: any, callback: any) => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/sponsorshipType`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const loadSponsors = async (setValue: any, callback: () => void) => {
	await fetch(`${urlOldApi}/admin/sponsorshipType`)
		.then((response) => {
			callback();
			if (response.ok) {
				response.json().then((data) => setValue(data));
			}
		})
		.catch((err) => callback());
};

export const deleteCompanyApi = async (id: string) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/company/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	const toast = useToast();

	const storeCompanys = useStoreCompanys();
	if (!response.ok)
		return toast.error(data.message || "Erro ao cadastrar usuário");
	toast.success("Empresa Excluida com sucesso");
	getCompany(storeCompanys.setCompanys, storeCompanys.search, () => {
		storeCompanys.setSpinner(false);
	});
};
export const getCompany = async (
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/company${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const getCompanyById = async (
	companyId: any,
	setValue: (p: any) => void,
	callback: () => void
) => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/company/${companyId}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const getGestores = async (
	companyId: any,
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `&direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/user?companyId=${companyId}${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const getUsersManagers = async (
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `?role=company_manager&direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/user${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const getExecutives = async (
	id: any,
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `&direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/participant/listForCompany/?companyId=${id}${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const searchCpfExecutive = async (
	cpf: any,
	setValue: (p: any, type: any) => void,
	callback: () => void
): Promise<any | void> => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/participant/find-by-cpf/${cpf}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) {
		callback();
		return;
	}
	setValue(data, "edit");
	callback();
};

export const loadDomains = () => {
	const store = useStoreCompanys();

	loadCompanys();
	loadPositions();
	loadPositionsType();
	loadClassification();

	const type = Object.keys(typeStatus).map((key: any) => {
		return {
			id: key,
			title: typeStatus[key],
		};
	});
	store.setDomains("status", type);
};

export const typeStatus: any = {
	"not invited": "Convite não Enviado",
	invited: "Convite Enviado",
	accepted: "Convite Aceito",
	unauthorized: "Não Autorizado",
	present: "Presente",
	"present at time": "Presente na hora",
	"not present": "Ausente",
	confirmed: "Confirmado",
	"subscription at time": "Inscrição na Hora",
};

const loadCompanys = async () => {
	const store = useStoreCompanys();

	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/company`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	store.setDomains("companys", data);
};

const loadPositions = async () => {
	const store = useStoreCompanys();

	await fetch(`${urlOldApi}/admin/position`)
		.then((response) => {
			if (response.ok) {
				response.json().then((data) => store.setDomains("positions", data));
			}
		})
		.catch((err) => console.error(err));
};

const loadPositionsType = async () => {
	const store = useStoreCompanys();

	await fetch(`${urlOldApi}/admin/positionType`)
		.then((response) => {
			if (response.ok) {
				response.json().then((data) => store.setDomains("positionsType", data));
			}
		})
		.catch((err) => console.error(err));
};

const loadClassification = async () => {
	const store = useStoreCompanys();

	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/classification`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	store.setDomains("classifications", data);
};
