import { ref } from 'vue';

import { HttpAdapterCustomer } from '@/helpers';

const store = {
	hasVoucher: ref(false),
};

export function useStoreVoucher() {
	const actions = {
		setHasVoucher: (value: boolean) => {
			store.hasVoucher.value = value;
		},
		verefyIfHasVoucher,
	};

	return { ...store, ...actions };
}

const verefyIfHasVoucher = async () => {
	const url = 'public/participant/hasVoucher';
	const { response } = await HttpAdapterCustomer.get({
		url,
		old_url: true,
		headers_config: {
			Authorization: 'old_token',
		},
	});
	const { setHasVoucher } = useStoreVoucher();
	setHasVoucher(response.ok);
};
