
import BaseInput from "../../../../../components/Atoms/BaseInput.vue";
import { useStoreEvents } from "../../store";
import { defineComponent, ref, watch, readonly } from "vue";

export default {
	data: () => ({
		tab: "event-register",
		organizers: useStoreEvents().organizers,
		storeEvents: useStoreEvents(),
		console: console,
	}),
	components: {
		BaseInput,
	},
};
