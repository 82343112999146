const PARTICIPANT_MUTATION = 'PARTICIPANT_MUTATION';
const SET_STATE_PARTICIPANT = 'SET_STATE_PARTICIPANT';
const TOGGLE_MUTATION = 'TOGGLE_MUTATION';
const SET_ASSETS = 'SET_ASSETS';
const SET_SPINNER = 'SET_SPINNER';
const SET_EVENT_INFO = 'SET_EVENT_INFO';
const SET_EVENT_STATE = 'SET_EVENT_STATE';
const SET_USER = 'SET_USER';

export { PARTICIPANT_MUTATION, TOGGLE_MUTATION, SET_STATE_PARTICIPANT, SET_ASSETS, SET_SPINNER, SET_EVENT_INFO, SET_EVENT_STATE, SET_USER };
