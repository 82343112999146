export enum StatusEnum {
	"accepted" = "accepted",
	"invited" = "invited",
	"not invited" = "not invited",
	"not present" = "not present",
	"present" = "present",
	"present at time" = "present at time",
}

export const StatusExhibition = {
	accepted: "Confirmada",
	invited: "Não Confirmada",
	"not invited": "Não confirmou",
	"not present": "Não  presente",
	present: "Presente no evento",
	"present at time": "Presente na hora",
};
