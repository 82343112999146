import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c152c59"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label-select" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["selected"]
const _hoisted_4 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-group-box", _ctx.sizeDefault ? 'input-group' : ''])
  }, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("select", {
      class: _normalizeClass(["select", [_ctx.sizeDefault ? 'select-default' : '']]),
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      disabled: _ctx.disabled,
      style: _normalizeStyle(_ctx.styleSelect)
    }, [
      _createElementVNode("option", {
        value: 0,
        disabled: "",
        selected: !_ctx.value
      }, "Selecione", 8, _hoisted_3),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", _mergeProps({
          value: option[_ctx.keys.keyValue],
          key: option[_ctx.keys.keyValue],
          selected: option[_ctx.keys.keyValue] === _ctx.value
        }, _ctx.$attrs), _toDisplayString(option[_ctx.keys.keyLabel]), 17, _hoisted_4))
      }), 128))
    ], 46, _hoisted_2)
  ], 2))
}