import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b292a552"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "column" }
const _hoisted_3 = {
  for: "option",
  class: "label"
}
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id", "value", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        key: (option.value as any)
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, _toDisplayString(option.text), 1),
          _createElementVNode("fieldset", {
            id: _ctx.value.id
          }, [
            _createElementVNode("input", {
              required: "",
              type: "radio",
              class: "input-radio",
              id: (option.value as any),
              value: option.value,
              name: _ctx.value.id,
              onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange($event, _ctx.value)))
            }, null, 40, _hoisted_5)
          ], 8, _hoisted_4)
        ])
      ]))
    }), 128))
  ]))
}