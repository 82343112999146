import { HttpAdapterCustomer } from "@/helpers/HttpAdapterCostumer";

export const getParticipants = async (): Promise<any | void> => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: "public/participant",
		old_url: true,
		headers_config: {
			Authorization: "old_token",
		},
	});
	if (!response.ok) return console.error(response.status)
	return data
}