import App from "./App.vue";
import DefaultLayout from "./Layout/DefaultLayout.vue";
import LayoutAdmin from "./Layout/LayoutAdmin/index.vue";
import LayoutFull from "./Layout/LayoutFull.vue";
import "./assets/global/import.css";
import { router } from "./router";
import { store } from "./store";
import { getCompany } from "@/views/Admin/PreRegistration/service";
import { createPinia } from "pinia";
import { createApp } from "vue";
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
import './index.css'

const options: PluginOptions = {
	timeout: 5000,
};

const app = createApp(App)
	.component("DefaultLayout", DefaultLayout)
	.component("LayoutFull", LayoutFull)
	.component("LayoutAdmin", LayoutAdmin);
const pinia = createPinia();

app.use(pinia);
app.use(Toast, options);
app.use(router);
app.use(store);
app.mount("#app");
