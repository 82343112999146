import { store } from '@/store';
import { SET_SPINNER } from '@/store/mutation-types';

export const stopSpinner = (time = 1000): void => {
	setTimeout(() => {
		store.commit(SET_SPINNER, false);
	}, time);
};

export const starSpinner = (): void => {
	store.commit(SET_SPINNER, true);
};
