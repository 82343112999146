export class SessionStorage {
	static set(key: string, value: any) {
		sessionStorage.setItem(key, JSON.stringify(value));
	}

	static get(key: string) {
		const data = sessionStorage.getItem(key);
		if (!data) return;
		return JSON.parse(data);
	}
}
