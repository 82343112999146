import { urlOldApi } from "./../../../helpers/HttpAdapterCostumer";
import { useStoreParticipantAdmin } from "./store";
import { HttpAdapter, HttpAdapterCustomer } from "@/helpers";
import axios from "axios";
import { useToast } from "vue-toastification";

export const importList = async (
	importData: any,
	callback: (result: any) => void
) => {
	const { data, response } = await HttpAdapterCustomer.post({
		url: `admin/participant/import`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
		body: JSON.stringify(importData),
	});
	callback(data);
	if (!response.ok) return;

	useToast().success("Participantes importados com sucesso");
};
export const deleteParticipant = async (id: string, callback: () => void) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/participant/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();

	if (response.status !== 200) {
		return useToast().error(data.message);
	} else {
		return useToast().success("Participante excluido com sucesso!");
	}
};
export const deleteParticipants = async (
	ids: string[],
	callback: () => void
) => {
	const { data, response } = await HttpAdapterCustomer.post({
		url: `admin/participant/deleteSelectedParticipants`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
		body: JSON.stringify(ids),
	});
	callback();

	if (response.status !== 200) {
		return useToast().error(data.message);
	} else {
		return useToast().success("Participantes excluidos com sucesso!");
	}
};
export const sendVips = async (
	ids: string[],
	vip: boolean,
	callback: () => void
) => {
	const { data, response } = await HttpAdapterCustomer.post({
		url: `admin/participant/vip/${vip}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
		body: JSON.stringify(ids),
	});
	callback();

	if (response.status !== 200) {
		return useToast().error(data.message);
	}
};

export const getCommon = async (
	setValue: (p: any) => void,
	search: string,
	status: any,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const hasStatus = status ? `&status=${status}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `?direction=${direction}&orderBy=${order}${hasSearch}${hasStatus}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/participant/${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	loadCompanys();
	setValue(data);
};

export const newGetParticipant = async (
	setValue: any,
	search: any,
	status: any,
	callback: () => void,
	page: any,
	size: any,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/v2/participant/?direction=${sort?.direction}&orderBy=${sort?.order}&like=${search}&status=${status}&page=${page}&size=${size}&type=common`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	callback();
	setValue(data);
};

export const updateParticipant = async (
	id: string | undefined,
	setValue: (p: any, type: any) => void,
	callback: () => void
): Promise<any | void> => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/v2/participant/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) {
		callback();
		return;
	}
	setValue(data, "edit");
	callback();
};

export const newGetStaff = async (
	setValue: any,
	search: any,
	callback: () => void,
	page: any,
	size: any,
	sort?: { order?: string; direction?: "asc" | "desc" }
): Promise<any | void> => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/v2/participant/?direction=${sort?.direction}&orderBy=${sort?.order}&like=${search}&status=${status}&page=${page}&size=${size}&type=staff`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	callback();
	setValue(data);
};

export const getStaff = async (
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/participant/staff/${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const newGetSpeaker = async (
	setValue: any,
	search: any,
	callback: () => void,
	page: any,
	size: any,
	sort?: { order?: string; direction?: "asc" | "desc" }
): Promise<any | void> => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/v2/participant/?direction=${sort?.direction}&orderBy=${sort?.order}&like=${search}&status=${status}&page=${page}&size=${size}&type=speaker`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	callback();
	setValue(data);
};

export const getSpeaker = async (
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/participant/speaker/${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const sendConfirm = async (
	ids: string[],
	callback: () => void,
	invalidParticipants: any
) => {
	const { data, response } = await HttpAdapterCustomer.post({
		url: "admin/participant/confirmAll",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
		body: JSON.stringify(ids),
	});
	if (data.invalidParticipants.length) {
		invalidParticipants(data.invalidParticipants);
	}
	callback();
	if (response.status !== 200) {
		return useToast().error("Erro ao confirmar participante(s)");
	}
	if (!data.invalidParticipant) {
		useToast().success(
			`${
				ids.length > 1 ? "Convite confirmado" : "Convites confirmados"
			} com sucesso`
		);
	}
};

export const sendInvites = async (ids: string[], callback: () => void) => {
	const { data, response } = await HttpAdapterCustomer.post({
		url: "admin/participant/invite",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
		body: JSON.stringify(ids),
	});
	callback();

	if (response.status !== 200) {
		if (data.message === "no-invitation-image") {
			return useToast().error(
				`Convites não enviados: Faça o upload do convite no evento`
			);
		} else {
			return useToast().error(data.message);
		}
	}
	useToast().success(
		`${ids.length > 1 ? "Convite enviado" : "Convites enviados"} com sucesso`
	);
};

export const typeStatus: any = {
	"not invited": "Convite não Enviado",
	invited: "Convite Enviado",
	accepted: "Convite Aceito",
	unauthorized: "Não Autorizado",
	present: "Presente",
	"present at time": "Presente na hora",
	"not present": "Ausente",
	confirmed: "Confirmado",
	"subscription at time": "Inscrição na Hora",
};
export const loadDomains = () => {
	const store = useStoreParticipantAdmin();

	loadCompanys();
	loadPositions();
	loadPositionsType();
	loadClassification();

	const type = Object.keys(typeStatus).map((el: any) => {
		return {
			id: el,
			title: typeStatus[el],
		};
	});
	store.setDomains("status", type);
};

const loadCompanys = async () => {
	const store = useStoreParticipantAdmin();

	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/company`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	store.setDomains("companys", data);
};

const loadPositions = async () => {
	const store = useStoreParticipantAdmin();

	await fetch(`${urlOldApi}/admin/position`)
		.then((response) => {
			if (response.ok) {
				response.json().then((data) => store.setDomains("positions", data));
			}
		})
		.catch((err) => console.error(err));
};

const loadPositionsType = async () => {
	const store = useStoreParticipantAdmin();

	await fetch(`${urlOldApi}/admin/positionType`)
		.then((response) => {
			if (response.ok) {
				response.json().then((data) => store.setDomains("positionsType", data));
			}
		})
		.catch((err) => console.error(err));
};

const loadClassification = async () => {
	const store = useStoreParticipantAdmin();

	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/classification`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	store.setDomains("classifications", data);
};
