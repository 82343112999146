import { IHandlerOptionsFetch, handlerOptionsFetch } from "./OptionsFetch";
import { getGeneralUrl, getGeneralUrlV2 } from "./abrafarmaApi";
import { validateCPF } from "@/helpers/ValidateCPF";
import { jwtDecode } from "./jwtDecode";
import { router } from "@/router";
import { TOKEN_TYPE, eventStore } from "@/store/event.store";
import { SessionStorage } from "@/store/sessionStorage";

export const urlNewApi = getGeneralUrlV2();
// process.env.NODE_ENV === "production"
// 	? "https://gestao.eventosabrafarma.com.br/api"
// 	: "https://abrafarma-api2-0-dev.tegra.com.br";

export const urlOldApi = getGeneralUrl();
// process.env.NODE_ENV === "production"
// 	? "https://eventosabrafarma.com.br/api"
// 	: "https://abrafarma-web-admin-dev.tegra.com.br/api";

export const url =
	"http://dev.abrafarma.frontend.s3-website-us-east-1.amazonaws.com";

interface IResponse<T = any> {
	response: Response;
	data: T;
}
let interval: any = null;
export class HttpAdapterCustomer {
	static async get<T = any>(
		optionsFetch: Omit<IHandlerOptionsFetch, "method">
	): Promise<IResponse<T>> {
		return await this.fetchResponse({ ...optionsFetch, method: "GET" });
	}

	static async getById<T = any>(
		optionsFetch: Omit<IHandlerOptionsFetch, "method">
	): Promise<IResponse<T>> {
		return await this.fetchResponse({ ...optionsFetch, method: "GET" });
	}

	static async post<T = any>(
		optionsFetch: Omit<IHandlerOptionsFetch, "method">
	): Promise<IResponse<T>> {
		return await this.fetchResponse({ ...optionsFetch, method: "POST" });
	}

	static async put<T = any>(
		optionsFetch: Omit<IHandlerOptionsFetch, "method">
	): Promise<IResponse<T>> {
		return await this.fetchResponse({ ...optionsFetch, method: "PUT" });
	}

	static async patch<T = any>(
		optionsFetch: Omit<IHandlerOptionsFetch, "method">
	): Promise<IResponse<T>> {
		return await this.fetchResponse({ ...optionsFetch, method: "PATCH" });
	}

	static async delete<T = any>(
		optionsFetch: Omit<IHandlerOptionsFetch, "method">
	): Promise<IResponse<T>> {
		return await this.fetchResponse({ ...optionsFetch, method: "DELETE" });
	}

	static async authOldApi(cpf: string) {
		const response = await HttpAdapterCustomer.post<{ token: string }>({
			url: "public/auth/login",
			body: JSON.stringify({ cpf, foreign: !validateCPF(cpf) }),
			old_url: true,
		});
		const { data } = response;
		eventStore.setStoreToken(data.token, TOKEN_TYPE["OLD_TOKEN"]);
		return response;
	}

	static async authOldApiAdmin(body: {
		email: string;
		password: string;
	}): Promise<any> {
		const { data, response } = await HttpAdapterCustomer.post<{
			token: string;
		}>({
			url: "admin/login",
			body: JSON.stringify(body),
			old_url: true,
		});
		if (data.token) {
			eventStore.setStoreToken(data.token, TOKEN_TYPE["ADMIN_TOKEN"]);
			const decodedToken = jwtDecode<any>(data.token);
			const { data: dataEvent, response } = await HttpAdapterCustomer.get({
				url: `admin/event/${decodedToken.eventId}`,
				old_url: true,
				headers_config: {
					Authorization: "admin_token",
				},
			});
			if (response.ok) SessionStorage.set("event", dataEvent);
		}
		return { data, response };
	}

	static async authOldApiProvider(body: {
		username: string;
		password: string;
	}): Promise<any> {
		const { data, response } = await HttpAdapterCustomer.post<{
			token: string;
		}>({
			url: "provider/auth/login",
			body: JSON.stringify(body),
			old_url: true,
		});
		eventStore.setStoreToken(data.token, TOKEN_TYPE["NEW_TOKEN"]);
		return response;
	}

	static async authNewApi(cpf: string) {
		const response = await HttpAdapterCustomer.post({
			url: "login/participant",
			body: JSON.stringify({ cpf }),
		});
		const { data } = response;
		eventStore.setStoreToken(data.token, TOKEN_TYPE["NEW_TOKEN"]);
		return response;
	}

	static refreshToken(cpf: string): void {
		clearInterval(interval);
		const fiveMinutes = 1000 * 60 * 5;
		interval = setInterval(() => {
			this.authOldApi(cpf);
		}, fiveMinutes);
	}

	static refreshTokenAdmin(body: { email: string; password: string }): void {
		clearInterval(interval);
		const fiveMinutes = 1000 * 60 * 5;
		interval = setInterval(() => {
			this.authOldApiAdmin(body);
		}, fiveMinutes);
	}

	static refreshTokenProvider(body: {
		username: string;
		password: string;
	}): void {
		clearInterval(interval);
		const fiveMinutes = 1000 * 60 * 5;
		interval = setInterval(() => {
			this.authOldApiProvider(body);
		}, fiveMinutes);
	}

	private static async fetchResponse(
		options: IHandlerOptionsFetch
	): Promise<IResponse<any>> {
		try {
			options.timeout = 3600000;
			const { method, url, urlApi, body, headers } =
				handlerOptionsFetch(options);

			const response = await fetch(`${urlApi}/${url}`, {
				method,
				body,
				headers,
			});

			if (
				response.status === 401 &&
				options.old_url &&
				options.headers_config?.Authorization === "admin_token"
			)
				logout();
			const dataJson = async () => {
				try {
					return await response.json();
				} catch {
					return Promise.resolve(response);
				}
			};

			return {
				response,
				data: await dataJson(),
			};
		} catch (error: any) {
			console.error(error);
			return { data: Promise.resolve(false), response: error };
		}
	}
}

const logout = () => {
	eventStore.clearStore();
	router.push("/");
	location.reload();
};
