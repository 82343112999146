<script
	setup
	lang="ts"
></script>

<template>
<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<path d="M488.726,139.637H23.273c-9.883,0-18.689,6.242-21.96,15.569c-3.271,9.326-0.295,19.701,7.422,25.876
	l232.733,186.181c4.25,3.399,9.394,5.1,14.538,5.1c5.143,0,10.29-1.7,14.538-5.1l232.72-186.181
	c7.719-6.173,10.695-16.55,7.422-25.876C507.415,145.878,498.609,139.637,488.726,139.637z"/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg></template>