<template>
	<div style="display: flex; flex-direction: column; margin-top: 1rem">
		<div class="container-table">
			<table>
				<thead>
					<slot name="thead" />
				</thead>
				<tbody>
					<slot name="tbody" />
				</tbody>
			</table>
		</div>
		<div></div>
	</div>
</template>
<script
	setup
	lang="ts"
></script>
<style>
	.container-table {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		overflow: auto;
	}

	table {
		border-collapse: collapse;
		min-width: 400px;
	}

	td,
	th {
		padding: 1rem 1rem;
		font-size: 0.875rem /* 14px */;
		line-height: 1.25rem /* 20px */;
		color: #3d3e41;
	}

	th {
		font-weight: 600;
		font-weight: 600;
		text-align: start;
			color: #5d5d60;
	}

	td div {
		display: flex;
		max-width: 230px;
		flex-direction: column;
		justify-content: start;
	}

	td span {
		text-align: initial;
	}

	td span:first-child {
		font-weight: 600;
	}

	th {
		border-bottom: 2px solid #d5d5d5;
	}

	tr,
	td {
		border-bottom: 1px solid #d5d5d5;
	}
</style>
