
	import Vue, { defineComponent } from 'vue';
  import Multiselect from '@vueform/multiselect'

	export default defineComponent({
		name: 'BaseMultipleSelect',
    components:{
      Multiselect
    },data(){
      return {
       
      }
    },
		props: {
			options: {
				type: Array as Vue.PropType<any[]>,
				required: true,
			},
			label: {
				type: String,
				required: false,
			},
			disabled: {
				type: Boolean,
				required: false,
			},
			value: {
				type: Array,
				required: true,
				default: [],
			},
			styleSelect: {
				type: String,
				required: false,
				default: '',
			},
			sizeDefault: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
			onChange(event: any) {
				this.$emit('change', event);
			},
		},
	});
