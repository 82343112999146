let interval: number;

export const debounce = (callBack: any, time = 1000) => {
	return ((...args: any[]) => {
		clearInterval(interval);
		interval = setTimeout(() => {
			callBack(...args);
		}, time);
	})();
};
