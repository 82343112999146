import { urlNewApi } from './HttpAdapterCostumer';

const urlApi = urlNewApi;

export class HttpAdapter {
	static getUrl(): string {
		return urlApi;
	}
	static async get(url: string, urlDefault = true) {
		try {
			const urlFetch = urlDefault ? `${urlApi}/${url}` : url;
			const response = await fetch(urlFetch, {
				method: 'GET',
			});
			return await response.json();
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async post(url: string, body: any): Promise<{ dataJson: any; dataRaw: Response; ok: boolean }> {
		try {
			const response = await fetch(`${urlApi}/${url}`, {
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return {
				dataRaw: response,
				ok: response.ok,
				dataJson: await response.json(),
			};
		} catch (error) {
			console.error(error);
			return { ok: false, dataRaw: error as any, dataJson: undefined };
		}
	}

	static async put(url: string, body: any): Promise<{ dataJson: any; dataRaw: any; ok: boolean }> {
		try {
			const response = await fetch(`${urlApi}/${url}`, {
				method: 'PUT',
				body: body,
				headers: {
					// 'Content-Type': 'multipart/form-data',
					'Cache-Control': 'no-cache',
				},
			});
			return {
				dataJson: await response.json(),
				dataRaw: response,
				ok: response.ok,
			};
		} catch (error) {
			console.error(error);
			return { ok: false, dataRaw: error, dataJson: undefined };
		}
	}
}
