import jwtDecode from 'jwt-decode';
import { ref } from 'vue';

import { SET_USER } from './mutation-types';
import { store as storeGlobal } from '@/store/index';

export enum TOKEN_TYPE {
	NEW_TOKEN = 'token',
	OLD_TOKEN = 'oldToken',
	ADMIN_TOKEN = 'adminToken',
}

const store = {
	token: ref(''),
	oldToken: ref(''),
	adminToken: ref(''),
};

const getters = {
	getStoreToken(type: TOKEN_TYPE): string {
		if (!store[type].value) return getTokenSessionStorage(type);
		return store[type].value;
	},
};

const actions = {
	setStoreToken(token: string, type: TOKEN_TYPE): void {
		store[type].value = token;
		setTokenSessionStorage(token, type);
		setUserData(token);
	},
	clearStore() {
		sessionStorage.clear();
	},
};

function setUserData(token: string): void {
	const user: any = jwtDecode(token);
	storeGlobal.commit(SET_USER, user);
}

export function getTokenSessionStorage(type: TOKEN_TYPE): string {
	const token = sessionStorage.getItem(type);
	if (!token) return '';
	return token.replace('Bearer ', '');
}

function setTokenSessionStorage(token: string, type: TOKEN_TYPE): void {
	sessionStorage.setItem(type, 'Bearer ' + token);
}

export const eventStore = { ...store, ...getters, ...actions };
