import { createStore } from 'vuex';

import * as packageJson from '../../package.json';
import { PARTICIPANT_MUTATION, SET_EVENT_STATE, SET_SPINNER, SET_STATE_PARTICIPANT, SET_USER, TOGGLE_MUTATION } from './mutation-types';
import { IParticipantState } from '@/views/Participant/dto/IParticipantState';

interface EventStore {
	userId: string;
	eventId: string;
	eventTerm: string;
	banner: string;
	footer: string;
}

const eventStorage = getEventConfigSessionStorage();

const store = createStore({
	state() {
		return {
			spinner: true,
			version: `v${packageJson.version}`,
			toogle: false,
			event: {
				eventId: eventStorage?.eventId,
				userId: eventStorage?.userId,
				banner: eventStorage?.banner,
				footer: eventStorage?.footer,
				eventTerm: eventStorage?.eventTerm,
			},
			user: {
				name: '',
			},
			// remover isso depois e colocar numa store ou mixin de participante
			participant: {
				gene: '',
				cpf: '',
				name: '',
				credential: '',
				birthday: '',
				emailBusinesses: '',
				emailPersonal: '',
				image: 'not-found',
				file: undefined,
				typePosition: '',
				descriptionPosition: '',
				register: '',
				nameCompany: '',
				phone: '',
				residencePhone: '',
				enterprisePhone: '',
				company: '',
				guestCompany: '',
				address: '',
			} as IParticipantState,
		};
	},
	mutations: {
		[PARTICIPANT_MUTATION](state: any, payload: any): void {
			state.participant[payload.property] = payload.value;
		},
		[TOGGLE_MUTATION](state: any, payload = 'open'): void {
			state.toogle = payload === 'open';
		},
		[SET_STATE_PARTICIPANT](state: any, payload: IParticipantState): void {
			state.participant = payload;
		},
		[SET_SPINNER](state: any, payload: boolean): void {
			state.spinner = payload;
		},
		[SET_EVENT_STATE](state: any, payload: EventStore) {
			state.event.banner = payload.banner;
			state.footer = payload.footer;
			state.event.Term = payload.eventTerm;
			state.event.eventId = payload.eventId;
			state.event.userId = payload.userId;
			setEventConfigSessionStorage(payload);
		},
		[SET_USER](state: any, payload: any) {
			state.user = payload;
		},
	},
});

function setEventConfigSessionStorage(payload: EventStore): void {
	sessionStorage.setItem('event', JSON.stringify(payload));
}

function getEventConfigSessionStorage(): EventStore | undefined {
	const event = sessionStorage.getItem('event');
	if (!event) return;
	return JSON.parse(event);
}

export { store };
