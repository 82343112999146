import { ref } from 'vue';

const store = {
	modalChangeEvent: ref(false),
};

export function useStoreLayoutAdmin() {
	const actions = {
		setModalChangeEvent: (value: boolean) => {
			store.modalChangeEvent.value = value;
		},
	};

	return { ...store, ...actions };
}
