import { StatusEnum, StatusExhibition } from "./constants";
import {
	Activity,
	IScheduleActivitiesResponse,
	IScheduleActivitiesState,
} from "./dto";
import { mapperScheduleActivities } from "./mapper/mapperScheduleActivities";
import { HttpAdapterCustomer } from "@/helpers";
import Vue, { ref } from "vue";

export const storeSchedule = {
	status: ref(""),
	activityError: ref<string | undefined>(""),
	isEventOnline: ref(false),
	descriptionEventOnline: ref(""),
	tabs: ref<string[]>([]),
	tabSelected: ref("Corporativa"),
	scheduleEvents: ref<IScheduleActivitiesState[]>([]),
	scheduleActivities: ref<Activity[]>([]),
	daysOfActivities: ref<string[]>([]),
	dateSelected: ref(0),
};

const actions = {
	async getScheduleActivities(eventId: string, userId: string) {
		const queryString = `?eventId=${eventId}&participantId=${userId}`;
		const urlAPi = `schedule-activity${queryString}`;
		const { data } = await HttpAdapterCustomer.get<IScheduleActivitiesResponse>(
			{ url: urlAPi }
		);

		storeSchedule.daysOfActivities.value = data?.data.map((schedule) => schedule.date);
		storeSchedule.activityError.value = data?.message || undefined;
		storeSchedule.descriptionEventOnline.value = data.description;
		storeSchedule.status.value = data?.status;
		storeSchedule.isEventOnline.value = data.online;
		const scheduleActivitiesMapper = mapperScheduleActivities(data);
		storeSchedule.scheduleEvents.value = scheduleActivitiesMapper;
		const activitiesTypeLabel = scheduleActivitiesMapper?.map(
			getActivitiesTypeLabel
		);

		storeSchedule.scheduleActivities.value = actions.getActivitiesForDayAndTypeEvent();		
		storeSchedule.tabs.value = sanitizedAndOrderTabs(activitiesTypeLabel);


		return {
			daysOfActivities: storeSchedule.daysOfActivities.value,
			dateSelected: storeSchedule.dateSelected.value,
		}
	},
	getActivitiesForDayAndTypeEvent() {		
		if (!storeSchedule.scheduleEvents.value?.length) return [];
		// storeSchedule.daysOfActivities.value = actions.getDateForActivitiesType();
		actions.getDateForActivitiesType();
		const event = storeSchedule.scheduleEvents;
		const eventActivitiesFilterForType = event.value?.map(isEventEqualTabSelected);
		const eventActivitiesForDay = eventActivitiesFilterForType.find(
			isEventDateEqualDateSelected
		);
		if (!eventActivitiesForDay?.activities) return [];
		return eventActivitiesForDay?.activities;
	},
	async getDateForActivitiesType() {
		if (!storeSchedule.scheduleEvents.value?.length) return [];
		const schedule = storeSchedule.scheduleEvents;
		const activitiesForType = schedule.value?.map(isEventEqualTabSelected);
		const activitiesFilterForType = activitiesForType.filter((activities) =>
			Boolean(activities.activities.length)
		);		
		
		return activitiesFilterForType.map((activities) => activities.date);
	},
	getStatusMessage() {
		const key = storeSchedule.status.value as StatusEnum;
		return StatusExhibition[key];
	},
};

function sanitizedAndOrderTabs(activitiesTypeLabel: string[][]) {
	if (!activitiesTypeLabel) return [];
	const tabs = [...new Set([...activitiesTypeLabel?.flat()])];
	const yes = 1;
	const no = -1;
	return tabs?.sort((tab) => (tab === "Corporativa" ? no : yes));
}

function getActivitiesTypeLabel(schedule: IScheduleActivitiesState) {
	return schedule.activities.map(
		(activity) => activity.activityDetail.typeLabel
	);
}

function isEventDateEqualDateSelected(item: IScheduleActivitiesState) {
	const date =
	storeSchedule.daysOfActivities.value[storeSchedule.dateSelected.value];
	return item.date === date;
}

function isEventEqualTabSelected(
	schedule: IScheduleActivitiesState
): IScheduleActivitiesState {
	return {
		date: schedule.date,
		activities: schedule.activities.filter(isEventTypeEqualTabSelected),
	};
}

function isEventTypeEqualTabSelected(activity: Activity): boolean {
	return activity.activityDetail.typeLabel === storeSchedule.tabSelected.value;
}

export const scheduleStore = { ...storeSchedule, ...actions };
