<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fit=""
		height="100%"
		width="100%"
		preserveAspectRatio="xMidYMid meet"
		viewBox="0 0 24 24"
		focusable="false"
		fill="currentColor"
	>
		<g id="plus-box">
			<path
				d="M17,13H13V17H11V13H7V11H11V7H13V11H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
			></path>
		</g>
	</svg>
</template>
