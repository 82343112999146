<template>
	<div class="c-schedule_event">
		<slot />
	</div>
</template>
<style>
	.c-schedule_event {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.c-schedule_table {
		display: flex;
		flex-wrap: wrap;
		padding: 25px 0;
		border-top: 1px solid var(--color-gray);
		border-bottom: 1px solid var(--color-gray);
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}
	.c-schedule_table_hour {
		width: 50%;
	}

	/* Desktop */
	@media (min-width: 992px) {
		.c-schedule_table {
			flex-direction: row;
		}

		.c-schedule_table_hour {
			width: 20%;
		}
		.c-schedule_table_cards {
			width: 45%;
		}

		.c-table_column {
			width: 20%;
		}
		.order_card {
			order: 2;
		}
		.order_logo {
			order: 3;
		}
	}
</style>
