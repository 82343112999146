import {
	getGestores,
	searchCpfExecutive,
	getExecutives,
	getSponsors,
	loadSponsors,
	deleteCompanyApi,
} from "./service";
import { existThisFile, HttpAdapterCustomer } from "@/helpers";
import { ICompany, IExecutive } from "@/interface/company";
import { defineStore } from "pinia";
import Toast, { useToast } from "vue-toastification";

export const type = {
	"not informed": "Não informado",
	"commercial partner": "Parceiro comercial",
	associated: "Associado",
	government: "Governamental",
	entity: "Entidade",
	midia: "Mídia",
	invited: "Convidado",
	organizer: "Organização",
	stand_builder: "Montadora Oficial",
	"ticket sale": "Inscrição",
};

const initialData: ICompany = {
	id: "",
	name: "",
	domains: "",
	date_of_association: "",
	social_name: "",
	cnpj: "",
	status: "",
	address: "",
	type: "not informed",
	email: "",
	sponsorship_type_id: "",
};

interface IGestor {
	name: String;
	email: String;
	role: String;
	password: String;
	id?: number;
}
const initialDataGestor: IGestor = {
	name: "",
	email: "",
	role: "company_manager",
	password: "",
};

const initialDataExecutive: IExecutive = {
	status: "not invited",
	gender: "not informed",
	type: "",
	cpf: "",
	guest_company: "",
	name: "",
	credential_name: "",
	classification_id: "",
	position_type_id: "",
	position: "",
	company_id: "",
	allows_meeting: true,
	enterprise_mail: "",
	personal_mail: "",
	enterprise_phone: "",
	home_phone: "",
	personal_phone: "",
	birth_date: null,
	register: "",
	address: "",
	credential_color: "#000000",
	observation: "",
	vip: false,
};
export const useStoreCompanys = defineStore("Companys", {
	state: () => ({
		Companys: [] as ICompany[],
		tab: "List" as
			| "List"
			| "Form"
			| "FormEdit"
			| "ExistManager"
			| "FormExecutive",
		tabEdit: "gestor" as "gestor" | "executive",
		tabAdd: false,
		companyToUpdate: initialData,
		executiveToUpdate: initialDataExecutive,
		search: "",
		sponsors: [],
		spinner: false,
		gestores: [],
		executives: [],
		usersManagers: [],
		confirm: "" as string,
		gestorToUpdate: initialDataGestor,
		hasSearchCpf: false,
		domains: {
			companys: [],
			positions: [],
			positionsType: [],
			classifications: [],
			status: [],
		},
		company_id: "",
	}),
	actions: {
		setDomains(
			type:
				| "companys"
				| "positions"
				| "positionsType"
				| "classifications"
				| "status",
			domain: any
		) {
			this.domains[type] = domain;
		},
		setTabAdd(tab: any) {
			this.tabAdd = tab;
		},
		setTabConfirm(tab: any) {
			this.confirm = tab;
		},
		setExistGestor() {
			this.tab = "ExistManager";
		},
		setGestores(gestores: any) {
			this.gestores = gestores;
			this.gestores = gestores.map((el: any) => {
				el.cpfFormat = el.cpf
					?.replace(/\D/g, "")
					?.replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4");
				return el;
			});
		},
		setSponsors(sponsors: any) {
			this.sponsors = sponsors;
		},
		setCompanys(Companys: ICompany[]) {
			this.Companys = Companys.map((el) => {
				el.typeName = el.type && type[el.type];
				el.statusName = el.status === "active" ? "Ativa" : "inativa";
				return el;
			});
		},
		setCompanyId(companyId: string) {
			this.company_id = companyId;
		},
		setUsersManagers(Companys: any) {
			this.usersManagers = Companys.map((el: any) => {
				el.roleName =
					el.role && ROLES.filter((role) => role.id === el.role)[0].role;
				return el;
			});
		},
		setExecutives(Companys: any) {
			this.executives = Companys.map((el: any) => {
				el.roleName =
					el.role && ROLES.filter((role) => role.id === el.role)[0].role;
				el.cpfFormat = el.cpf
					?.replace(/\D/g, "")
					?.replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4");
				return el;
			});
		},
		addCompany(Company: ICompany) {
			this.Companys.push(Company);
		},
		updateCompany(Company: ICompany) {
			const index = this.Companys.findIndex((u: any) => u.id === Company.id);
			this.Companys[index] = Company;
		},
		setCompanyToUpdate(Company?: ICompany) {
			this.companyToUpdate = Company ? Company : initialData;
		},
		setGestorToUpdate(Company: any, type: any) {
			this.gestorToUpdate = Company ? Company : initialDataGestor;
			if (type === "edit") this.setTab("Form");
		},
		setExecutiveToUpdate(executive: any, type: any) {
			if (!this.executiveToUpdate) executive as any;
			this.executiveToUpdate = {
				...initialDataExecutive,
				...executive,
			};
		},
		setValueInCompanyToUpdate(key: string, value: any) {
			if (!this.companyToUpdate) this.companyToUpdate = {} as any;
			(this.companyToUpdate as any)[key] = value;
		},
		setValueInGestorToUpdate(key: string, value: any) {
			if (!this.gestorToUpdate) this.gestorToUpdate = {} as any;
			(this.gestorToUpdate as any)[key] = value;
		},
		setValueInExecutiveToUpdate(key: string, value: any) {
			if (!this.executiveToUpdate)
				this.executiveToUpdate = initialDataExecutive as IExecutive;
			(this.executiveToUpdate as any)[key] = value;
		},
		setTab(tab: "List" | "Form" | "FormEdit" | "FormExecutive") {
			if (tab === "List") {
				this.setGestorToUpdate(initialData, "close");
			}
			if (tab === "Form" || tab === "FormEdit") {
				loadSponsors(this.setSponsors, () => {
					this.setSpinner(false);
				});
			}
			this.tab = tab;
		},
		setTabEdit(tab: any) {
			this.tabEdit = tab;
		},
		setSearch(search: string) {
			this.search = search;
		},
		setSpinner(spinner: boolean) {
			this.spinner = spinner;
		},
		setHasSearch(val: boolean) {
			this.hasSearchCpf = val;
		},
		deleteCompany(id: any) {
			deleteCompanyApi(id);
		},
		deleteGestor(id: any, companyId: any) {
			deleteGestorApi(id, companyId);
		},
		deleteExecutive(id: any, companyId: any) {
			deleteExecutiveApi(id, companyId);
		},
		async searchCpf(cpf: any) {
			this.setSpinner(true);
			await searchCpfExecutive(cpf, this.setExecutiveToUpdate, () => {
				this.setHasSearch(true);
			});
			this.setSpinner(false);
		},
	},
});

export const ROLES = [
	{ id: "admin", role: "Administrador" },
	{ id: "Company", role: "Usuário" },
	{ id: "company_manager", role: "Gestor" },
	{ id: "organizer", role: "Organizador" },
	{ id: "stand_builder", role: "Montadora Oficial" },
	{ id: "coordinator", role: "Coordenador" },
	{ id: "sender", role: "Remetente" },
];

export const deleteExecutiveApi = async (id: number, companyId: string) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/participant/remove-company/${id}/${companyId}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	const toast = useToast();
	const storeCompanys = useStoreCompanys();
	if (!response.ok)
		return toast.error(data.message || "Erro ao excluir executivo");
	toast.success("Executivo Excluido com sucesso");
	getExecutives(
		companyId,
		storeCompanys.setExecutives,
		storeCompanys.search,
		() => {
			storeCompanys.setSpinner(false);
		}
	);
};

export const deleteGestorApi = async (id: any, eventId: any) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/user/remove-company/${id}/${eventId}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	const toast = useToast();

	const storeCompanys = useStoreCompanys();
	if (!response.ok)
		return toast.error(data.message || "Erro ao excluir gestor");
	toast.success("Gestor excluido com sucesso");
	getGestores(eventId, storeCompanys.setGestores, storeCompanys.search, () => {
		storeCompanys.setSpinner(false);
	});
};
export const getCompany = async (
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/Company${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};
