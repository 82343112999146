
	import { defineComponent, ref, watch } from 'vue';

	import { ROLES, useStoreCompanys } from '../../store';
	import {getGestores, getUsersManagers} from '../../service'
	import BaseButton from '../../../../../components/Atoms/BaseButton.vue';
	import BaseInput from '../../../../../components/Atoms/BaseInput.vue';
	import Spinner from '../../../../../components/Atoms/Spinner.vue';
	import IcSearch from '../../../../../components/icons/IcSearch.vue';
	import IcTrash from '../../../../../components/icons/IcTrash.vue';
	import IcEdit from '../../../../../components/icons/IcEdit.vue';

	import IcVerticalDots from '../../../../../components/icons/IcVerticalDots.vue';
	import { debounce } from '../../../../../helpers/debounce';
	import Table from '../../../../../components/Organism/Table/table.vue'
import { useRouter } from 'vue-router';
import { HttpAdapterCustomer } from '../../../../../helpers';
import { useToast } from 'vue-toastification';


 export default defineComponent({
		name: 'MenuTable',
		components: {
			BaseInput,
			IcTrash,
      IcSearch,
      IcEdit,
      BaseButton,
			Table,
			Spinner
		},
		setup(props) {
	const storeCompanys = useStoreCompanys();
  const toast = useToast()
	const miniSpinner = ref(false);

	const search = (value: string) => {
		storeCompanys.setSearch(value);
		miniSpinner.value = true;
		const TRHEE_SECONDS = 1 * 1000;
		debounce(() => {
			storeCompanys.setSpinner(true);
			miniSpinner.value = false;
			
			getUsersManagers(storeCompanys.setUsersManagers, storeCompanys.search, () => {
				storeCompanys.setSpinner(false);
			});
		}, TRHEE_SECONDS);
	};

  const selectUserManager = async (gestor:any) => {
      
      const storeCompanys = useStoreCompanys()

      gestor.company_id = storeCompanys.companyToUpdate.id
      const { data, response } = await HttpAdapterCustomer.post({
        url: "admin/user",
        old_url: true,
        headers_config: {
          Authorization: "admin_token",
        },
        body: JSON.stringify(gestor),
      });
      if (!response.ok)
        return toast.error(data.message || "Erro ao cadastrar gestor");
        toast.success("Gestor Atualizado com sucesso");
        storeCompanys.setTab("List");
        getGestores(storeCompanys.companyToUpdate.id,storeCompanys.setGestores, storeCompanys.search, () => {
          storeCompanys.setSpinner(false);
  });
  }

	const router = useRouter()
	const tableActions = [{
		title:'Selecionar',
		icon: IcEdit,
		onClick: ((company:any) => selectUserManager(company))
	}]

	const columns = [{
		title:'Nome',
		value:'name',
		id:1,
		sort: true,
		width:'25%'
	},{
		title:'Papel',
		value:'roleName',
		id:3,
		sort: false,
		width:'25%'
	},{
		title:'Email',
		value:'email',
		id:3,
		sort: true,
		width:'25%'
  }]

 return {
     columns,
		 tableActions,
		 storeCompanys,
		 miniSpinner,
		 Table,
		 Spinner,
		 search,
     BaseButton
    }
 } 
 })
