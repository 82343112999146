export const removeMask = (text: string, maskChars: string): string => {
	return text.replace(new RegExp(`[${maskChars}]`, "g"), "");
};

export function maskCpf(cpf: string) {
	if (!cpf) return "";
	return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
}

export function removeMaskCpf(cpf: string) {
	if (!cpf) return "";
	return cpf.replace(/[^\d]+/g, "");
}

export function maskPhone(cpf: string) {
	if (!cpf) return "";
	return cpf.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3");
}

export function removeMaskPhone(cpf: string) {
	if (!cpf) return "";
	return cpf.replace(/[^\d]+/g, "");
}
