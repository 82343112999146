<template>
	<h2 class="c-schedule_title">
		<slot></slot>
	</h2>
</template>
<style scoped>
.c-schedule_title {
	width: 100%;
	font-size: 16px;
	padding-top: 10px;
	padding-bottom: 10px;
	color: #fff;
	background-color: var(--color-admin-primary);
}
</style>
