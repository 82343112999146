<!-- eslint-disable max-len -->
<template>
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			id="mask0_27_27508"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="5"
			y="3"
			width="14"
			height="18"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M14 16.2963H10C9.45 16.2963 9 15.8463 9 15.2963V10.2963H7.41C6.52 10.2963 6.08 9.21634 6.71 8.58634L11.3 3.99634C11.4868 3.80909 11.7405 3.70386 12.005 3.70386C12.2695 3.70386 12.5232 3.80909 12.71 3.99634L17.3 8.58634C17.93 9.21634 17.48 10.2963 16.59 10.2963H15V15.2963C15 15.8463 14.55 16.2963 14 16.2963ZM18 18.2963H6C5.45 18.2963 5 18.7463 5 19.2963C5 19.8463 5.45 20.2963 6 20.2963H18C18.55 20.2963 19 19.8463 19 19.2963C19 18.7463 18.55 18.2963 18 18.2963Z"
				fill="black"
			/>
		</mask>
		<g mask="url(#mask0_27_27508)">
			<rect width="24" height="24" fill="currentColor" />
		</g>
	</svg>
</template>

<script setup lang="ts"></script>
