import { reactive, ref } from 'vue';
import { useToast } from 'vue-toastification';

import { IActivity, IRawActivity } from './interface';
import { HttpAdapterCustomer } from '@/helpers/HttpAdapterCostumer';

const toast = useToast();
const store = {
	activityToUpdateData: ref({} as IActivity),
	activities: ref<IActivity[]>([]),
	activitiesToShow: ref<IActivity[]>([]),
	search: ref(''),
	modalOptions: ref(false),
	modalSendDigitalTicket: ref(false),
	dateSearch: ref(''),
	dateToSearch: reactive([
		{
			value: '',
			id: '',
		},
	]),
	event: ref({
		id: '',
		name: '',
		marketing_activity_name: '',
		name_activity_premium: '',
	}),
	companies: ref<{ id: string; name: string }[]>([]),
	};
let timeout: any;

export function useStoreScheduleAdmin() {
	const actions = {
		setActivityToUpdateData: (data: IActivity) => {
			store.activityToUpdateData.value = data;
		},
		setSearch: (value: string) => {
			store.search.value = value;
			myDebounce(value);
		},
		setModalOptions: (value: boolean) => {
			store.modalOptions.value = value;
		},
		setModalSendDigitalTicket: (value: boolean) => {
			store.modalSendDigitalTicket.value = value;
		},
		setDateSearch: (value: string) => {
			store.dateSearch.value = value;
		},
		setDateToSearch: (value: any) => {
			store.dateToSearch.length = 0;
			store.dateToSearch.push(...value);
		},
		setEvent: (value: any, key?: any) => {
			if (key) return ((store.event as any)[key] = value);
			store.event.value = value;
		},
		setActivities: (value: any) => {
			store.activities.value.length = 0;
			store.activities.value.push(...value);
		},

		getCompany: async () => {
			const { response, data } = await HttpAdapterCustomer.get({
				url: 'admin/company',
				old_url: true,
				headers_config: {
					Authorization: 'admin_token',
				},
			});
			if (!response.ok) return toast.error(data.message || 'Erro ao buscar as empresas');
			store.companies.value = data;
		},
	};


	const getActivities = async (like = '') => {
		const url = (() => {
			const base = '?type=marketing_activity';
			const search = like ? `&like=${like}` : '';
			return `admin/activity${base}${search}`;
		})();
		const { response, data } = await HttpAdapterCustomer.get<IRawActivity[]>({
			url,
			old_url: true,
			headers_config: {
				Authorization: 'admin_token',
			},
		});
		if (!response.ok) return;
		const dataMapped = data.map((item): IActivity => {
			return {
				id: item.id,
				title: item.title,
				description: item.description,
				start_time: item.start_time,
				end_time: item.end_time,
				place: item.place,
				date: new Date(item.date).toISOString().split('T')[0],
				attendance_list: item.attendance_list,
				has_moderator: item.has_moderator,
				is_colored: item.is_colored,
				is_interval: item.is_interval,
				type: item.type,
				logo: item.logo,
				company_id: item.company_id,
				restricted_list: item.restricted_list,
				speakers: item.speakers,
			};
		});
		const { setActivities } = useStoreScheduleAdmin();
		setActivities(dataMapped);
		activitiesByDate();
	};

	return { ...store, ...actions, getActivities, activitiesByDate };
}

const activitiesByDate = () => {
	const result = store.activities.value.filter((item) => item.date === store.dateSearch.value);
	store.activitiesToShow.value.length = 0;
	store.activitiesToShow.value = result;
	return result;
};

const executeFilter = async (valueToFilter: string) => {
	await useStoreScheduleAdmin().getActivities(valueToFilter);
};

const myDebounce = (valueToFilter: string) => {
	clearTimeout(timeout);
	timeout = setTimeout(async () => await executeFilter(valueToFilter), 1000);
};
