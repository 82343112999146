import { HttpAdapter } from "@/helpers/HttpAdapter";
import { HttpAdapterCustomer } from "@/helpers/HttpAdapterCostumer";
import { router } from "@/router";
import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();

const ERRORS: any = {
	"Event id is missing": "Evento não disponível",
	"Financial manager is missing": "Responsável financeiro não informado",
	"Participant batch is missing": "Lote de compra vazio",
	"Error registring ticket batch": "Erro ao registrar o pedido de compra",
	"Financial manager email domain is not valid":
		"E-mail do responsável pelo pagamento inválido!",
};

export async function getEventPublic(setValue: any, eventId: string) {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `public/event/${eventId}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	return (setValue = data);
}

export async function createManyPreRegistration(
	data: any,
	callback: () => void
) {
	try {
		const res = await axios.post(
			`${HttpAdapter.getUrl()}/ticket-batch/create`,
			data
		);
		if (res.status === 200) {
			router.push("/sucesso-participante");
			return;
		}
		callback();
	} catch (error: any) {
		toast.error(error.response.data);
	}
}

export const updatePreRegistrationIndividual = async (
	preRegistrationId: string,
	data: any,
	callback: () => void
) => {
	try {
		const update = await axios.put(
			`${HttpAdapter.getUrl()}/pre-registration/${preRegistrationId}`,
			data
		);
		toast.success("Inscrição atualizada com sucesso!");
		update.data;
		callback();
		return;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao atualizar os dados");
	}
};

export async function verifyDiscount(
	data: {
		event_id: string;
		company_id: string;
		registrations_count: number;
	},
	callback: () => void
) {
	try {
		const res = await axios.post(
			`${HttpAdapter.getUrl()}/ticket-discount/verify-discount`,
			data
		);
		callback();
		return res;
	} catch (error: any) {
		toast.error(ERRORS[error.response.data]);
	}
}

export const getPreRegistrationById = async (
	preRegistrationId: any,
	callback: () => void
): Promise<any | void> => {
	const list = await axios.get(
		`${HttpAdapter.getUrl()}/pre-registration/${preRegistrationId}`
	);
	if (list.status === 500) return;
	callback();
	return list.data;
};
