import { ref } from 'vue';

const store = {
	step: ref<'signIn' | 'forgotPassword'>('signIn'),
};

export function useStoreAuth() {
	const actions = {
		setStep: (value: 'signIn' | 'forgotPassword') => {
			store.step.value = value;
		},
	};

	return { ...store, ...actions };
}
