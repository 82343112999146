
import BaseButton from "../../../components/Atoms/BaseButton.vue";
import BaseInput from "../../../components/Atoms/BaseInput.vue";
import IcEdit from "../../../components/icons/IcEdit.vue";
import IcSearch from "../../../components/icons/IcSearch.vue";
import IcTrash from "../../../components/icons/IcTrash.vue";
import IcVerticalDots from "../../../components/icons/IcVerticalDots.vue";
import { debounce } from "../../../helpers/debounce";
import { IUsers } from "../../../views/Admin/Users/interface";
import Vue, { defineComponent, onMounted, reactive, ref, watch } from "vue";

export default defineComponent({
	name: "MenuTable",
	props: {
		handleActions: {
			type: Array as Vue.PropType<any[]>,
			required: true,
		},
		user: {
			type: Object as Vue.PropType<IUsers>,
			required: false,
		},
	},
	components: {
		BaseInput,
		IcTrash,
		IcSearch,
		IcEdit,
		BaseButton,
	},
	setup(props) {
		const modalOptions = ref("");

		const setOptionDot = (id: string) => {
			if (id === undefined) {
				return (modalOptions.value = "true");
			}
			if (id === modalOptions.value) {
				return (modalOptions.value = "");
			} else {
				return (modalOptions.value = id);
			}
		};
		const user = props.user;
		const handleActions = props.handleActions;
		const positionModal = ref<any>({ top: 0, left: 0 });
		const getPosition = (event: any) => {
			const rect = document
				.getElementById(`button-dots${event}`)
				?.getBoundingClientRect();
			positionModal.value = { top: rect?.top, left: rect?.left };
		};

		return {
			IcSearch,
			IcTrash,
			IcEdit,
			BaseButton,
			modalOptions,
			setOptionDot,
			IcVerticalDots,
			user,
			handleActions,
			console,
			getPosition,
			positionModal,
		};
	},
});
