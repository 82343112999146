import { HttpAdapterCustomer } from "@/helpers/HttpAdapterCostumer";
import { IClassification } from "@/interface/classification";
import { ICompanyType } from "@/interface/companyType";
import { IPositionType } from "@/interface/positionType";
import { ISponsorshipType } from "@/interface/sponsorshipType";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

const initialData: any = {
	company_types: [],
	name: "",
	position_types: [],
	sponsorship_types: [],
};

export const useClassificationStore = defineStore("useClassificationStore", {
	state: () => ({
		classification: [] as IClassification[],
		tab: "List" as "List" | "Form",
		tabForm: "position-type" as
			| "position-type"
			| "company-type"
			| "sponsorship-type",
		classificationToUpdate: initialData,
		search: "",
		spinner: false,
		positionType: [] as IPositionType[],
		companyType: [] as ICompanyType[],
		sponsorshipType: [] as ISponsorshipType[],
		confirmDeleteOne: false,
		confirmDeleteOneId: "",
	}),

	actions: {
		setClassification(classification: IClassification[]) {
			this.classification = classification.map((item) => {
				item.position =
					item.position_types
						?.map((item) => item.name)
						.join(",")
						.substring(0, 80) + "...";
				item.company = item.company_types?.map((item) => item.name).join(",");
				item.sponsorship = item.sponsorship_types
					?.map((item) => item.name)
					.join(",");
				return item;
			});
		},
		setValueInClassificationToUpdate(key: string, value: any) {
			if (!this.classificationToUpdate) this.classificationToUpdate = {} as any;
			(this.classificationToUpdate as any)[key] = value;
		},
		setClassificationToUpdate(classification: IClassification | undefined) {
			this.classificationToUpdate = classification
				? classification
				: initialData;
		},
		updateClassification(classification: IClassification) {
			const index = this.classification.findIndex(
				(u) => u.id === classification.id
			);
			this.classification[index] = classification;
		},
		setTab(tab: "List" | "Form") {
			if (tab === "List") this.setClassificationToUpdate(undefined);
			this.tab = tab;
		},
		setTabForm(tabForm: "position-type" | "company-type" | "sponsorship-type") {
			if (tabForm === "position-type") {
				this.tabForm = "position-type";
			}
			if (tabForm === "company-type") {
				this.tabForm = "company-type";
			}
			if (tabForm === "sponsorship-type") {
				this.tabForm = "sponsorship-type";
			}
		},
		setSpinner(spinner: boolean) {
			this.spinner = spinner;
		},
		setPositionType(positionType: any) {
			this.positionType = positionType.filter((el: any) => el.name);
		},
		setCompanyType(companyType: any) {
			this.companyType = companyType.filter((el: any) => el.name);
		},
		setSponsorshipType(sponsorshipType: any) {
			this.sponsorshipType = sponsorshipType.filter((el: any) => el.name);
		},
		changeInput(
			event: any,
			item: any,
			type: "position_types" | "company_types" | "sponsorship_types"
		) {
			const selected = this.classificationToUpdate[type];

			if (event) {
				if (!selected.map((el: any) => el.id).includes(item.id)) {
					selected.push(item);
					this.setValueInClassificationToUpdate(type, selected);
				}
			} else {
				if (selected.map((el: any) => el.id).includes(item.id)) {
					const index = selected.map((el: any) => el.id).indexOf(item.id);
					selected.splice(index, 1);
					this.setValueInClassificationToUpdate(type, selected);
				}
			}
		},
		showConfirmDeleteOne(id: string, confirm: boolean) {
			this.confirmDeleteOneId = id;
			this.confirmDeleteOne = confirm;
		},
		onConfirmDeleteOne() {
			this.setSpinner(true);
			deleteClassificationApi(this.confirmDeleteOneId, () => {
				this.setSpinner(false);
				getClassification(this.setClassification, this.search, () => {});
				this.showConfirmDeleteOne("", false);
			});
		},
	},
});

export const getClassification = async (
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/classification${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	setValue(data);
	callback();
};

export const deleteClassificationApi = async (
	id: string,
	callback: () => void
) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/classification/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	const toast = useToast();
	const storeClassification = useClassificationStore();
	if (!response.ok)
		return toast.error(data.message || "Erro ao excluir uma classificação");
	toast.success("Classificação excluída com sucesso!");
	getClassification(
		storeClassification.setClassification,
		storeClassification.search,
		() => {
			storeClassification.setSpinner(false);
		}
	);
	callback();
};
