import { HttpAdapter } from "@/helpers";
import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();

export const getPreRegistration = async (
	setValue: any,
	callback: () => void,
	id: string,
	size: any,
	page: any,
	searchQuery: any,
	statusQuery: any,
	sort?: { order: string; direction: "ASC" | "DESC" }
): Promise<any | void> => {
	const list = await axios.get(
		`${HttpAdapter.getUrl()}/pre-registration/event/${id}?size=${size}&page=${page}&search=${searchQuery}&status=${statusQuery}&order_by=${
			sort?.order
		}&order_type=${sort?.direction}`
	);
	if (list.status === 500) return;
	callback();
	setValue(list.data);
};

export const getResumePreRegistration = async (
	setValue: any,
	id: string,
	callback: () => void
): Promise<any | void> => {
	const resume = await axios.get(
		`${HttpAdapter.getUrl()}/pre-registration/event/${id}/resume`
	);
	if (resume.status === 500) return;
	callback();
	setValue(resume.data);
};

export const getCompany = async (setValue: any) => {
	const companyName = await axios.get(
		`${HttpAdapter.getUrl()}/company/names-and-ids`
	);
	setValue(companyName.data);
};

export const getPosition = async (setValue: any) => {
	const positionName = await axios.get(`${HttpAdapter.getUrl()}/position-type`);
	setValue(positionName.data);
};

export const updatePreRegistration = async (data: any) => {
	try {
		const update = await axios.put(
			`${HttpAdapter.getUrl()}/pre-registration/${data.id}`,
			data
		);
		toast.success("Inscrição atualizada com sucesso!");
		return update.data;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao atualizar os dados");
	}
};

export const deletePreRegistration = async (id: any, callback: () => void) => {
	try {
		const deleteRegister = await axios.delete(
			`${HttpAdapter.getUrl()}/pre-registration/${id}`
		);
		toast.success("Inscrição excluída com sucesso!");
		return deleteRegister;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao excluir essa inscrição");
	}
	callback();
};

export const updateStatusPreRegistration = async (
	data: any,
	callback?: () => void
) => {
	try {
		const statusData = await axios.patch(
			`${HttpAdapter.getUrl()}/pre-registration/status`,
			data
		);
		if (callback) callback();

		return statusData;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao atualizar status da pré-inscrição");
	}
};

export const resendLinkPreRegistration = async (
	data: any,
	callback?: () => void
) => {
	try {
		const resendData = await axios.post(
			`${HttpAdapter.getUrl()}/pre-registration/payment-link`,
			data
		);
		if (callback) callback();
		return resendData;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao enviar novo link");
	}
};

export const manualPaymentPreRegistration = async (
	body: { pre_registration_id: string; method: string; amount: number },
	callback?: () => void
) => {
	try {
		const manualPayment = await axios.post(
			`${HttpAdapter.getUrl()}/admin/confirm-payment`,
			body
		);
		if (callback) callback();
		return manualPayment;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao executar a baixa manual do pagamento");
	}
};

export const updateCoupon = async (data: any, callback?: () => void) => {
	try {
		const statusData = await axios.patch(
			`${HttpAdapter.getUrl()}/pre-registration/coupon`,
			data
		);
		if (callback) callback();

		return statusData;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao atualizar status do lote");
	}
};
