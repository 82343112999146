export function getContentUrl() {
	return `${process.env.VUE_APP_ABRAFARMA_API_V1}/content`;
}

export function getGeneralUrl() {
	return `${process.env.VUE_APP_ABRAFARMA_API_V1}/api`;
}

export function getGeneralUrlV2() {
	return `${process.env.VUE_APP_ABRAFARMA_API_V2}`;
}
