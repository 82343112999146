<script>
	import { defineComponent,onBeforeMount } from 'vue';
  import { useRouter } from 'vue-router';
  import IcReturn from '../../../../components/icons/IcReturn'
  import {IContractDetails} from '../interface'
  import {getContractDetails} from '../service'
  import {useStoreContractBenefits} from '../store'
  import IcEdit from '../../../../components/icons/IcEdit'
  import IcTrash from '../../../../components/icons/IcTrash'
  import IcInfo from '../../../../components/icons/IcInfo'
  import IcCheck from '../../../../components/icons/IcCheck'

	import Table from '../../../../components/Organism/Table/table.vue'

export default defineComponent({
  	components: {
			IcReturn,
      IcInfo,
      IcCheck,
      Table
		},
  setup() {
    const router = useRouter()
    const back = () => {
        router.push('/contract')
    }

  onBeforeMount(() => {
      getContractDetails(router.currentRoute.value.params.id,storeContractBenefits.setContractDetails, storeContractBenefits.search, () => {
        storeContractBenefits.setSpinner(false);
      });
  });
    const storeContractBenefits = useStoreContractBenefits();

    const tableActions = [{

		title:'Editar',
		icon: IcEdit,
		onClick: (contractBenefit) => updateContractBenefit(contractBenefit)
	},{
		title:'Excluir',
		icon:IcTrash,
		onClick: (contractBenefit) => storeContractBenefits.deleteContractBenefit(contractBenefit.id)
	}]

	const columns = [{
		title:'Descrição do Evento',
		value:'description',
		id:1,
    width:'20%',
		sort: false
	},{
		title:'Descrição do Item',
		value:'item_description',
		id:1,
    width:'20%',
		sort: false
	},{
		title:'Ação',
		value:'action',
		id:3,
		sort: false
	},{
		title:'Classificação',
		value:'classification',
		id:4,
		sort: false
	},{
		title:'Valor do Item',
		value:'item_value',
		id:5,
		sort: false
	},{
		title:'Green',
		value:'green',
		id:6,
    icon:{success:IcCheck,fail:IcInfo},
		sort: false
	},{
		title:'Blue',
		value:'blue',
		id:7,
    icon:{success:IcCheck,fail:IcInfo},
		sort: false
	},{
		title:'Gold',
		value:'gold',
		id:8,
    icon:{success:IcCheck,fail:IcInfo},
		sort: false
	},{
		title:'Platinum',
		value:'platinum',
		id:9,
    icon:{success:IcCheck,fail:IcInfo},
		sort: false
	},{
		title:'Black',
		value:'black',
		id:10,
    icon:{success:IcCheck,fail:IcInfo},
		sort: false
	}]
    return {
      name: router.currentRoute.value.query.name,
      id: router.currentRoute.value.params.id,
      IcReturn,
      back,
      columns,
      tableActions,
      storeContractBenefits,
      getContractDetails,
      Table
    }
  },
})
</script>
<template>
  <div class="box">
    <div class="model-header">
      <div class="title-model-header">
        <span >Nome do Modelo</span>
        <span>{{ name }}</span>
      </div>
      <IcReturn @click="back" style="cursor: pointer" />
    </div>
    <Table
      v-if="storeContractBenefits && storeContractBenefits.contractDetails"
      :columns="columns"
      :rows="storeContractBenefits.contractDetails"
      :tableActions="tableActions"
      @onsort="
        (column, direction) => {
          getContractDetails(
            id,
            storeContractBenefits.setContractDetails,
            storeContractBenefits.search,
            () => {
              storeContractBenefits.setSpinner(false);
            },
            {
              order: column === 'roleName' ? 'role' : column,
              direction: direction,
            }
          );
        }
      "
    />
  </div>
</template>
<style scoped>
.model-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1vw 2vw;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
}

.title-model-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
</style>
