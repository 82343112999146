import { ref } from 'vue';
import { useToast } from 'vue-toastification';

import { IActivity } from '../interface';
import { useStoreScheduleAdmin } from '../store-old';
import { HttpAdapterCustomer } from '@/helpers/HttpAdapterCostumer';

const toast = useToast();
const { setActivityToUpdateData } = useStoreScheduleAdmin();

const store = {
	activity: ref<IActivity>({
		description: '',
	} as IActivity),
	loading: ref(''),
	speakerModal: ref(false),
};

export const useStoreUpdateActivity = () => {
	const actions = {
		setActivity: (activity: IActivity | any, key?: string) => {
			if (key) return ((store.activity.value as any)[key] = activity);
			store.activity.value = activity;
		},
		setLoading: (loading: string) => {
			store.loading.value = loading;
		},
		submit: async () => {
			actions.setLoading('Salvando');
			const speakerFormatted = formatSpeaker(store.activity.value);
			const { data, response } = await HttpAdapterCustomer.post({
				url: 'admin/activity',
				old_url: true,
				headers_config: {
					Authorization: 'admin_token',
				},
				body: JSON.stringify(speakerFormatted),
			});
			actions.setLoading('');
			if (!response.ok) {
				if (data?.importationResult) return errorHandler(data.importationResult);
				return toast.error(data.message || 'Erro ao atualizar a atividade');
			}
			toast.success('Atividade atualizada com sucesso');
			location.reload();
		},
		goBack: () => {
			setActivityToUpdateData({} as IActivity);
		},
		setSpeakerModal: (value: boolean) => {
			store.speakerModal.value = value;
		},
	};
	return {
		...store,
		...actions,
	};
};

const errorHandler = (importationResult: any) => {
	const errors = {
		'participant-not-found': 'O participante não está inscrito no evento',
		'participant-associated': 'O participante não é um associado dessa rede',
		'participant-duplicated': 'O cpf já foi inserido',
		'Nonexistent-company': 'Essa Empresa não está cadastrada',
	};
	importationResult?.errors.forEach(({ error }: any) => {
		toast.error((errors as any)[error]);
	});
};

const formatSpeaker = (activity: IActivity) => {
	const { speakers } = activity;
	const speakerToSave = speakers.map((speaker: any) => {
		if (speaker.speaker_id) return speaker.speaker;
		return speaker;
	});
	return {
		...activity,
		speakers: speakerToSave,
	};
};
