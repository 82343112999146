import { ITokenOldAPI } from '../interface/ITokenOldApi';
import { HttpAdapterCustomer, jwtDecode } from '@/helpers';
import { TOKEN_TYPE, eventStore } from '@/store/event.store';

const changeEvent = async (eventId: string): Promise<{ token: string; data: ITokenOldAPI }> => {
	const { data } = await HttpAdapterCustomer.post({
		url: `public/auth/change-event`,
		body: JSON.stringify({ eventId }),
		old_url: true,
		headers_config: {
			Authorization: 'old_token',
		},
	});
	return { token: data.token, data: jwtDecode<ITokenOldAPI>(data.token) };
};

const changeEventAdmin = async (eventId: string): Promise<ITokenOldAPI> => {
	const { data } = await HttpAdapterCustomer.post({
		url: `public/auth/change-event`,
		body: JSON.stringify({ eventId }),
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		},
	});

	eventStore.setStoreToken(data.token, TOKEN_TYPE['ADMIN_TOKEN']);

	return jwtDecode<ITokenOldAPI>(data.token);
};

export { changeEvent, changeEventAdmin };
