import { HttpAdapter, HttpAdapterCustomer } from "@/helpers";
import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();

type ErrorTypes = {
	[key: string]: string;
};

const ERROR_DISCOUNT: ErrorTypes = {
	"The discount range already exists. Specify a new unique range":
		"A faixa de desconto já existe. Especifique um novo intervalo exclusivo",
};

export const getCompanies = async (setValue: any, callback: () => void) => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: "admin/event/companies",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	callback();
	setValue(data);
};

export const getCoupons = async (
	setValue: any,
	event_company_id: string,
	callback: () => void
) => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/coupon?event_company_id=${event_company_id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	callback();
	setValue(data);
};

export const getCourtesy = async (
	eventCompanyId: string,
	setValue: any,
	callback: () => void
): Promise<any | void> => {
	const resume = await axios.get(
		`${HttpAdapter.getUrl()}/ticket-batch/list-courtesies?event_company_id=${eventCompanyId}`
	);
	if (resume.status === 500) return;
	callback();
	setValue(resume.data);
};

export const createNewPreRegistrationInBatch = async (
	data: {
		name: string;
		enterprise_mail: string;
		company_id: string;
		event_id: string;
		ticket_batch_id: string;
	},
	callback?: () => void
) => {
	try {
		const createRegister = await axios.post(
			`${HttpAdapter.getUrl()}/ticket-batch/add-one-registration`,
			data
		);
		if (callback) callback();
		return createRegister;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao criar um novo participante no lote");
	}
};
export const updateCourtesyValue = async (
	eventCompanyId: string,
	courtesy_amount: number,
	callback?: () => void
) => {
	try {
		const updateCourtesy = await axios.patch(
			`${HttpAdapter.getUrl()}/ticket-batch/update-courtesy/${eventCompanyId}`,
			{ courtesy_amount }
		);
		if (callback) callback();
		return updateCourtesy;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao atualizar o valor de cortesia para essa empresa");
	}
};

export const getTicketBatchByCompany = async (
	setValue: any,
	eventId: string,
	companyId: string,
	callback: () => void
): Promise<any | void> => {
	const resume = await axios.get(
		`${HttpAdapter.getUrl()}/ticket-batch/${eventId}/${companyId}`
	);
	if (resume.status === 500) return;
	callback();
	setValue(resume.data);
};

export const sendLinkPaymentBatch = async (
	data: {
		ticketBatchId: string;
		eventId: string;
	},
	callback?: () => void
) => {
	try {
		const sendLink = await axios.post(
			`${HttpAdapter.getUrl()}/ticket-batch/generate-payment-link`,
			data
		);
		if (callback) callback();
		return sendLink;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao enviar link de pagamento para este lote");
	}
};

export const deletePreRegistrationInBatch = async (
	id: string,
	callback?: () => void
) => {
	try {
		const deleteRegister = await axios.delete(
			`${HttpAdapter.getUrl()}/ticket-batch/${id}`
		);
		if (callback) callback();
		return deleteRegister;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao excluir um participante no lote");
	}
};

export const updateStatusBatch = async (data: any, callback?: () => void) => {
	try {
		const statusData = await axios.patch(
			`${HttpAdapter.getUrl()}/ticket-batch/update-status`,
			data
		);
		if (callback) callback();

		return statusData;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao atualizar status do lote");
	}
};

export const createCoupons = async (body: any, callback: () => void) => {
	const { response } = await HttpAdapterCustomer.post({
		url: "admin/coupon",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
		body: JSON.stringify(body),
	});
	if (!response.ok) {
		toast.error("Erro ao criar cupom!");
		return;
	}
	callback();
};

export const updateStatusCoupon = async (
	id: string,
	statusCoupon: boolean,
	callback: () => void
) => {
	const { data, response } = await HttpAdapterCustomer.patch({
		url: `admin/coupon/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
		body: JSON.stringify({ is_active: statusCoupon }),
	});
	if (!response.ok) return;
	callback();
};

export const deleteCoupon = async (id: string, callback: () => void) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/coupon/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok)
		return toast.error(data.message || "Não é possível excluir esse cupom!");
	callback();
};

export const getDiscountByCompany = async (
	setValue: any,
	eventCompanyId: string,
	callback: () => void
): Promise<any | void> => {
	const resume = await axios.get(
		`${HttpAdapter.getUrl()}/ticket-discount/${eventCompanyId}`
	);
	if (resume.status === 500) return;
	callback();
	setValue(resume.data);
};

export const createNewDiscount = async (
	data: {
		min_registrations: number;
		max_registrations: number;
		registration_value: number;
		event_company_id: string;
	},
	callback?: () => void
) => {
	try {
		const createDiscount = await axios.post(
			`${HttpAdapter.getUrl()}/ticket-discount/create`,
			data
		);
		if (callback) callback();
		return createDiscount;
	} catch (error: any) {
		if (error.response && error.response.data) {
			console.error(error.response.data);
			toast.error(ERROR_DISCOUNT[error.response.data]);
		} else {
			console.error(error);
			toast.error("Erro ao criar um novo desconto progressivo");
		}
	}
};

export const deleteDiscountById = async (id: string, callback?: () => void) => {
	try {
		const deleteRegister = await axios.delete(
			`${HttpAdapter.getUrl()}/ticket-discount/${id}`
		);
		if (callback) callback();
		return deleteRegister;
	} catch (error) {
		console.error(error);
		toast.error("Erro ao excluir um desconto");
	}
};

export async function resendEmailTicketBatch(data: any, callback: () => void) {
	try {
		const res = await axios.post(
			`${HttpAdapter.getUrl()}/ticket-batch/resend-email`,
			data
		);
		callback();
		return res;
	} catch (error: any) {
		toast.error(error.response.data);
	}
}
