import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-91dca208"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-group-box", _ctx.sizeDefault ? 'input-group' : ''])
  }, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_Multiselect, {
      value: _ctx.value,
      options: _ctx.options,
      mode: "tags",
      onChange: _ctx.onChange,
      "close-on-select": false,
      searchable: true,
      "create-option": true
    }, null, 8, ["value", "options", "onChange"])
  ], 2))
}