<script setup>
import BaseButton from "../../../components/Atoms/BaseButton.vue";
import Spinner from "../../../components/Atoms/Spinner.vue";
import ConfirmModal from "../../../components/Organism/ConfirmModal.vue";
import MenuTable from "../../../components/Organism/Table/menu.vue";
import IcConfirmVue from "../../../components/icons/IcConfirm.vue";
import IcDownloadVue from "../../../components/icons/IcDownload.vue";
import IcMessageVue from "../../../components/icons/IcMessage.vue";
import IcStarVue from "../../../components/icons/IcStar.vue";
import IcTrashVue from "../../../components/icons/IcTrash.vue";
import Form from "./components/Form/index.vue";
import Common from "./components/common/index.vue";
import ExportParticipant from "./components/export-participants/index.vue";
import ImportParticipant from "./components/import-participants/index.vue";
import Speaker from "./components/speaker/index.vue";
import Staff from "./components/staff/index.vue";
import { loadDomains } from "./service";
import { useStoreParticipantAdmin } from "./store";
import { TOKEN_TYPE, getTokenSessionStorage } from "@/store/event.store";
import jwtDecode from "jwt-decode";
import { defineComponent, onMounted, ref } from "vue";

const store = useStoreParticipantAdmin();
const userRole = ref("");

const tableActions = [
	{
		icon: IcMessageVue,
		title: "Enviar convites",
		onClick: () => {
			store.sendInvite();
		},
	},
	{
		icon: IcConfirmVue,
		title: "Confirmar",
		onClick: () => {
			store.setConfirmModal(true);
		},
	},
	{
		icon: IcDownloadVue,
		title: "Ler lista de presença",
		onClick: () => {},
	},
	{
		icon: IcDownloadVue,
		title: "Importar",
		onClick: () => {
			document.getElementById("input-file").click();
		},
	},
	{
		icon: IcDownloadVue,
		title: "Exportar",
		onClick: () => {
			store.setTab("export");
		},
	},
	{
		icon: IcStarVue,
		title: "Tornar VIP",
		onClick: () => {
			store.showConfirmVip(true, true);
		},
	},
	{
		icon: IcStarVue,
		title: "Não VIP",
		onClick: () => {
			store.showConfirmVip(true, false);
		},
	},
	{
		icon: IcTrashVue,
		title: "Excluir",
		onClick: () => {
			store.setSpinner(true);
			store.showConfirmDelete(true);

			getCommon(store.setCommonToExport, store.search, store.status, () => {
				store.setSpinner(false);
			});
		},
	},
];
const tableActionStaff = [
	{
		icon: IcDownloadVue,
		title: "Importar ",
		onClick: () => {
			document.getElementById("input-file").click();
		},
	},
];
const tableActionManager = [
	{
		icon: IcConfirmVue,
		title: "Confirmar",
		onClick: () => {
			store.setConfirmModal(true);
		},
	},

	{
		icon: IcDownloadVue,
		title: "Importar ",
		onClick: () => {
			document.getElementById("input-file").click();
		},
	},
	{
		icon: IcDownloadVue,
		title: "Exportar",
		onClick: () => {
			store.setTab("export");
		},
	},

	{
		icon: IcTrashVue,
		title: "Excluir",
		onClick: () => {
			store.setSpinner(true);
			store.showConfirmDelete(true);

			getCommon(store.setCommonToExport, store.search, store.status, () => {
				store.setSpinner(false);
			});
		},
	},
];

function verifyLogin() {
	const token = getTokenSessionStorage(TOKEN_TYPE["ADMIN_TOKEN"]);
	const userLogin = jwtDecode(token);
	userRole.value = userLogin.role;
}

onMounted(() => {
	verifyLogin();
	loadDomains();
	store.setTab("common");
});
</script>

<template>
	<ConfirmModal
		v-if="store.confirmModal"
		text="Deseja confirmar o(s) participante(s) selecionado(s)?"
		@confirm="
			() => {
				store.confirmAll();
				store.setConfirmModal(false);
			}
		"
		@cancel="
			() => {
				store.setConfirmModal(false);
			}
		"
	/>
	<div
		v-if="store.tab === 'common'"
		style="width: 99%; display: flex; justify-content: flex-end"
	>
		<div style="display: flex; gap: 1rem">
			<BaseButton
				@click="
					store.setTab('form');
					store.setParticipantCommon(true);
					store.setParticipantToUpdate();
				"
				style="display: flex; justify-content: flex-end; align-items: center"
			>
				Cadastrar
			</BaseButton>
			<MenuTable
				v-if="userRole === 'admin'"
				:handleActions="tableActions"
				:user="{}"
			></MenuTable>
			<MenuTable
				v-else
				:handleActions="tableActionManager"
				:user="{}"
			></MenuTable>
		</div>
	</div>

	<div
		v-if="store.tab === 'staff'"
		style="width: 99%; display: flex; justify-content: flex-end"
	>
		<div style="display: flex; gap: 1rem">
			<BaseButton
				@click="
					store.setTab('form');
					store.setParticipantCommon(false);
					store.setParticipantToUpdate();
				"
				style="display: flex; justify-content: flex-end; align-items: center"
			>
				Cadastrar
			</BaseButton>
			<MenuTable :handleActions="tableActionStaff" :user="{}"></MenuTable>
		</div>
	</div>
	<div class="container card" style="width: 95%">
		<Spinner v-if="store.spinner" />
		<input
			type="file"
			id="input-file"
			style="visibility: hidden; position: absolute"
			@change="(event) => store.formImportList(event, 'common')"
		/>
		<ConfirmModal
			v-if="store.confirmVip"
			:text="
				store.confirmVipStatus
					? 'Deseja tornar estes participantes vips?'
					: 'Deseja tornar estes participantes não vip?'
			"
			@confirm="
				() => {
					store.onConfirmVips();
				}
			"
			@cancel="
				() => {
					store.showConfirmVip(false);
				}
			"
		/>
		<ConfirmModal
			v-if="store.confirmDelete"
			text="Tem certeza que deseja excluir estes participantes?"
			@confirm="
				() => {
					store.onConfirmDelete();
				}
			"
			@cancel="
				() => {
					store.showConfirmDelete(false);
				}
			"
		/>

		<ConfirmModal
			v-if="store.confirmDeleteOne"
			text="Tem certeza que deseja excluir este participante?"
			@confirm="
				() => {
					store.onConfirmDeleteOne();
				}
			"
			@cancel="
				() => {
					store.showConfirmDeleteOne('', false);
				}
			"
		/>

		<div
			v-if="
				store.tab !== 'form' && store.tab !== 'export' && store.tab !== 'import'
			"
			class="tabs"
		>
			<div
				ref="tab"
				class="tab"
				:class="store.tab === 'common' ? 'tab-selected' : ''"
				@click="store.setTab('common')"
			>
				PARTICIPANTE
			</div>
			<div
				v-if="userRole === 'admin'"
				class="tab"
				:class="store.tab === 'staff' ? 'tab-selected' : ''"
				@click="store.setTab('staff')"
			>
				STAFF
			</div>
			<div
				v-if="userRole === 'admin'"
				class="tab"
				:class="store.tab === 'speaker' ? 'tab-selected' : ''"
				@click="store.setTab('speaker')"
			>
				PALESTRANTE
			</div>
		</div>

		<Common v-if="store.tab === 'common'" />
		<Form v-if="store.tab === 'form'" />
		<Staff v-if="store.tab === 'staff' && userRole === 'admin'" />
		<Speaker v-if="store.tab === 'speaker' && userRole === 'admin'" />
		<ExportParticipant v-if="store.tab === 'export'" />
		<ImportParticipant v-if="store.tab === 'import'" />
	</div>
</template>

<style scoped>
.container {
	margin: 4px !important;
}

.disabled {
	cursor: not-allowed;
	color: var(--color-gray);
}
</style>
