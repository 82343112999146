import { IEventResponseOldAPI } from "../interface/IEventResponseOldApi";
import { changeEvent } from "./changeEvent";
import { HttpAdapterCustomer, existThisFile } from "@/helpers";
import { SET_EVENT_STATE } from "@/store/mutation-types";

const getInformationEvent = async (store: any) => {
	// O gargalo está aqui
	console.time("getInformationEvent");
	const eventId = await eventIdParticipant();
	// const event = await eventIdFUTURETRENDS2022();
	console.timeEnd("getInformationEvent");
	const { data: eventInfoIds } = await changeEvent(eventId);
	const eventInfo = {
		eventId: eventInfoIds.eventId,
		banner: `${existThisFile(eventId)}/banner`,
		footer: `${existThisFile(eventId)}/footer`,
		eventTerm: `${existThisFile(eventId)}/event_term`,
		userId: eventInfoIds.id,
	};
	store.commit(SET_EVENT_STATE, eventInfo);
};

async function eventIdParticipant() {
	const { data } = await HttpAdapterCustomer.get({
		url: "public/participant",
		old_url: true,
		headers_config: {
			Authorization: "old_token",
		},
	});
	return data.event_id
}

async function eventIdFUTURETRENDS2022(): Promise<IEventResponseOldAPI> {
	const { data } = await HttpAdapterCustomer.get<IEventResponseOldAPI[]>({
		url: "public/event",
		old_url: true,
		headers_config: {
			Authorization: "old_token",
		},
	});
	// Evento de Teste - ONLINE
	return data[0] as IEventResponseOldAPI;
}

export { getInformationEvent };
