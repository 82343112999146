import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-538c9051"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  style: {"width":"100%"},
  class: ""
}
const _hoisted_2 = { style: {"font-weight":"bold","color":"var(--color-admin-primary)","font-size":"1rem"} }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.store.error)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Participantes a serem importados"))
        : _createCommentVNode("", true),
      (_ctx.store.error)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Participantes não importados"))
        : _createCommentVNode("", true)
    ]),
    (_ctx.store.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createTextVNode(" Foram importados "),
          _createElementVNode("b", null, _toDisplayString(_ctx.store.successCount), 1),
          _createTextVNode(" participantes com sucesso, mas não foi possível importar os seguintes participantes: ")
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Table, {
      columns: _ctx.columns,
      overflowX: "auto",
      rows: _ctx.store.participants,
      style: {"max-height":"70vh"}
    }, null, 8, ["columns", "rows"]),
    _createElementVNode("div", _hoisted_6, [
      (!_ctx.store.error)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            variant: 'new-secondary',
            onClick: _cache[0] || (_cache[0] = ($event: any) => {
					_ctx.store.setTab('common');
					_ctx.store.setHasSearch(false);
				})
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancelar")
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_BaseButton, {
            key: 1,
            variant: 'new-secondary',
            onClick: _cache[1] || (_cache[1] = ($event: any) => {
					_ctx.store.setTab('common');
					_ctx.store.setHasSearch(false);
				})
          }, {
            default: _withCtx(() => [
              _createTextVNode("OK")
            ]),
            _: 1
          })),
      _createVNode(_component_BaseButton, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.store.importList('common', _ctx.store.participants)))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Importar")
        ]),
        _: 1
      })
    ])
  ]))
}