import { usestoreAccountingYears } from "./store";
import { HttpAdapterCustomer } from "@/helpers";
import formatCurrency from "@/helpers/formatCurrency";
import moment from "moment";
import { useToast } from "vue-toastification";

export const getAccountingYear = async (
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/accounting-year${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	setValue(data);
	callback();
};

export const getBanks = async (
	setValue: (p: any) => void,
	callback: () => void
): Promise<any | void> => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/banks`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const getCompanys = async (
	setValue: (p: any) => void,
	callback: () => void
): Promise<any | void> => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/company`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const getDefaultBank = async (
	setValue: (p: any) => void,
	callback: () => void
): Promise<any | void> => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/banks/default-bank`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const getEffectiveRevenues = async (
	id: any,
	setValue: (p: any) => void,
	search: string,
	callback: () => void
): Promise<any | void> => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/effective-revenues/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const revenuesCosts = async (
	setValue: (p: any) => void,
	year: number,
	group: boolean,
	callback: () => void
): Promise<any | void> => {
	const { data, response } = await HttpAdapterCustomer.post({
		url: `admin/accounting-year/revenues-costs`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
		body: JSON.stringify({ grouped: group, years: [year] })
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const getEffectiveRevenuesById = async (
	id: any,
	setValue: (p: any) => void,
	callback: () => void
) => {
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/effective-revenues/details/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	data.parcels = data.parcels
		.map((el: any) => {
			el.dueDayFormat = el.due_day
				? moment(el.due_day).format("DD/MM/YYYY")
				: "";
			el.payDayFormat = el.pay_day
				? moment(el.pay_day).format("DD/MM/YYYY")
				: "";
			el.valorFormat = formatCurrency(Number(el.value));
			el.situation = !!el.pay_day ? "Pago" : "Pendente";
			return el;
		})
		.sort((a: any, b: any) => {
			return a.parcel_number - b.parcel_number;
		});
	setValue(data);
};

export const sendReceptEmail = async (id: string, callback: () => void) => {
	const { data, response } = await HttpAdapterCustomer.post({
		url: `admin/effective-revenues/receipt/receipt-email/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
};

export const getAccountingYearById = async (
	id: any,
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/accounting-year/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};
export const deleteEffectiveRevenues = async (id: any, eventId: any) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/effective-revenues/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	const toast = useToast();

	const store = usestoreAccountingYears();
	if (!response.ok)
		return toast.error(data.message || "Erro ao excluir receita");
	toast.success("Receita excluida com sucesso");
	// getEffectiveRevenues(eventId, store.setEffectiveRevenues, store.search, () => {
	// 	store.setSpinner(false);
	// });
};

export const effectiveRevenuesPay = async (
	body: any,
	setValue: any,
	callback: () => void
) => {
	const { data, response } = await HttpAdapterCustomer.post({
		url: `admin/effective-revenues/pay`,
		old_url: true,
		body: body,
		headers_config: {
			Authorization: "admin_token",
		},
	});

	callback();
	setValue(data);
};
