import XLSX from 'xlsx';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
export const importTable = (event: any, callBack: Function) => {
	const file = event.target.files;
	if (!file?.length) return;
	const fileToRead = file[0];
	const reader = new FileReader();
	reader.onload = (e: any) => {
		const data = e.target.result;
		const workBook = XLSX.read(data, { type: 'binary' });
		const workSheet = workBook.Sheets['Atividades'];
		const dataFromFile = XLSX.utils.sheet_to_json(workSheet);
		callBack(formatDataFile(dataFromFile as any[]));
	};
	reader.readAsBinaryString(fileToRead);
};

const formatDataFile = (dataFromFile: { CPF: string; Nome: string; Empresa: string }[]): IFileActivity[] => {
	return dataFromFile.map((activity: any) => {
		return {
			name: activity.Nome,
			cpf: activity.CPF,
			company: activity.Empresa,
		};
	});
};

export type IFileActivity = { name: string; cpf: string; company: string };
