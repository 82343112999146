import { ref } from 'vue';

import { useStoreUpdateActivity } from '../store';

const { activity } = useStoreUpdateActivity();
const store = {
	participantsInList: ref<any[]>([]),
};

export const useStoreListRestricted = () => {
	const actions = {
		setParticipantsInList: (participantsInList: any[]) => {
			store.participantsInList.value = participantsInList;
		},
		getParticipants: () => {
			actions.setParticipantsInList(
				activity.value?.restricted_list
					.map((participant) => {
						return {
							...participant,
							name: participant.name,
							cpf: participant.cpf,
							company_id: participant.company.id,
						};
					})
					.sort((a, b) => a.name.localeCompare(b.name)) || []
			);
		},
	};
	return {
		...store,
		...actions,
	};
};
