export const companyType = [
	{ id: "not informed", title: "Não informado" },
	{ id: "commercial partner", title: "Parceiro comercial" },
	{ id: "associated", title: "Associado" },
	{ id: "government", title: "Governamental" },
	{ id: "entity", title: "Entidade" },
	{ id: "midia", title: "Mídia" },
	{ id: "invited", title: "Convidado" },
	{ id: "organizer", title: "Organização" },
	{ id: "stand_builder", title: "Montadora Oficial" },
	{ id: "ticket sale", title: "Inscrição" },
];
