<template>
	<div class="container_input">
		<slot></slot>
	</div>
</template>
<style scoped>
	/* Mobile First */
	.container_input {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		gap: 1rem;
		width: 100%;
		border-bottom: 2px;
		padding-bottom: 1rem;
	}

	/* Desktop */
	@media (min-width: 992px) {
		.container_input {
			flex-direction: row;
		}
	}

	/* Large */
	@media (min-width: 1200px) {
	}
</style>
