<script
	setup
	lang="ts"
></script>

<template>
	<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 172 172" style=" fill:#000000;">
<g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
  <path d="M0,172v-172h172v172z" fill="none">
  </path><g fill="#000000">
    <path d="M103.91667,39.41667l-82.41667,82.41667v28.66667h28.66667l82.41667,
    -82.41667zM151.93333,20.06667c-7.88333,-7.88333 -20.78333,-7.88333 -28.66667,
    0l-8.6,8.6l28.66667,28.66667l8.6,-8.6c7.88333,-7.88333 7.88333,-20.78333 0,-28.66667z">
  </path></g></g></svg>
</template>
