import { existThisFile, HttpAdapterCustomer } from "@/helpers";
import formatCurrency from "@/helpers/formatCurrency";
import { IAccountingYear } from "@/interface/accountingYear";
import { defineStore } from "pinia";
import Toast, { useToast } from "vue-toastification";
import { set_cptable } from "xlsx";

export const type = {
	"not informed": "Não informado",
	"commercial partner": "Parceiro comercial",
	associated: "Associado",
	government: "Governamental",
	entity: "Entidade",
	midia: "Mídia",
	invited: "Convidado",
	organizer: "Organização",
	stand_builder: "Montadora Oficial",
	"ticket sale": "Inscrição",
};

const initialData: IAccountingYear = {
	description: "",
	effectiveRevenues: [],
	year: "",
};

interface IGestor {
	name: String;
	email: String;
	role: String;
	password: String;
	id?: number;
}
const initialDataGestor: IGestor = {
	name: "",
	email: "",
	role: "accountingYear_manager",
	password: "",
};

const initialDataEffective = {
	id: "",
	company_id: "",
	financial_mail: "",
	contract_value: "",
	situation: "pending",
	form_of_payment: "",
	description: null,
	revenue: "",
	accounting_year_id: "",
	filename: null,
	created_at: "",
	updated_at: "",
	bank_id: "13e5c01f-c432-48c8-9a6d-4686d2b52c8e",
	base64: "",
	agency: "9337",
	account: "07306-9",
	credit_card_number: null,
	address: "",
	year: {
		id: "",
		description: "",
		year: "",
	},
	parcels: [
		{
			id: "",
			effective_revenues_id: "",
			parcel_number: 1,
			value: "",
			due_day: "",
			pay_day: null,
			created_at: "",
			updated_at: "",
			last_receipt_number: "",
		},
	],
	company: {
		id: "",
		name: "",
		slug: "",
		cnpj: "",
		social_name: "",
		date_of_association: "",
		type: "",
		deleted: false,
		status: "",
		domains: "",
		address: "",
		city: "",
		cep: "",
		uf: "",
		country: "",
		email: "",
		contact: null,
		phone: "",
		sponsorship_type_id: "",
		type_id: "",
	},
	bank: {
		id: "",
		code: "",
		name: "",
	},
};
export const usestoreAccountingYears = defineStore("AccountingYears", {
	state: () => ({
		accountingYears: [] as IAccountingYear[],
		tab: "List" as
			| "List"
			| "Form"
			| "register"
			| "register-form"
			| "dashboard"
			| "revenue-register",
		tabEdit: "gestor" as "gestor" | "executive",
		tabAdd: false,
		idPay: "",
		accountingYearToUpdate: initialData,
		executiveToUpdate: initialDataEffective,
		search: "",
		sponsors: [],
		spinner: false,
		gestores: [],
		executives: [],
		usersManagers: [],
		banks: [],
		companys: [],
		confirm: "" as string,
		gestorToUpdate: initialDataGestor,
		hasSearchCpf: false,
		effectiveRevenues: [],
		setEffectiveRevenues: [],
		revenuesCosts: [],
		domains: {
			accountingYears: [],
			positions: [],
			positionsType: [],
			classifications: [],
			status: [],
		},
		defaultBankData: {} as any,
		selectedYear: 2023,
	}),
	actions: {
		setDomains(
			type:
				| "accountingYears"
				| "positions"
				| "positionsType"
				| "classifications"
				| "status",
			domain: any
		) {
			this.domains[type] = domain;
		},
		setTabAdd(tab: any) {
			this.tabAdd = tab;
		},
		setTabConfirm(tab: any) {
			this.confirm = tab;
		},
		setSelectedYear(year: number) {
			this.selectedYear = year;
		},
		setCompanys(companys: any) {
			this.companys = companys;
		},
		setDefaultBank(bankData: any) {
			this.defaultBankData = bankData;
		},
		setBanks(banks: any) {
			this.banks = banks;
		},
		setIdPay(id: string) {
			this.idPay = id;
		},
		setAccountingYears(AccountingYears: IAccountingYear[]) {
			this.accountingYears = AccountingYears.map((el: any) => {
				el.value = formatCurrency(el.totalContractsValue?.total);
				return el;
			});
		},
		setUsersManagers(AccountingYears: any) {
			this.usersManagers = AccountingYears.map((el: any) => {
				el.roleName =
					el.role && ROLES.filter((role) => role.id === el.role)[0].role;
				return el;
			});
		},
		setEffectives(AccountingYears: any) {
			this.executives = AccountingYears.map((el: any) => {
				el.roleName =
					el.role && ROLES.filter((role) => role.id === el.role)[0].role;
				return el;
			});
		},
		addAccountingYear(AccountingYear: IAccountingYear) {
			this.accountingYears.push(AccountingYear);
		},
		updateAccountingYear(AccountingYear: IAccountingYear) {
			const index = this.accountingYears.findIndex(
				(u: any) => u.id === AccountingYear.id
			);
			this.accountingYears[index] = AccountingYear;
		},
		setAccountingYearToUpdate(AccountingYear?: IAccountingYear) {
			this.accountingYearToUpdate = AccountingYear
				? AccountingYear
				: initialData;
		},
		setGestorToUpdate(AccountingYear: any, type: any) {
			this.gestorToUpdate = AccountingYear ? AccountingYear : initialDataGestor;
			if (type === "edit") this.setTab("FormEdit");
		},
		setEffectiveToUpdate(AccountingYear: any) {
			this.executiveToUpdate = AccountingYear
				? AccountingYear
				: initialDataEffective;
		},
		setValueInAccountingYearToUpdate(key: string, value: any) {
			if (!this.accountingYearToUpdate) this.accountingYearToUpdate = {} as any;
			(this.accountingYearToUpdate as any)[key] = value;
		},
		setValueInGestorToUpdate(key: string, value: any) {
			if (!this.gestorToUpdate) this.gestorToUpdate = {} as any;
			(this.gestorToUpdate as any)[key] = value;
		},
		setValueInEffectiveToUpdate(key: string, value: any) {
			if (!this.executiveToUpdate) this.executiveToUpdate = {} as any;
			(this.executiveToUpdate as any)[key] = value;
		},
		setTab(tab: any) {
			this.tab = tab;
		},
		setTabEdit(tab: any) {
			this.tabEdit = tab;
		},
		setSearch(search: string) {
			this.search = search;
		},
		setSpinner(spinner: boolean) {
			this.spinner = spinner;
		},
		setHasSearch(val: boolean) {
			this.hasSearchCpf = val;
		},
		deleteAccountingYear(id: string) {
			deleteAccountingYearApi(id);
		},
		setRevenuesCosts(data: any) {
			this.revenuesCosts = data
		},
		setEffectiveRevenues(data: any) {
			this.effectiveRevenues = data.map((el: any) => {
				const enumSituation: any = {
					paid: "Pago",
					pending: "Pendente",
				};
				el.companyName = el.company ? el.company.name : "";
				el.situationLabel = enumSituation[el.situation];
				el.fileName = el.file ? 'Comprovante' : "Sem Anexo";
				el.valueFormat = formatCurrency(el.contract_value);
				el.formPayment =
					el.form_of_payment === "credit_in_account"
						? "Crédito na conta"
						: "Cartão de crédito";
				return el;
			});
		},
	},
});

export const ROLES = [
	{ id: "admin", role: "Administrador" },
	{ id: "AccountingYear", role: "Usuário" },
	{ id: "accountingYear_manager", role: "Gestor" },
	{ id: "organizer", role: "Organizador" },
	{ id: "stand_builder", role: "Montadora Oficial" },
	{ id: "coordinator", role: "Coordenador" },
];

export const deleteAccountingYearApi = async (id: string) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/accounting-year/${id}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	const toast = useToast();
	const storeAccountingYears = usestoreAccountingYears();
	if (!response.ok) return toast.error(data.message || "Erro ao excluir ano");
	toast.success("Ano excluido com sucesso");
	getAccountingYear(
		storeAccountingYears.setAccountingYears,
		storeAccountingYears.search,
		() => {
			storeAccountingYears.setSpinner(false);
		}
	);
};

// export const deleteEffectiveApi = async (id: number,accountingYearId:string) => {
// 	const { data, response } = await HttpAdapterCustomer.delete({
// 		url: `admin/participant/remove-accountingYear/${id}/${accountingYearId}`,
// 		old_url: true,
// 		headers_config: {
// 			Authorization: 'admin_token',
// 		}
// 	});
// 	const toast = useToast()
// 	const storeAccountingYears = usestoreAccountingYears()
// 	if (!response.ok) return toast.error(data.message || 'Erro ao excluir executivo');
// 	toast.success('Executivo Excluido com sucesso');
// 	getEffectives(accountingYearId,storeAccountingYears.setEffectives, storeAccountingYears.search, () => {
// 		storeAccountingYears.setSpinner(false);
// 	});
// }

// export const deleteGestorApi = async (id: any, eventId: any) => {
// 	const { data, response } = await HttpAdapterCustomer.delete({
// 		url: `admin/user/remove-accountingYear/${id}/${eventId}`,
// 		old_url: true,
// 		headers_config: {
// 			Authorization: 'admin_token',
// 		}
// 	});
// 	const toast = useToast()

// 	const storeAccountingYears = usestoreAccountingYears()
// 	if (!response.ok) return toast.error(data.message || 'Erro ao excluir gestor');
// 	toast.success('Gestor excluido com sucesso');
// 	getGestores(eventId, storeAccountingYears.setGestores, storeAccountingYears.search, () => {
// 		storeAccountingYears.setSpinner(false);
// 	});
// }
export const getAccountingYear = async (
	setValue: (p: any) => void,
	search: string,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : "";
	const direction = sort?.direction ? sort.direction : "asc";
	const order = sort?.order ? sort.order : "name";
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/accounting-year${query}`,
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};
