import { HttpAdapterCustomer } from "@/helpers";
import { useToast } from "vue-toastification";
import { useStoreSpeakers } from "./store";

export const deleteSpeakerApi = async (id: number) => {
	const { data, response } = await HttpAdapterCustomer.delete({
		url: `admin/speaker/${id}`,
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		}
	});
	const toast = useToast()

	const storeSpeakers = useStoreSpeakers()
	if (!response.ok) return toast.error(data.message || 'Erro ao cadastrar usuário');
	toast.success('Palestrante Excluido com sucesso');
	getSpeaker(storeSpeakers.setSpeakers, storeSpeakers.search, () => {
		storeSpeakers.setSpinner(false);
	});
}
export const getSpeaker = async (setValue: (p: any) => void, search: string, callback: () => void,
	sort?: { order: string, direction: 'asc' | 'desc' }): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : '';
	const direction = sort?.direction ? sort.direction : 'asc';
	const order = sort?.order ? sort.order : 'name'
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `admin/speaker${query}`,
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};