
import BaseButton from "../../../../../components/Atoms/BaseButton.vue";
import Table from "../../../../../components/Organism/Table/table.vue";
import { useStoreParticipantAdmin } from "../../store";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
	name: "ExportParticipant",
	components: {
		Table,
		BaseButton,
	},
	setup() {
		const miniSpinner = ref(false);
		const store = useStoreParticipantAdmin();

		onMounted(() => {});

		const columns = [
			{
				title: "Rede",
				value: "company",
				id: 1,
				sort: false,
				width: "8%",
			},
			{
				title: "Nome",
				value: "name",
				sort: false,
				id: 2,
				width: "10%",
			},
			{
				title: "Nome da Credencial",
				value: "credentialName",
				sort: false,
				id: 3,
				width: "10%",
			},
			{
				title: "Tipo",
				value: "type",
				sort: false,
				id: 2,
				width: "5%",
			},
			{
				title: "CPF",
				value: "cpf",
				sort: false,
				id: 2,
				width: "10%",
			},
			{
				title: "Matrícula",
				value: "register",
				sort: false,
				id: 2,
				width: "10%",
			},
			{
				title: "Email",
				value: "email",
				sort: false,
				id: 2,
				width: "10%",
			},
			{
				title: "Cargo",
				value: "position",
				sort: false,
				id: 2,
				width: "7%",
			},
			{
				title: "Empresa Convidada",
				value: "guest_company",
				sort: false,
				id: 2,
				width: "7%",
			},
			{
				title: "Tipo de cargo",
				value: "position_type",
				sort: false,
				id: 2,
				width: "10%",
			},
			{
				title: "Classificação",
				value: "classification",
				sort: false,
				id: 2,
				width: "5%",
			},
			{
				title: "Motivo",
				value: "error",
				sort: false,
				id: 2,
				width: "5%",
			},
		];
		return {
			store,
			Table,
			columns,
			miniSpinner,
		};
	},
});
