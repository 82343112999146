<template>
	<form class="container_form">
		<slot></slot>
	</form>
</template>

<script
	setup
	lang="ts"
></script>
<style scoped>
	/* Mobile First */
	.container_form {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 1rem;
		padding: 1.8rem;
	}

	/* Desktop */
	@media (min-width: 992px) {
	}

	/* Large */
	@media (min-width: 1200px) {
	}
</style>
