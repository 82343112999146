
	import { defineComponent, ref, watch } from 'vue';

	import { IContractBenefits } from '../interface';
	import { ROLES, useStoreContractBenefits } from '../store';
	import {getContractBenefit} from '../service'
	import BaseButton from '../../../../components/Atoms/BaseButton.vue';
	import BaseInput from '../../../../components/Atoms/BaseInput.vue';
	import Spinner from '../../../../components/Atoms/Spinner.vue';
	import IcSearch from '../../../../components/icons/IcSearch.vue';
	import IcTrash from '../../../../components/icons/IcTrash.vue';
	import IcEdit from '../../../../components/icons/IcEdit.vue';
	import IcInfo from '../../../../components/icons/IcInfo.vue';
	

	import IcVerticalDots from '../../../../components/icons/IcVerticalDots.vue';
	import { debounce } from '../../../../helpers/debounce';
	import Table from '../../../../components/Organism/Table/table.vue'
	import { useRouter } from 'vue-router';


 export default defineComponent({
		name: 'MenuTable',
		components: {
			BaseInput,
			IcTrash,
      IcSearch,
      IcEdit,
      BaseButton,
			Table,
			Spinner
		},
		setup(props) {
	const storeContractBenefits = useStoreContractBenefits();

	const miniSpinner = ref(false);

	const updateContractBenefit = (contractBenefit: IContractBenefits) => {
		storeContractBenefits.setContractBenefitToUpdate(contractBenefit);
		storeContractBenefits.setTab('Form');
	};

	

	const search = (value: string) => {
		storeContractBenefits.setSearch(value);
		miniSpinner.value = true;
		const TRHEE_SECONDS = 1 * 1000;
		debounce(() => {
			storeContractBenefits.setSpinner(true);
			miniSpinner.value = false;
			
			getContractBenefit(storeContractBenefits.setContractBenefits, storeContractBenefits.search, () => {
				storeContractBenefits.setSpinner(false);
			});
		}, TRHEE_SECONDS);
	};

	const router = useRouter()
	const tableActions = [{
		title:'Detalhes',
		icon: IcInfo,
		onClick: (contractBenefit:any) => router.push(`/contract/${contractBenefit.id}?name=${contractBenefit.name}`)
	},{
		title:'Editar',
		icon: IcEdit,
		onClick: (contractBenefit:any) => updateContractBenefit(contractBenefit)
	},{
		title:'Excluir',
		icon:IcTrash,
		onClick: (contractBenefit:any) => storeContractBenefits.deleteContractBenefit(contractBenefit.id)
	}]

	const columns = [{
		title:'Nome',
		value:'name',
		id:1,
		sort: true,
		width:'90%'
	}]

 return {
     columns,
		 tableActions,
		 storeContractBenefits,
		 miniSpinner,
		 Table,
		 Spinner,
		 search,
		 getContractBenefit
  }
 } 
 })
