<template>
  <svg
    width="24"
    height="24"
  >
    <g clip-path="url(#clip0_9689_201633)">
      <path
        d="M7.46875 12.0625C7.19531 12.3359 7.19531 12.7188 7.46875 12.9922L15.5078 21.0859C15.7266 21.3594 16.1641 21.3594 16.4375 21.0859L17.4766 19.9922C17.75 19.7188 17.75 19.3359 17.4766 19.0625L11.0234 12.5L17.4766 5.99219C17.75 5.71875 17.75 5.28125 17.4766 5.0625L16.4375 3.96875C16.1641 3.69531 15.7266 3.69531 15.5078 3.96875L7.46875 12.0625Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_9689_201633">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
