import { HttpAdapterCustomer } from "@/helpers";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useStoreContractBenefits } from "./store";

const router = useRouter()
const api_url = `admin/contract-model`
const api_url_detail = `admin/contract-model`
const toast = useToast()

export const deleteContractBenefitApi = async (id:number) => {
	const {data,response } = await HttpAdapterCustomer.delete({
		url:`${api_url}/${id}`,
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		}
	});

	const storeContractBenefits = useStoreContractBenefits()
	if (!response.ok) return toast.error(data.message || 'Erro ao cadastrar usuário');
	toast.success('Usuário Excluido com sucesso');
	getContractBenefit(storeContractBenefits.setContractBenefits, storeContractBenefits.search, () => {
			storeContractBenefits.setSpinner(false);
		});
}

export const deleteContractDetailApi = async (id:number,idModel:string) => {
	const {data,response } = await HttpAdapterCustomer.delete({
		url:`${api_url_detail}/${id}`,
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		}
	});

	const storeContractBenefits = useStoreContractBenefits()
	if (!response.ok) return toast.error(data.message || 'Erro ao cadastrar usuário');
	toast.success('Usuário Excluido com sucesso');
	getContractDetails(idModel,storeContractBenefits.setContractDetails, storeContractBenefits.search, () => {
			storeContractBenefits.setSpinner(false);
		});
}
export const getContractBenefit = async (setValue: (p: any) => void, search: string, callback: () => void,
			sort?:{order:string,direction:'asc' | 'desc'}): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : '';
	const direction = sort?.direction? sort.direction:'asc';
	const order = sort?.order? sort.order: 'name'
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `${api_url}${query}`,
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
};

export const getContractDetails = async (id:string,setValue: (p: any) => void, search: string, callback: () => void,
	sort?:{order:string,direction:'asc' | 'desc'}): Promise<any | void> => {
	const hasSearch = search ? `&like=${search}` : '';
	const direction = sort?.direction? sort.direction:'asc';
	const order = sort?.order? sort.order: 'name'
	const query = `?direction=${direction}&orderBy=${order}&page=1&limit=500${hasSearch}`;
	const { data, response } = await HttpAdapterCustomer.get({
		url: `${api_url_detail}/${id}${query}`,
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data.benefits);
};

export const updateContractDetail= async () => {
	const storeContractBenefits = useStoreContractBenefits()
		
		const { data, response } = await HttpAdapterCustomer.post({
			url: api_url_detail,
			old_url: true,
			headers_config: {
				Authorization: 'admin_token',
			},
			body: JSON.stringify(storeContractBenefits.contractDetailsToUpdate),
		});
		if (!response.ok) return toast.error(data.message || 'Erro ao cadastrar modelo');
		toast.success('Modelo Atualizado com sucesso');
			storeContractBenefits.setTabDetails('List');
			getContractBenefit(storeContractBenefits.setContractDetails, storeContractBenefits.search, () => {
			storeContractBenefits.setSpinner(false);
		});
}
export const updateContractModel = async () => {
	const storeContractBenefits = useStoreContractBenefits()
		
		const { data, response } = await HttpAdapterCustomer.post({
			url: api_url,
			old_url: true,
			headers_config: {
				Authorization: 'admin_token',
			},
			body: JSON.stringify(storeContractBenefits.contractBenefitToUpdate),
		});
		if (!response.ok) return toast.error(data.message || 'Erro ao cadastrar modelo');
		toast.success('Modelo Atualizado com sucesso');
			storeContractBenefits.setTab('List');
			getContractBenefit(storeContractBenefits.setContractBenefits, storeContractBenefits.search, () => {
			storeContractBenefits.setSpinner(false);
		});
}
