import { HttpAdapterCustomer } from "@/helpers";


export const  getCompanysEvent = async (setValue:any,callback:any) =>{
  

  const { data, response } = await HttpAdapterCustomer.get({
		url: `/admin/event/companies`,
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
}
export const  getCompanysEventParticipant = async (setValue:any,callback:any) =>{
  

  const { data, response } = await HttpAdapterCustomer.get({
		url: `/admin/participant/participants-dependents`,
		old_url: true,
		headers_config: {
			Authorization: 'admin_token',
		},
	});
	callback();
	if (!response.ok) return;
	setValue(data);
}

export const getQuestions = async (setValue:any,callback:() => void) => {
	const {data,response} = await HttpAdapterCustomer.get({
		url:`admin/event/questions/answers`,
		old_url:true,
		headers_config: {
			Authorization:'admin_token'
		}
	})

	callback()
	if(!response.ok) return;
	setValue(data)
}

export const getEventAccess = async (setValue:any,callback:() => void) => {
	const {data,response} = await HttpAdapterCustomer.get({
		url:`admin/event-access`,
		old_url:true,
		headers_config: {
			Authorization:'admin_token'
		}
	})

	callback()
	if(!response.ok) return;
	setValue(data)

}
export const getCurrentEvent = async (setValue:any,callback:() => void) => {
	const {data,response} = await HttpAdapterCustomer.get({
		url:`admin/event/current`,
		old_url:true,
		headers_config: {
			Authorization:'admin_token'
		}
	})

	callback()
	if(!response.ok) return;
	setValue(data)

}