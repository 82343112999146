<template>
	<div class="c-box">
		<slot />
	</div>
</template>
<style scoped>
	.c-box {
		box-shadow: 1px 1px 3px var(--color-gray);
		border-radius: 8px;
		height: 100%;
		background-color: #fff;
		width: 100%;
		overflow: hidden;
	}
</style>
