import { IBatchList, ISubmitCoupon, ISubmitDiscount } from "./interface";
import {
	deleteCoupon,
	deleteDiscountById,
	getCoupons,
	getDiscountByCompany,
	updateStatusCoupon,
} from "./service";
import { store } from "@/store";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

const toast = useToast();
const initialFormCoupon: ISubmitCoupon = {
	code: "",
	end_date: "",
	amount_payment: 0,
	start_date: "",
	available_uses: 1,
	event_company_id: "",
};

const initialFormDiscount: ISubmitDiscount = {
	min_registrations: 0,
	max_registrations: 0,
	registration_value: 0,
	event_company_id: "",
};

const initialTicketBatch = {
	id: "",
	hash: "",
	company_id: "",
	event_id: "",
	fullprice: "",
	alias: "",
	financial_manager_id: "",
	computed_value: "",
};

const initialFinancialManager = {
	id: "",
	name: "",
	email: "",
	phone: "",
	cpf: "",
	company_id: "",
	position_id: "",
	foreign_document: false,
};

const initialValueBatchList: IBatchList[] = [
	{
		ticket_batch: initialTicketBatch,
		financial_manager: initialFinancialManager,
		pre_registration: [],
	},
];

export const useStoreCoupon = defineStore("useStoreCoupon", {
	state: () => ({
		spinner: false,
		section: "List" as "List" | "Details" | "Sales" | "Discount",
		openModal: false,
		openDiscountModal: false,
		openCourtesyModal: false,
		companiesNameList: [],
		companiesList: [],
		couponList: [],
		discountList: [],
		batchList: initialValueBatchList as IBatchList[],
		companyDetails: {
			companyName: "",
			company_id: "",
			eventCompanyId: "",
			event_id: "",
		},
		couponSubmit: { ...initialFormCoupon },
		eventCompanyId: "",
		confirmDeleteOneCoupon: "",
		confirmDeleteOne: false,
		confirmDeleteOneDiscount: "",
		confirmDelete: false,
		newRegistration: {
			name: "",
			cpf: "",
			enterprise_mail: "",
			company_id: "",
			event_id: "",
			ticket_batch_id: "",
			position: "",
		},
		newDiscount: {
			...initialFormDiscount,
		} as ISubmitDiscount,
		openModalNewRegistration: false,
		deleteMode: false,
		courtesyList: {
			courtesy_tickets_available: 0,
			courtesy_tickets_used: 0,
			event_company_id: "",
		},
		updateCourtesy: 0,
	}),

	actions: {
		setSection(sections: "List" | "Details" | "Sales" | "Discount") {
			this.section = sections;
			if (this.section === "List") {
				this.couponList = [];
				this.companyDetails = {
					companyName: "",
					company_id: "",
					eventCompanyId: "",
					event_id: "",
				};
			}
		},
		setSpinner(spinnerStatus: boolean) {
			this.spinner = spinnerStatus;
		},
		setOpenModal(open: boolean) {
			this.openModal = open;
		},
		setOpenDiscountModal(open: boolean) {
			this.openDiscountModal = open;
		},
		setOpenCourtesyModal(open: boolean) {
			this.openCourtesyModal = open;
		},
		setOpenModalNewRegistration(open: boolean) {
			this.openModalNewRegistration = open;
		},
		setDeleteMode(mode: boolean) {
			this.deleteMode = mode;
		},
		setCompanies(company: any) {
			this.companiesList = company;
			this.companiesNameList = company.map((data: any) => {
				data.eventCompanyId = data?.id;
				data.companyName = data?.company?.name;
				data.batchPending = data?.batch_pending;
				data.batchCount = data?.batch_count;
				return data;
			});
		},
		setCompanyDetails(details: any) {
			this.companyDetails = details;
		},
		setCoupons(coupon: any) {
			this.couponList = coupon.map((data: any) => {
				return data;
			});
		},
		setDiscounts(discount: any) {
			this.discountList = discount.map((data: any) => {
				return data;
			});
		},
		setCourtesy(courtesy: any) {
			this.courtesyList = courtesy;
		},
		setTicketsBatch(batch: any) {
			this.batchList = batch.map((data: any) => {
				return data;
			});
		},
		setValueInCreateCoupon(key?: any, value?: any) {
			if (!key) {
				this.couponSubmit = initialFormCoupon as ISubmitCoupon;
				return;
			}
			(this.couponSubmit as any)[key] = value;
		},
		setValueInCreateDiscount(key?: any, value?: any) {
			if (!key) {
				this.newDiscount = initialFormDiscount as ISubmitDiscount;
				return;
			}
			(this.newDiscount as any)[key] = value;
		},
		showConfirmDeleteOne(id: string, confirm: boolean) {
			this.confirmDeleteOneCoupon = id;
			this.confirmDeleteOne = confirm;
		},

		showConfirmDeleteOneDiscount(id: string, confirm: boolean) {
			this.confirmDeleteOneDiscount = id;
			this.confirmDelete = confirm;
		},

		setNewRegistration(key?: string, value?: any) {
			if (!key) {
				this.newRegistration = this.newRegistration as any;
				return;
			}
			(this.newRegistration as any)[key] = value;
		},

		setUpdateValueCourtesy(key?: string, value?: any) {
			if (!key) {
				this.updateCourtesy = this.updateCourtesy as any;
				return;
			}
			(this.updateCourtesy as any) = value;
		},

		onConfirmDeleteOne() {
			deleteCoupon(this.confirmDeleteOneCoupon, async () => {
				this.showConfirmDeleteOne("", false);
				toast.success("Cupom excluído!");
				await getCoupons(
					this.setCoupons,
					this.companyDetails.eventCompanyId,
					() => {}
				);
			});
		},

		onConfirmDeleteOneDiscount() {
			deleteDiscountById(this.confirmDeleteOneDiscount, async () => {
				await getDiscountByCompany(
					this.setDiscounts,
					this.companyDetails.eventCompanyId,
					() => {}
				);
				this.showConfirmDeleteOneDiscount("", false);
				toast.success("Desconto excluído!");
			});
		},
	},
});
