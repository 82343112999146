import { HttpAdapterCustomer, urlOldApi } from "@/helpers";

export const getQuestions = async (
	setValue: any,
	callback: () => void
): Promise<any | void> => {
	setValue([]);
	const { data, response } = await HttpAdapterCustomer.get({
		url: "admin/event/questions",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	callback();
	setValue(data);
};

export const getAnswers = async (
	setValue: any,
	callback: () => void,
	sort?: { order: string; direction: "asc" | "desc" }
): Promise<any | void> => {
	const direction = sort?.direction ? sort.direction : "asc";
	const { data, response } = await HttpAdapterCustomer.get({
		url: "admin/event/questions/answers",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	});
	if (!response.ok) return;
	callback();
	setValue(data);
};

export const postOrder = async (
	value: any,
	callback: () => void
): Promise<any | void> => {
	const { data, response } = await HttpAdapterCustomer.post({
		url: "admin/event/questions/section/order",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
		body: JSON.stringify(value),
	});
	if (!response.ok) return;
	callback();
};

export const postOrderQuestion = async (
	value: any,
	callback: () => void
): Promise<any | void> => {
	const { response } = await HttpAdapterCustomer.post({
		url: "admin/event/questions/order",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
		body: JSON.stringify(value),
	});
	if (!response.ok) return;
	callback();
};

export const postPublish = async (
	value: any,
	callback: () => void
): Promise<any | void> => {
	const { response } = await HttpAdapterCustomer.post({
		url: "admin/event/questions/publish",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
		body: JSON.stringify(value),
	});
	if (!response.ok) return;
	callback();
};

export const getPreviewToken = async () => {
	const data = await HttpAdapterCustomer.get({
		url: "admin/preview-token",
		old_url: true,
		headers_config: {
			Authorization: "admin_token",
		},
	})
	console.log('GET', data)
}
