import { defineStore } from 'pinia';

export const useStoreMenu = defineStore('menu', {
	state: () => ({
		menuOpen:true
	}),
	actions:{
		setMenu(menu:boolean){
      sessionStorage.setItem('menuOpen',String(menu))
			this.menuOpen = menu
		},
	}
})