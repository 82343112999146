import IcAcommodation from "../components/icons/IcAcommodation.vue";
import IcAirPlane from "../components/icons/IcAirPlane.vue";
import IcChecklist from "../components/icons/IcChecklist.vue";
import IcClassification from "../components/icons/IcClassification.vue";
import IcCompany from "../components/icons/IcCompany.vue";
import IcConfiguration from "../components/icons/IcConfiguration.vue";
import IcCost from "../components/icons/IcCost.vue";
import IcCoupon from "../components/icons/IcCoupon.vue";
import IcCredencial from "../components/icons/IcCredencial.vue";
import IcCurrent from "../components/icons/IcCurrent.vue";
import IcDashboard from "../components/icons/IcDashboard.vue";
import IcDoubleUser from "../components/icons/IcDoubleUser.vue";
import IcEvent from "../components/icons/IcEvent.vue";
import IcEventCompanyAction from "../components/icons/IcEventCompanyAction.vue";
import IcFile from "../components/icons/IcFile.vue";
import IcFilePlus from "../components/icons/IcFilePlus.vue";
import IcFindRetailer from "../components/icons/IcFindRetailer.vue";
import IcHotel from "../components/icons/IcHotel.vue";
import IcMarketingMail from "../components/icons/IcMarketingMail.vue";
import IcOfficialService from "../components/icons/IcOfficialService.vue";
import IcParticipant from "../components/icons/IcParticipant.vue";
import IcPhone from "../components/icons/IcPhone.vue";
import IcPreRegister from "../components/icons/IcPreRegister.vue";
import IcProviders from "../components/icons/IcProviders.vue";
import IcQuestions from "../components/icons/IcQuestions.vue";
import IcSchedule from "../components/icons/IcSchedule.vue";
import IcStarBorder from "../components/icons/IcStarBorder.vue";
import IcTaxas from "../components/icons/IcTaxas.vue";
import IcUser from "../components/icons/IcUser.vue";

export const menuItensUp = [
	{
		title: "Usuários",
		path: "/users",
		name: "users",
		icon: IcUser,
		permissions: "admin",
	},
	{
		title: "Palestrantes",
		path: "/speaker",
		name: "speaker",
		icon: IcDoubleUser,
		permissions: "admin",
	},
	{
		title: "Benefícios do Contrato",
		path: "/contract",
		name: "contract",
		icon: IcFilePlus,
		permissions: "",
	},
	{
		title: "Eventos",
		path: "/event",
		name: "event",
		icon: IcEvent,
		permissions: "admin,user,organizer,coordinator",
	},
	{
		title: "Empresas",
		path: "/company",
		name: "company",
		icon: IcCompany,
		permissions: "admin,user,company_manager,organizer",
	},
	{
		title: "Receitas por Vigência",
		path: "/revenues",
		name: "revenues",
		icon: IcCurrent,
		permissions: "admin",
	},
	{
		title: "Classificação",
		path: "/classification",
		name: "classification",
		icon: IcClassification,
		permissions: "admin",
	},
	{
		title: "Configurações",
		path: "/settings",
		name: "settings",
		icon: IcConfiguration,
		permissions: "",
	},
];

export const menuItensDown = [
	{
		title: "Dashboard",
		path: "/dashboard",
		name: "dashboard",
		icon: IcDashboard,
		permissions: "admin,user,coordinator",
	},

	{
		title: "Agenda",
		path: "/activity",
		name: "schedule",
		icon: IcSchedule,
		permissions: "admin,user,activity_speaker,organizer,coordinator",
	},
	{
		title: "Pré-inscrição",
		path: "/pre-registration",
		name: "pre-registration",
		icon: IcPreRegister,
		permissions: "admin",
		show: "is_paid",
	},
	{
		title: "Gestão de inscrições",
		path: "/coupon",
		name: "coupon",
		icon: IcCoupon,
		permissions: "admin",
		show: "is_paid",
	},
	{
		title: "Participante",
		path: "/participant",
		name: "participant",
		icon: IcParticipant,
		permissions: "admin,user,company_manager,organizer,coordinator",
	},
	{
		title: "Empresas do evento",
		path: "/event-company",
		name: "event-company",
		icon: IcCompany,
		permissions: "admin,user,",
	},
	{
		title: "Hotéis",
		path: "/hotel",
		name: "hotel",
		icon: IcHotel,
		permissions: "admin,user,organize",
	},
	{
		title: "Checklist",
		path: "/checklist",
		name: "checklist",
		icon: IcChecklist,
		permissions: "admin,user,company_manager,organizer,stand_builder",
	},
	{
		title: "Convidado",
		path: "/guests",
		name: "guests",
		icon: IcClassification,
		permissions: "admin",
	},
	{
		title: "Passagens Aéreas",
		path: "/passagens-aereas",
		name: "airplane",
		icon: IcAirPlane,
		permissions: "admin,company_manager",
		show: "air_travel",
	},
	{
		title: "Prestadores",
		path: "/prestadores",
		name: "prestadores",
		icon: IcProviders,
		permissions: "admin,company_manager,organizer,stand_builder",
	},
	{
		title: "Serviços Oficiais",
		path: "/servicos-oficiais",
		name: "official",
		icon: IcOfficialService,
		permissions: "admin,company_manager",
	},
	{
		title: "",
		path: "/atividade-premium",
		name: "atividade-premium",
		icon: IcStarBorder,
		permissions: "admin,company_manager",
		value: "name_activity_premium",
		show: "activity_premium",
	},
	{
		title: "",
		path: "/table-reservation",
		name: "hotel",
		icon: IcPhone,
		permissions: "admin",
		value: "event_table_reservation_description",
		show: "has_event_table_reservation",
	},
	{
		title: "Hospedagem",
		path: "/hospedagem",
		name: "acommodation",
		icon: IcAcommodation,
		permissions: "admin,user,company_manager",
		show: "lodging",
	},
	{
		title: "Financeiro",
		path: "/costs",
		name: "costs",
		icon: IcCost,
		permissions: "admin",
	},
	{
		title: "Arquivos",
		path: "/upload-manuais",
		name: "",
		icon: IcFile,
		permissions: "admin,company_manager,organizer,stand_builder",
	},
	{
		title: "Taxas e pedidos",
		path: "/taxas",
		name: "taxas",
		icon: IcTaxas,
		permissions: "admin,organizer,stand_builder",
	},
	{
		title: "Credenciais",
		path: "/credenciais",
		name: "credenciais",
		icon: IcCredencial,
		permissions: "admin,organizer,company_manager",
	},
	// {
	// 	title: "Email Marketing",
	// 	path: "/email-marketing",
	// 	name: "MKTMAIL",
	// 	icon: IcMarketingMail,
	// 	permissions: "admin,user",
	// },
	// {
	// 	title: "Ações Patrocinadores",
	// 	path: "/event-company-action",
	// 	name: "hotela",
	// 	icon: IcEventCompanyAction,
	// 	permissions: "admin",
	// },
	// {
	// 	title: "Notificações APP",
	// 	path: "/push-notification",
	// 	name: "hotel",
	// 	icon: IcPhone,
	// 	permissions: "admin",
	// },
	{
		title: "Questionário",
		path: "/questionnaire",
		name: "questionnaire",
		icon: IcQuestions,
		permissions: "admin,user",
	},
	{
		title: "",
		path: "/findRetailerAdmin",
		name: "findRetailerAdmin",
		icon: IcFindRetailer,
		permissions: "",
		value: "marketing_activity_name",
		show: "show",
	},
	{
		title: "",
		path: "/findRetailerNotBussinesClub",
		name: "findRetailerNotBussinesClub",
		icon: IcFindRetailer,
		permissions: "",
		value: "marketing_activity_name",
		show: "showPartnerNotBussinesClub",
	},
	{
		title: "",
		path: "/findRetailerBusinessClub",
		name: "findRetailerBusinessClub",
		icon: IcFindRetailer,
		permissions: "",
		value: "marketing_activity_name",
		show: "showPartnerBussinesClub",
	},
	{
		title: "Sessões temáticas",
		path: "/findRetailerSession",
		name: "hotel2",
		icon: IcFindRetailer,
		permissions: "company_manager",
		show: "showPartnerBussinesClub",
		online: true,
	},
];
